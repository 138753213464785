import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Button from "../common/button/button";
import Form from "./tributacaoForm";
import Select from "../common/form/select";
import { setModoTela, initForm, salvar, excluir, setFiltro, getLista, getlistaEstado, getListaAtividade } from "./tributacaoActions";
import { Loading } from "../common/loading/loading";

class Tributacao extends Component {
    componentWillMount() {
        this.props.getlistaEstado();
        this.props.getListaAtividade();
        this.props.getLista();
    }

    render() {
        //Sem acesso
        if (!this.props.usuarioLogado || this.props.usuarioLogado.acesso_cliente) {
            return <div />;
        }

        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}

                <Content>
                    {this.props.modoTela === "visualizacao" ? <Form visualizar onSubmit={() => {}} /> : null}
                    {this.props.modoTela === "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
                    {this.props.modoTela === "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
                    {this.props.modoTela === "lista" ? this.lista() : null}
                </Content>
            </div>
        );
    }

    lista() {
        return (
            <ContentCard>
                <ContentCardHeader>
                    <Row>
                        <Grid cols="12 6 4 2">
                            <Button
                                text="Adicionar"
                                type={"success"}
                                icon={"fa fa-plus"}
                                disabled={!this.props.usuarioLogado.acesso_tributacao_adicionar}
                                event={() => {
                                    this.props.setModoTela("cadastro", {});
                                    this.props.initForm({});
                                }}
                            />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody>
                    <Row>
                        <Select
                            name="id_estado"
                            options={this.props.listaEstado.filter((estado) => this.props.lista.find((tributacao) => tributacao.id_estado == estado.id))}
                            label="UF"
                            cols="12 6 4"
                            placeholder="Selecione a UF"
                            value={this.props.filtro.id_estado}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_estado: value,
                                });
                                this.props.getLista();
                            }}
                        />

                        <Select
                            name="id_atividade"
                            options={this.props.listaAtividade.filter((atividade) =>
                                this.props.lista
                                    .filter((tributacao) => !this.props.filtro.id_estado || tributacao.id_estado == this.props.filtro.id_estado)
                                    .find((tributacao) => tributacao.id_atividade == atividade.id)
                            )}
                            label="Tipo atividade ICMS"
                            cols="12 6 4"
                            placeholder="Selecione o tipo de atividade ICMS"
                            value={this.props.filtro.id_atividade}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_atividade: value,
                                });
                                this.props.getLista();
                            }}
                        />
                    </Row>
                    <Table responsive>
                        <THead>
                            <NewTr
                                backgroundColorTr={"#FFFFFF"}
                                backgroundColorTd={"#F5F5F5"}
                                celulas={
                                    <>
                                        <Th sticky>UF</Th>
                                        <Th sticky>Tipo Atividade ICMS</Th>
                                        <Th sticky>Alíquota</Th>
                                    </>
                                }
                            />
                        </THead>
                        <TBody>
                            {this.props.lista.map((item) => {
                                const aliquotaIcmsComVirgula = String(item.aliquota_icms).replace(".", ",");
                                const registro = { ...item, aliquota_icms: aliquotaIcmsComVirgula };
                                return (
                                    <NewTr
                                        key={item.id}
                                        backgroundColorTr={"#FFFFFF"}
                                        backgroundColorTd={"#F5F5F5"}
                                        celulas={
                                            <>
                                                <Td minWidth={"30vw"}>{item.nome_estado}</Td>
                                                <Td minWidth={"30vw"}>{item.nome_atividade}</Td>
                                                <Td minWidth={"10vw"}>{aliquotaIcmsComVirgula + " %"}</Td>
                                            </>
                                        }
                                        botoes={[
                                            {
                                                type: "primary",
                                                icon: "fas fa-eye",
                                                disabled: !this.props.usuarioLogado.acesso_tributacao_visualizar,
                                                event: () => {
                                                    this.props.setModoTela("visualizacao", registro);
                                                    this.props.initForm(registro);
                                                },
                                            },
                                            {
                                                type: "warning",
                                                icon: "fas fa-pencil-alt",
                                                disabled: !this.props.usuarioLogado.acesso_tributacao_editar,
                                                event: () => {
                                                    this.props.setModoTela("cadastro", registro);
                                                    this.props.initForm(registro);
                                                },
                                            },
                                            {
                                                type: "danger",
                                                icon: "fas fa-trash-alt",
                                                disabled: !this.props.usuarioLogado.acesso_tributacao_excluir,
                                                event: () => {
                                                    this.props.setModoTela("exclusao", registro);
                                                    this.props.initForm(registro);
                                                },
                                            },
                                        ]}
                                    />
                                );
                            })}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.tributacao.modoTela,
	formularioValues: getFormValues("tributacaoForm")(state),
	aguardando: state.tributacao.aguardando,
	filtro: state.tributacao.filtro,
	lista: state.tributacao.lista,
	listaEstado: state.tributacao.listaEstado,
	listaAtividade: state.tributacao.listaAtividade,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			setFiltro,
			salvar,
			excluir,
			getLista,
			getlistaEstado,
			getListaAtividade,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(Tributacao);
