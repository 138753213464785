import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "./analise.css";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Select from "../common/form/select";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_dark from "@amcharts/amcharts4/themes/dark.js";
import confirmDialog from "../common/dialog/confirmDialog";
import { DisplayPDF } from "../displayPDF/displayPDF";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import { AnaliseCustoConsumo } from "./abas/AnaliseCustoConsumo.jsx";
import { AnaliseAproveitamentoConsumoHorario } from "./abas/AnaliseAproveitamentoConsumoHorario.jsx";
import { AnaliseDemanda } from "./abas/AnaliseDemanda.jsx";
import { AnaliseReativo } from "./abas/AnaliseReativo.jsx";
import { AnalisePeriodoSafra } from "./abas/AnalisePeriodoSafra.jsx";
import { AnaliseMulta } from "./abas/AnaliseMulta.jsx";
import { AnaliseMicroMiniGeracao } from "./abas/AnaliseMicroMiniGeracao.jsx";
import { AnaliseGestaoEmpenho } from "./abas/AnaliseGestaoEmpenho.jsx";
import { AnaliseDevolucaoRessarcimento } from "./abas/AnaliseDevolucaoRessarcimento.jsx";
import {
    setFiltro,
    getDemanda,
    getListaCliente,
    getListaUnidadeConsumidora,
    getListaDemandaContratoTipo,
    setTabSelecionada,
    setAbas,
    buscaCusd,
    getListaGraficoValorDevolvidoAnual,
} from "./analiseActions";
import { getListaGrupoFaturamento } from "../cliente/clienteActions";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";
import { Loading } from "../common/loading/loading";

class Analise extends Component {
    constructor(props) {
        super(props);
        this.setAguardando = this.setAguardando.bind(this);
        this.buscarPropriedadeUcFiltrada = this.buscarPropriedadeUcFiltrada.bind(this);
    }
    state = {
        mobile: window.innerWidth < 500,
        aguardando: false,
        cores: [
            am4core.color("#1976d2"),
            am4core.color("#dfe30b"),
            am4core.color("#d65e33"),
            am4core.color("#00c853"),
            am4core.color("#9575cd"),
            am4core.color("#ff9800"),
        ],
    };

    componentWillMount() {
        this.props.getListaUnidadeConsumidora();
        this.props.getListaCliente();
        this.props.getListaDemandaContratoTipo();
        this.props.getListaGrupoFaturamento();

        if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
            this.props.setFiltro({
                ...this.props.filtro,
                id_cliente: this.props.usuarioLogado.id_cliente,
            });
        }
    }

    listar(tabSelecionada, forcarAtualizacao = false) {
        this.props.getDemanda();
        this.props.setTabSelecionada(tabSelecionada);
        if (!forcarAtualizacao) return;

        switch (tabSelecionada) {
            case "consumo":
                this.props.setAbas({ ...this.props.abas, consumo: true });
                this.setAguardando(true);
                break;
            case "horario":
                this.props.setAbas({ ...this.props.abas, horario: true });
                this.setAguardando(true);
                break;
            case "demanda":
                this.props.setAbas({ ...this.props.abas, demanda: true });
                this.setAguardando(true);
                break;
            case "reativo":
                this.props.setAbas({ ...this.props.abas, reativo: true });
                this.setAguardando(true);
                break;
            case "periodoSafra":
                this.props.setAbas({ ...this.props.abas, periodoSafra: true });
                this.setAguardando(true);
                break;
            case "multas":
                this.props.setAbas({ ...this.props.abas, multas: true });
                this.setAguardando(true);
                break;
            case "excedente":
                this.props.setAbas({ ...this.props.abas, excedente: true });
                this.setAguardando(true);
                break;
            case "empenho":
                this.props.setAbas({ ...this.props.abas, empenho: true });
                this.setAguardando(true);
                break;
            case "devolucao":
                this.props.setAbas({ ...this.props.abas, devolucao: true });
                this.setAguardando(true);
                break;
            default:
                break;
        }
    }

    setAguardando(valor) {
        this.setState({ ...this.state, aguardando: valor });
    }

    //UCs que listam no select do primeiro filtro  de UCS
    listarUCsFiltradas() {
        return this.props.listaUnidadeConsumidora.filter((item) => {
            //se não selecionar cliente não traz UCs
            const filtroClienteValidado =
                !this.props.filtro.id_cliente || item.id_cliente == this.props.filtro.id_cliente;

            const filtroGrupoFaturamento = this.props.filtro.grupos_faturamento.map((item) => parseInt(item.value)); //apenas os ids do filtro

            const filtroGrupoFaturamentoValidado =
                !filtroGrupoFaturamento.length || filtroGrupoFaturamento.includes(item.id_grupo_faturamento);
            const filtroIrriganteValidado =
                (this.props.filtro.irrigante && item.irrigante) || (this.props.filtro.naoIrrigante && !item.irrigante);

            if (["demanda", "reativo", "multas"].includes(this.props.tabSelecionada)) {
                return filtroClienteValidado && (item.id_grupo_faturamento == 1 || item.id_grupo_faturamento == 3);
            }

            if (this.props.tabSelecionada === "horario") {
                return (
                    filtroClienteValidado &&
                    (item.id_grupo_faturamento == 1 || item.id_grupo_faturamento == 3 || item.irrigante)
                );
            }

            if (this.props.tabSelecionada === "excedente") {
                return filtroClienteValidado && (item.geracao || item.remota);
            }

            // POR ENQUANTO TRAZ TODAS NA NA PERIODO SAFRA
            // if (this.props.tabSelecionada === "periodoSafra") {
            // 	return filtroClienteValidado && (item.id_atividade == 2 || clienteSelecionado.visualiza_analise_periodo_safra); //2 = atividade rural
            // }

            //se não for nenhum dos anteriores
            return filtroClienteValidado && filtroGrupoFaturamentoValidado && filtroIrriganteValidado;
        });
    }

    buscarPropriedadeUcFiltrada(nomePropriedade) {
        const listaUcFiltro = this.props.listaUnidadeConsumidora.filter((item) =>
            this.props.filtro.unidades_consumidoras.find((uc) => uc.value == item.id)
        );
        //se não veio propriedade quer dizer que vai verificar se existe algum item no filtro
        if (!nomePropriedade) return !!listaUcFiltro.length;

        //se veio propriedade tem que encontrar algum item que possua a propriedade especificada
        const uc = listaUcFiltro.find((uc) => uc[nomePropriedade]);
        if (!uc) return;

        //se encontrar algum item retorna a propriedade
        return uc[nomePropriedade];
    }

    validarExibicaoAba(aba) {
        //deve ter if para todas abas possíveis
        if (!this.props.filtro.id_cliente) return true;
        const clienteSelecionado = this.props.listaCliente.find((cli) => cli.id == this.props.filtro.id_cliente) || {};
        const listaUCsClienteFiltro = this.props.listaUnidadeConsumidora.filter((item) => this.props.filtro.id_cliente && item.id_cliente == this.props.filtro.id_cliente);

        if (["demanda", "reativo", "multas"].includes(aba)) {
            return listaUCsClienteFiltro.find((item) => item.id_grupo_faturamento == 1 || item.id_grupo_faturamento == 3);
        }

        if (aba === "horario") {
            return listaUCsClienteFiltro.find((item) => item.id_grupo_faturamento == 1 || item.id_grupo_faturamento == 3 || item.irrigante);
        }

        if (aba === "excedente") {
            return listaUCsClienteFiltro.find((item) => item.geracao || item.remota);
        }

        if (aba === "periodoSafra") {
            return listaUCsClienteFiltro.find((item) => item.id_atividade == 2) || clienteSelecionado.visualiza_analise_periodo_safra; //2 = atividade rural
        }

        if (aba === "empenho") {
            return this.props.usuarioLogado.cliente_prefeitura || clienteSelecionado.cliente_prefeitura;
        }

        if (aba === "devolucao") {
            return true; //!this.props.usuarioLogado.acesso_cliente;	inicialmente todos tem acesso
        }

        if (aba === "consumo") {
            return true; //!this.props.usuarioLogado.acesso_cliente;	inicialmente todos tem acesso
        }
    }

    componentDidUpdate() {
        //se tava na aba de empenho e selecionou outro cliente que n é prefeitura
        //faz retornar para a aba de consumo, que é a primeira
        if (!this.validarExibicaoAba(this.props.tabSelecionada)) {
            window.alert("O cliente não se enquadra na aba selecionada.");
            this.props.setFiltro({
                ...this.props.filtro,
                id_cliente: null,
                id_unidade_consumidora: null,
                unidades_consumidoras: [],
                unidades_custo_anual: [],
                unidades_custo_anual_periodo_safra: [],
            });
        }
    }

    render() {
        if (this.props.pdf) {
            return (
                <DisplayPDF
                    closePdf={() => this.props.setArquivoPDF()}
                    pdfBase64={this.props.pdf}
                    nomePdf={this.props.nomePdf}
                />
            );
        }
        am4core.useTheme(am4themes_dark);

        //usado no filtro do ano_referencia, quando clica na aba empenho
        const anoAtual = new Date().getFullYear();
        const listaAnos = [
            { id: anoAtual, valor: anoAtual },
            { id: anoAtual + 1, valor: anoAtual + 1 },
            { id: anoAtual + 2, valor: anoAtual + 2 },
            { id: anoAtual + 3, valor: anoAtual + 3 },
            { id: anoAtual + 4, valor: anoAtual + 4 },
        ];

        return (
            <div>
                {this.state.aguardando || this.props.aguardando ? <Loading /> : null}

                <Content>
                    <ul className="menu-analise-container">
                        {/*todos os graficos ates da pizza*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "consumo" ? "-active" : ""}`}
                            data-toggle="tab"
                            onClick={() => this.listar("consumo")}
                        >
                            Análise de Custo e Consumo
                        </li>
                        {/*Graficos pizza e colunas de horarios*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "horario" ? "-active" : ""}`}
                            style={{ display: this.validarExibicaoAba("horario") ? "" : "none" }}
                            onClick={() => this.listar("horario")}
                        >
                            Análise de Aproveitamento de Consumo Horário
                        </li>
                        {/*Demandas*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "demanda" ? "-active" : ""}`}
                            style={{ display: this.validarExibicaoAba("demanda") ? "" : "none" }}
                            onClick={() => this.listar("demanda")}
                        >
                            Análise de Demanda
                        </li>
                        {/*Consumo e gastos reativos*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "reativo" ? "-active" : ""}`}
                            style={{ display: this.validarExibicaoAba("reativo") ? "" : "none" }}
                            onClick={() => this.listar("reativo")}
                        >
                            Análise de Reativos
                        </li>
                        {/*Período Safra*/}
                        <li
                            className={`menu-analise-item${
                                this.props.tabSelecionada == "periodoSafra" ? "-active" : ""
                            }`}
                            style={{ display: this.validarExibicaoAba("periodoSafra") ? "" : "none" }}
                            onClick={() => this.listar("periodoSafra")}
                        >
                            Análise do Período Safra
                        </li>
                        {/*MUltas*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "multas" ? "-active" : ""}`}
                            style={{ display: this.validarExibicaoAba("multas") ? "" : "none" }}
                            onClick={() => this.listar("multas")}
                        >
                            Análise de Multas
                        </li>
                        {/*Mini Geração*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "excedente" ? "-active" : ""}`}
                            tittle="(excedentes e comportamento de saldo)"
                            style={{
                                display: this.validarExibicaoAba("excedente") ? "" : "none",
                            }}
                            onClick={() => this.listar("excedente")}
                        >
                            Análise de Micro/Mini Geração
                        </li>
                        {/*Gestão de empenho*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "empenho" ? "-active" : ""}`}
                            style={{
                                display: this.validarExibicaoAba("empenho") ? "" : "none",
                            }}
                            onClick={() => this.listar("empenho")}
                        >
                            Análise/Gestão de Empenho
                        </li>
                        {/*Gestão de devolução*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "devolucao" ? "-active" : ""}`}
                            style={{
                                display: this.validarExibicaoAba("devolucao") ? "" : "none",
                            }}
                            onClick={() => this.listar("devolucao")}
                        >
                            Análise de Devoluções/Ressarcimentos
                        </li>
                    </ul>

                    <ContentCard>
                        <ContentCardHeader>
                            <Row>
                                {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                                    <Select
                                        name="id_cliente"
                                        options={this.props.listaCliente}
                                        label="Cliente"
                                        cols="12 12 5 5"
                                        placeholder="Selecione o Cliente"
                                        value={this.props.filtro.id_cliente}
                                        onChange={(value) => {
                                            this.props.setFiltro({
                                                ...this.props.filtro,
                                                id_cliente: value,
                                                id_unidade_consumidora: null,
                                                unidades_consumidoras: [],
                                                unidades_custo_anual: [],
                                                unidades_custo_anual_periodo_safra: [],
                                            });
                                            if (this.props.tabSelecionada == "devolucao") {
                                                this.props.getListaGraficoValorDevolvidoAnual();
                                            }
                                        }}
                                    />
                                ) : null}
                                {this.props.tabSelecionada == "empenho" ? (
                                    <Select
                                        cols="12 12 5 5"
                                        name="ano_referencia"
                                        // VERIFICAR QUAIS ANOS PODEM SER
                                        options={listaAnos}
                                        label="Ano de referência do Empenho"
                                        placeholder="Selecione o ano de referência do empenho"
                                        value={this.props.filtro.ano_referencia}
                                        isClearable={false}
                                        onChange={(value) => {
                                            this.props.setFiltro({
                                                ...this.props.filtro,
                                                ano_referencia: value,
                                            });
                                        }}
                                    />
                                ) : (
                                    <>
                                        <Select
                                            name="grupos_faturamento"
                                            options={this.props.listaGrupoFaturamento}
                                            label="Tipo de Faturamento"
                                            isMulti={true}
                                            cols="12 12 4 4"
                                            placeholder="Selecione o tipo de faturamento"
                                            value={this.props.filtro.grupos_faturamento}
                                            onChange={(grupos_faturamento) => {
                                                this.props.setFiltro({
                                                    ...this.props.filtro,
                                                    grupos_faturamento,
                                                });
                                            }}
                                        />
                                        {this.props.tabSelecionada === "demanda" ? (
                                            <Select
                                                name="demanda_contrato_tipos"
                                                options={this.props.listaDemandaContratoTipo.filter((tipo) => {
                                                    const ucFiltradaPeloCliente =
                                                        this.props.listaUnidadeConsumidora.filter(
                                                            (uc) => uc.id_cliente == this.props.filtro.id_cliente
                                                        );
                                                    const condicaoForaPonta = tipo.id == 1;
                                                    const condicaoPonta =
                                                        tipo.id == 2 &&
                                                        !!ucFiltradaPeloCliente.find(
                                                            (uc) => uc.id_modalidade_tarifaria == 2
                                                        ); //alguma uc do cliente selecionado tem q ser horosazonal azul
                                                    const condicaoGeracao =
                                                        tipo.id == 3 &&
                                                        !!ucFiltradaPeloCliente.find((uc) => uc.geracao); //alguma uc do cliente selecionado tem q ser geradora
                                                    return condicaoForaPonta || condicaoPonta || condicaoGeracao;
                                                })}
                                                label="Tipo de Contrato de Demanda"
                                                cols="6 6 3 3"
                                                placeholder="Selecione o tipo de contrato de demanda"
                                                value={this.props.filtro.id_tipo_contrato_demanda}
                                                isClearable={false}
                                                onChange={(id_tipo_contrato_demanda) => {
                                                    this.props.setFiltro({
                                                        ...this.props.filtro,
                                                        id_tipo_contrato_demanda,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <Grid cols="6 6 3 3">
                                                <Row style={{ paddingLeft: 12, paddingBottom: 12 }}>
                                                    <b>Tipo de Atividade</b>
                                                </Row>
                                                <Row style={{ display: "flex", alignItems: "center" }}>
                                                    <LabelAndCheckbox
                                                        gridDisplay="flex"
                                                        gridJustifyContent={
                                                            window.innerWidth < 400 ? "start" : "center"
                                                        }
                                                        gridAlignItems="end"
                                                        style={{ marginLeft: 12 }}
                                                        component={LabelAndCheckbox}
                                                        label="Irrigante"
                                                        cols="12 12 4 4"
                                                        value={this.props.filtro.irrigante}
                                                        onChange={() => {
                                                            this.props.setFiltro({
                                                                ...this.props.filtro,
                                                                irrigante: !this.props.filtro.irrigante,
                                                            });
                                                        }}
                                                    />
                                                    <LabelAndCheckbox
                                                        gridDisplay="flex"
                                                        gridJustifyContent={
                                                            window.innerWidth < 400 ? "start" : "center"
                                                        }
                                                        gridAlignItems="end"
                                                        style={{ marginLeft: 12 }}
                                                        component={LabelAndCheckbox}
                                                        label="Não-Irrigante"
                                                        cols="12 12 8 8"
                                                        value={this.props.filtro.naoIrrigante}
                                                        onChange={() => {
                                                            this.props.setFiltro({
                                                                ...this.props.filtro,
                                                                naoIrrigante: !this.props.filtro.naoIrrigante,
                                                            });
                                                        }}
                                                    />
                                                </Row>
                                            </Grid>
                                        )}

                                        <Select
                                            options={this.listarUCsFiltradas()}
                                            label="Unidades Consumidoras"
                                            cols={
                                                this.props.tabSelecionada == "devolucao" ? "12 12 5 5" : "12 12 10 10"
                                            }
                                            placeholder="Selecione as UCs"
                                            isMulti={true}
                                            value={this.props.filtro.unidades_consumidoras}
                                            onChange={(ucsSelecionadas) => {
                                                const ucQualquerDoFiltro = ucsSelecionadas[0] || {};
                                                const ucCompleta = this.props.listaUnidadeConsumidora.find(
                                                    (uc) => ucQualquerDoFiltro.value == uc.id
                                                ) || { id_cliente: null };
                                                this.props.setFiltro({
                                                    ...this.props.filtro,
                                                    unidades_consumidoras: [...ucsSelecionadas],
                                                    id_cliente: this.props.filtro.id_cliente || ucCompleta.id_cliente,
                                                });
                                            }}
                                        />
                                        {this.props.tabSelecionada == "devolucao" ? (
                                            <>
                                                <Select
                                                    style={{ color: "#000" }}
                                                    options={
                                                        this.props.filtro.id_cliente
                                                            ? this.props.listaGraficoValorDevolvidoAnual.map(
                                                                  (item) => ({
                                                                      id: this.props.filtro.devolucao_ano_safra
                                                                          ? String(item.ano_inicio_fim_safra).split(
                                                                                "/"
                                                                            )[0]
                                                                          : item.ano,
                                                                      valor: this.props.filtro.devolucao_ano_safra
                                                                          ? item.ano_inicio_fim_safra
                                                                          : item.ano,
                                                                  })
                                                              )
                                                            : [
                                                                  {
                                                                      id: `${new Date().getFullYear()}`,
                                                                      valor: this.props.filtro.devolucao_ano_safra
                                                                          ? `${new Date().getFullYear()}/${
                                                                                new Date().getFullYear() + 1
                                                                            }`
                                                                          : `${new Date().getFullYear()}`,
                                                                  },
                                                              ]
                                                    }
                                                    label="Ano"
                                                    cols="12 12 2 2"
                                                    placeholder="Selecione o ano"
                                                    value={this.props.filtro.ano_devolucao}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    onChange={(value) => {
                                                        const modoSafra = this.props.filtro.devolucao_ano_safra;
                                                        this.props.setFiltro({
                                                            ...this.props.filtro,
                                                            ano_devolucao: value,
                                                            competencia_inicial_comparativo_devolucao_ressarcimento:
                                                                modoSafra ? `${value}06` : `${value}01`,
                                                            competencia_final_comparativo_devolucao_ressarcimento:
                                                                modoSafra ? `${parseInt(value) + 1}05` : `${value}12`,
                                                            competencia_inicial_devolucao_por_uc: modoSafra
                                                                ? `${value}06`
                                                                : `${value}01`,
                                                            competencia_final_devolucao_por_uc: modoSafra
                                                                ? `${parseInt(value) + 1}05`
                                                                : `${value}12`,
                                                        });
                                                    }}
                                                />
                                                <Select
                                                    style={{ color: "#000" }}
                                                    options={[
                                                        "Ano Civil (Janeiro à Dezembro)",
                                                        "Ano Safra (Junho à Maio)",
                                                    ].map((item) => ({
                                                        id: item,
                                                        valor: item,
                                                    }))}
                                                    label="Período de Visualização"
                                                    cols="12 12 3 3"
                                                    placeholder="Selecione o ano"
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    value={
                                                        this.props.filtro.devolucao_ano_safra
                                                            ? "Ano Safra (Junho à Maio)"
                                                            : "Ano Civil (Janeiro à Dezembro)"
                                                    }
                                                    onChange={async (value) => {
                                                        if (this.props.tabSelecionada == "devolucao") {
                                                            this.props.getListaGraficoValorDevolvidoAnual();
                                                        }
                                                        const modoSafra = value === "Ano Safra (Junho à Maio)";
                                                        this.props.setFiltro({
                                                            ...this.props.filtro,
                                                            devolucao_ano_safra: modoSafra,
                                                        });
                                                    }}
                                                />
                                            </>
                                        ) : null}
                                    </>
                                )}
                                <Grid cols="12 12 2 2" style={{ paddingTop: 28, paddingRight: 15 }}>
                                    <Button
                                        text="Atualizar"
                                        type={"primary"}
                                        icon={"fa fa-sync"}
                                        event={async () => {
                                            this.props.setAbas({
                                                consumo: false,
                                                horario: false,
                                                demanda: false,
                                                reativo: false,
                                                excedente: false,
                                                periodoSafra: false,
                                                multas: false,
                                                empenho: false,
                                                devolucao: false,
                                            });
                                            this.listar(this.props.tabSelecionada, true);
                                        }}
                                    />
                                </Grid>
                                {this.props.tabSelecionada == "demanda" ? (
                                    <Grid cols="12 12 4 3">
                                        <Button
                                            text="Contrato de Demanda Vigente"
                                            title="Contrato CUSD"
                                            type={"primary"}
                                            icon={"fa fa-check"}
                                            event={async () => {
                                                if (
                                                    this.props.contratoDemanda &&
                                                    this.props.contratoDemanda.arquivo_cusd
                                                ) {
                                                    this.props.buscaCusd(
                                                        this.props.contratoDemanda.id,
                                                        "contrato_cusd"
                                                    );
                                                    this.props.setArquivoPDF();
                                                } else {
                                                    confirmDialog(
                                                        "Nenhum arquivo de contrato(CUSD) encontrado. Tente atualizar.",
                                                        () => {}
                                                    );
                                                }
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                            </Row>
                        </ContentCardHeader>
                    </ContentCard>

                    {this.props.tabSelecionada == "consumo" ? (
                        <AnaliseCustoConsumo
                            state={this.state}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                        />
                    ) : null}

                    {this.props.tabSelecionada == "horario" ? (
                        <AnaliseAproveitamentoConsumoHorario
                            buscarPropriedadeUcFiltrada={this.buscarPropriedadeUcFiltrada}
                            state={this.state}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                        />
                    ) : null}
                    {this.props.tabSelecionada == "demanda" ? (
                        <AnaliseDemanda
                            buscarPropriedadeUcFiltrada={this.buscarPropriedadeUcFiltrada}
                            state={this.state}
                            setAguardando={this.setAguardando}
                        />
                    ) : null}
                    {this.props.tabSelecionada == "reativo" ? (
                        <AnaliseReativo
                            state={this.state}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                        />
                    ) : null}
                    {this.props.tabSelecionada == "periodoSafra" ? (
                        <AnalisePeriodoSafra
                            state={this.state}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                        />
                    ) : null}
                    {this.props.tabSelecionada == "multas" ? (
                        <AnaliseMulta
                            state={this.state}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                        />
                    ) : null}
                    {this.props.tabSelecionada == "excedente" ? (
                        <AnaliseMicroMiniGeracao
                            state={this.state}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                            buscarPropriedadeUcFiltrada={this.buscarPropriedadeUcFiltrada}
                        />
                    ) : null}
                    {this.props.tabSelecionada == "empenho" ? (
                        <AnaliseGestaoEmpenho state={this.state} setAguardando={this.setAguardando} />
                    ) : null}
                    {this.props.tabSelecionada == "devolucao" ? (
                        <AnaliseDevolucaoRessarcimento state={this.state} setAguardando={this.setAguardando} />
                    ) : null}
                </Content>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    pdf: state.displayPDF.pdf,
    nomePdf: state.displayPDF.nomePdf,
    abas: state.analise.abas,
    filtro: state.analise.filtro,
    modoTela: state.analise.modoTela,
    aguardando: state.analise.aguardando,
    listaCliente: state.analise.listaCliente,
    listaDemandaContratoTipo: state.analise.listaDemandaContratoTipo,
    tabSelecionada: state.analise.tabSelecionada,
    contratoDemanda: state.analise.contratoDemanda,
    listaUnidadeConsumidora: state.analise.listaUnidadeConsumidora,
    listaGraficoValorDevolvidoAnual: state.analise.listaGraficoValorDevolvidoAnual,
    listaGrupoFaturamento: state.cliente.listaGrupoFaturamento,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setFiltro,
            getDemanda,
            getListaCliente,
            getListaUnidadeConsumidora,
            buscaCusd,
            setArquivoPDF,
            setTabSelecionada,
            setAbas,
            getListaGrupoFaturamento,
            getListaDemandaContratoTipo,
            getListaGraficoValorDevolvidoAnual,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(Analise);
