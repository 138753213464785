import axios from "axios";
import consts from "../consts";
import { reset as resetForm, initialize } from "redux-form";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";
import confirmDialog from "../common/dialog/confirmDialog";
import { adicionarBloqueioUsuarioImplantacao } from "../utils/adicionarBloqueioUsuarioImplantacao";
import { getUcValidarUsuarioImplantacao } from "../cliente/clienteActions";
import sleep from "../utils/sleep";

export function setModoTela(modo, registro = {}) {
	return {
		type: "GERACAO_INTEGRACAO_MODO_TELA",
		payload: {
			modo: modo,
			registro: registro,
		},
	};
}

export function setAguardando(aguardando) {
	return {
		type: "GERACAO_INTEGRACAO_SET_AGUARDANDO",
		payload: aguardando,
	};
}

export function initForm(registro = {}) {
	return [initialize("geracaoIntegracaoForm", registro)];
}

export function setFiltro(filtro) {
	return {
		type: "GERACAO_INTEGRACAO_FILTRO",
		payload: filtro,
	};
}

export function salvar(registro) {
	return (dispatch, getState) => {
		if (registro.tipo === "SOLIS") {
			registro.chave_api = JSON.stringify({
				KeyId: registro.id_chave_api,
				KeySecret: registro.segredo_chave_api,
				APIURL: registro.url_api,
			});
		}
		if (["FRONIUS", "SOLARVIEW"].includes(registro.tipo) && String(registro.id_sistema_fotovoltaico).includes("=")) {
			registro.id_sistema_fotovoltaico = registro.id_sistema_fotovoltaico.split("=")[1];
		}
		if (registro.tipo === "SOLIS" && String(registro.id_sistema_fotovoltaico).includes("/")) {
			const partesURL = registro.id_sistema_fotovoltaico.split("/");
			const ultimaPosicao = partesURL.length - 1;
			registro.id_sistema_fotovoltaico = partesURL[ultimaPosicao];
		}
		if (registro.tipo === "FUSION SOLAR (Huawei)") {
			registro.id_sistema_fotovoltaico = JSON.stringify({
				codigo_empresa: registro.codigo_empresa,
				nome_instalacao: registro.id_sistema_fotovoltaico,
			});
		}
		if (!registro.id) {
			dispatch(setAguardando(true));
			axios
				.post(`${consts.API_URL}/geracaoIntegracao`, registro)
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getLista());
					dispatch(setModoTela("lista"));
					dispatch(setAguardando(false));
				})
				.catch((e) => {
					setErroAPI(e);
					dispatch(setAguardando(false));
					dispatch(
						initForm({
							...registro,
							id_sistema_fotovoltaico:
								registro.tipo === "FRONIUS"
									? registro.id_sistema_fotovoltaico && `https://www.solarweb.com/PvSystems/PvSystem?pvSystemId=${registro.id_sistema_fotovoltaico}`
									: registro.tipo === "SOLIS"
									? registro.id_sistema_fotovoltaico &&
									  `https://www.soliscloud.com/#/station/stationDetails/generalSituation/${registro.id_sistema_fotovoltaico}`
									: registro.tipo === "SOLARVIEW"
									? registro.id_sistema_fotovoltaico && `https://my.solarview.com.br/monitoramento?und=${registro.id_sistema_fotovoltaico}`
									: registro.tipo === "FUSION SOLAR (Huawei)"
									? JSON.parse(registro.id_sistema_fotovoltaico).nome_instalacao
									: ["SOLARMAN", "WEG", "RENOCLIENT (Renovigi)", "ISOLARCLOUD (Sungrow)", "SOLAREDGE"].includes(registro.tipo)
									? registro.id_sistema_fotovoltaico
									: "",
							codigo_empresa: registro.tipo === "FUSION SOLAR (Huawei)" ? JSON.parse(registro.id_sistema_fotovoltaico).codigo_empresa : "",
						})
					);
				});
		} else {
			confirmDialog("Deseja realmente alterar?", () => {
				dispatch(setAguardando(true));

				axios
					.put(`${consts.API_URL}/geracaoIntegracao`, registro)
					.then((resp) => {
						setSuccess("Operação Realizada com sucesso.");
						dispatch(getLista());
						dispatch(setModoTela("lista"));
						dispatch(setAguardando(false));
					})
					.catch((e) => {
						setErroAPI(e);
						dispatch(setAguardando(false));
						dispatch(
							initForm({
								...registro,
								id_sistema_fotovoltaico:
									registro.tipo === "FRONIUS"
										? registro.id_sistema_fotovoltaico && `https://www.solarweb.com/PvSystems/PvSystem?pvSystemId=${registro.id_sistema_fotovoltaico}`
										: registro.tipo === "SOLIS"
										? registro.id_sistema_fotovoltaico &&
										  `https://www.soliscloud.com/#/station/stationDetails/generalSituation/${registro.id_sistema_fotovoltaico}`
										: registro.tipo === "SOLARVIEW"
										? registro.id_sistema_fotovoltaico && `https://my.solarview.com.br/monitoramento?und=${registro.id_sistema_fotovoltaico}`
										: registro.tipo === "FUSION SOLAR (Huawei)"
										? JSON.parse(registro.id_sistema_fotovoltaico).nome_instalacao
										: ["SOLARMAN", "WEG", "RENOCLIENT (Renovigi)", "ISOLARCLOUD (Sungrow)", "SOLAREDGE"].includes(registro.tipo)
										? registro.id_sistema_fotovoltaico
										: "",
								codigo_empresa: registro.tipo === "FUSION SOLAR (Huawei)" ? JSON.parse(registro.id_sistema_fotovoltaico).codigo_empresa : "",
							})
						);
					});
			});
		}
	};
}

export function excluir(registro) {
	return (dispatch, getState) => {
		confirmDialog("Deseja realmente excluir?", () => {
			axios
				.delete(`${consts.API_URL}/geracaoIntegracao?id=${registro.id}`)
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getLista());
					dispatch(setModoTela("lista"));
				})
				.catch((e) => {
					setErroAPI(e);
				});
		});
	};
}

export function getLista() {
	return async (dispatch, getState) => {
		//busca lista de UC
		if (!getState().geracaoIntegracao.aguardando) {
			dispatch(setAguardando(true));
			dispatch(getUcValidarUsuarioImplantacao());
		}

		//enquanto não tiver a lista de UC para filtrar usuario implantação
		//fica aguardando para somente depois disso disparar a requisição
		if (!getState().cliente.listaUcValidarUsuarioImplantacao.length) {
			await sleep(1000);
			return dispatch(getLista());
		}

		axios
			.get(
				`${consts.API_URL}/geracaoIntegracao?
			id_cliente=${getState().geracaoIntegracao.filtro.id_cliente || null}
			&id_unidade_consumidora=${getState().geracaoIntegracao.filtro.id_unidade_consumidora || null}`
			)
			.then((resp) => {
				const listaFiltrada = adicionarBloqueioUsuarioImplantacao(
					getState().auth.usuarioLogado,
					getState().cliente.listaUcValidarUsuarioImplantacao,
					resp.data
				);

				dispatch({
					type: "GERACAO_INTEGRACAO_LISTADO",
					payload: { data: listaFiltrada },
				});

				dispatch(setAguardando(false));
			})
			.catch((ex) => {
				setError("Não foi possível carregar a lista de integrações");
				dispatch(setAguardando(false));
			});
	};
}

export function getListaCliente() {
	const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
	return {
		type: "GERACAO_INTEGRACAO_CLIENTE_SELECT_LISTADO",
		payload: request,
	};
}

export function getListaUnidadeConsumidora() {
	const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect`);
	return {
		type: "GERACAO_INTEGRACAO_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
		payload: request,
	};
}
