import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";
import confirmDialog from "../common/dialog/confirmDialog";
import { buscarSessao } from "../auth/authActions";

export function setModoTela(modo, registro = {}) {
    return {
        type: "USUARIO_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}
export function initForm(registro = {}) {
    return [initialize("usuarioForm", registro)];
}

export function getLista(id_cliente) {
    const request = id_cliente ? axios.get(`${consts.API_URL}/usuario?id_cliente=${id_cliente}`) : axios.get(`${consts.API_URL}/usuario`);
    return {
        type: "USUARIO_LISTADO",
        payload: request,
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: "USUARIO_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: "USUARIO_CLIENTE_LISTADO",
        payload: request,
    };
}

export function getTotalUsuarios() {
    const request = axios.get(`${consts.API_URL}/usuario/totalUsuarios`);
    return {
        type: "TOTAL_USUARIOS",
        payload: request,
    };
}

export function getLogados() {
    const request = axios.get(`${consts.API_URL}/usuario/listarLogados`);
    return {
        type: "LOGADOS_USUARIOS",
        payload: request,
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        if (!registro.id) {
            axios
                .post(`${consts.API_URL}/usuario`, {
                    ...registro,
                })
                .then((resp) => {
                    if (registro.usuario_implantacao) {
                        const permissoesUsuarioImplantacao = {
                            id: resp.data.id,
                            acesso_cadastro_cliente_adicionar: true,
                            acesso_cadastro_cliente_editar: true,
                            acesso_cadastro_cliente_excluir: true,
                            acesso_cadastro_cliente_visualizar: true,
                            acesso_fatura_editar: true,
                            acesso_fatura_editar_fechado: true,
                        };
                        dispatch(setPermissao(permissoesUsuarioImplantacao));
                    }

                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista(getState().auth.usuarioLogado.id_cliente));

                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        } else {
            confirmDialog("Deseja realmente alterar?", () => {
                axios
                    .put(`${consts.API_URL}/usuario`, {
                        ...registro,
                    })
                    .then((resp) => {
                        if (registro.usuario_implantacao) {
                            const permissoesUsuarioImplantacao = {
                                id: registro.id,
                                acesso_cadastro_cliente_adicionar: true,
                                acesso_cadastro_cliente_editar: true,
                                acesso_cadastro_cliente_excluir: true,
                                acesso_cadastro_cliente_visualizar: true,
                                acesso_fatura_editar: true,
                                acesso_fatura_editar_fechado: true,
                            };
                            // //console.log(
                            //   "enviado set Permissao",
                            //   permissoesUsuarioImplantacao
                            // );
                            dispatch(setPermissao(permissoesUsuarioImplantacao));
                        }
                        setSuccess("Operação Realizada com sucesso.");
                        dispatch(getLista(getState().auth.usuarioLogado.id_cliente));
                        dispatch(setModoTela("lista"));
                    })
                    .catch((e) => {
                        setErroAPI(e);
                    });
            });
        }
    };
}

export function setPermissao(registro) {
    return (dispatch, getState) => {
        if (registro.id) {
            axios
                .post(`${consts.API_URL}/usuario/permissao`, {
                    ...registro,
                })
                .then((resp) => {
                    dispatch([{ type: "USER_FETCHED", payload: resp.data }]);
                    dispatch(buscarSessao());
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista(getState().auth.usuarioLogado.id_cliente));
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        }
    };
}

export function setEmitirAlertaSonoro() {
    return (dispatch, getState) => {
        const alteracaoConfirmada = getState().auth.usuarioLogado.emitir_alerta_sonoro
            ? window.confirm("Deseja realmente desativar o alerta sonoro?")
            : window.confirm("Deseja realmente ativar o alerta sonoro?");
        if (alteracaoConfirmada)
            axios
                .patch(`${consts.API_URL}/usuario/emitirAlertaSonoro/${getState().auth.usuarioLogado.id}`, {
                    emitir_alerta_sonoro: !getState().auth.usuarioLogado.emitir_alerta_sonoro,
                })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(buscarSessao());
                })
                .catch((e) => {
                    setErroAPI(e);
                });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        confirmDialog("Deseja realmente excluir?", () => {
            axios
                .delete(`${consts.API_URL}/usuario?id=${registro.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista(getState().auth.usuarioLogado.id_cliente));
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}
