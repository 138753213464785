import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

function montarGraficoPieAnual(state, component, nome, dados = [], titulo, prefixo, sufixo) {
	if (component) {
		component.dispose();
	}

	let chart = am4core.create(nome, am4charts.PieChart3D);
	chart.colors.step = 1;

	chart.language.locale = locale;

	let title = chart.titles.create();
	title.text = titulo;
	title.fontSize = 16;
	title.marginBottom = 30;

	chart.exporting.menu = new am4core.ExportMenu();
	chart.exporting.filePrefix = "Gráfico";
	chart.exporting.menu.align = "right";
	chart.exporting.menu.verticalAlign = "top";
	chart.exporting.menu.items = [
		{
			label: "...",
			menu: [
				{
					type: "png",
					label: "PNG",
				},
			],
		},
	];

	// chart.legend = new am4charts.Legend()
	// chart.legend.position = 'top'
	// chart.legend.paddingBottom = 20
	// chart.legend.labels.template.maxWidth = 95
	chart.language.locale["_decimalSeparator"] = ",";
	chart.language.locale["_thousandSeparator"] = ".";
	chart.numberFormatter.numberFormat = "###,###,###,###.00";
	chart.hiddenState.properties.opacity = 0;
	chart.legend = new am4charts.Legend();

	let series = chart.series.push(new am4charts.PieSeries3D());
	series.dataFields.value = "value";
	series.dataFields.category = "category";
	series.colors.list = state.cores;

	series.slices.template.tooltipText = `{category}: [bold]${prefixo} {value.formatNumber("###,###,###,###.00")} ${sufixo}[/]`;

	let interfaceColors = new am4core.InterfaceColorSet();

	let data = [];
	dados.forEach((item) => {
		item.tipos.forEach((itemTipo) => {
			if (data.filter((serie) => serie.category == itemTipo.tipo).length == 0) {
				data.push({
					category: String(itemTipo.tipo),
					value: 0,
				});
			}
			data = data.map((serie) => {
				if (serie.category == itemTipo.tipo) {
					return {
						...serie,
						value: serie.value + (itemTipo.valor == "NaN" ? 0 : itemTipo.valor),
					};
				} else {
					return serie;
				}
			});
		});
	});

	chart.data = data;

	return chart;
}

export { montarGraficoPieAnual };
