import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Button from "../common/button/button";
import Form from "./documentoAdmForm";
import Select from "../common/form/select";
import { Loading } from "../common/loading/loading";

import { getUcValidarUsuarioImplantacao } from "../cliente/clienteActions";
import { setModoTela, initForm, setFiltro, salvar, excluir, getLista, getListaDocumentoTipo } from "./documentoAdmActions";

class DocumentoAdm extends Component {
    componentWillMount() {
        this.props.getUcValidarUsuarioImplantacao();
        this.props.getLista();
        this.props.getListaDocumentoTipo();
    }

    render() {
        //Sem acesso
        if (!this.props.usuarioLogado) {
            return <div />;
        }

        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}

                <Content>
                    {this.props.modoTela == "visualizacao" ? <Form onSubmit={() => {}} visualizacao /> : null}
                    {this.props.modoTela == "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
                    {this.props.modoTela == "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
                    {this.props.modoTela == "lista" ? this.lista() : null}
                </Content>
            </div>
        );
    }

    lista() {
        let usuarioVisualizar = !!this.props.usuarioLogado.acesso_documento_adm_visualizar;
        let usuarioEdicao = !!this.props.usuarioLogado.acesso_documento_adm_editar;

        return (
            <ContentCard>
                <ContentCardHeader>
                    {usuarioEdicao ? (
                        <Row>
                            <Grid cols="6 6 4 2">
                                <Button
                                    text="Adicionar"
                                    type={"success"}
                                    icon={"fa fa-plus"}
                                    disabled={!usuarioEdicao}
                                    event={() => {
                                        this.props.setModoTela("cadastro", {});
                                        this.props.initForm({ anexos: [] });
                                    }}
                                />
                            </Grid>
                        </Row>
                    ) : null}
                </ContentCardHeader>
                <ContentCardBody>
                    <Row>
                        <Select
                            name="id_documento_tipo"
                            options={this.props.listaDocumentoTipo}
                            label="Tipo"
                            cols="12 6 4"
                            placeholder="Selecione o Tipo de Documento"
                            value={this.props.filtro.id_documento_tipo}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_documento_tipo: value,
                                });
                                this.props.getLista();
                            }}
                        />
                    </Row>

                    <Table responsive>
                        <THead>
                            <NewTr
                                backgroundColorTr={"#FFFFFF"}
                                backgroundColorTd={"#F5F5F5"}
                                celulas={
                                    <>
                                        <Th sticky>Tipo</Th>
                                        <Th sticky>Documento</Th>
                                    </>
                                }
                            />
                        </THead>
                        <TBody>
                            {this.props.lista.map((item) => (
                                <NewTr
                                    key={item.id}
                                    backgroundColorTr={"#FFFFFF"}
                                    backgroundColorTd={"#F5F5F5"}
                                    celulas={
                                        <>
                                            <Td>{item.nome_documento_tipo}</Td>
                                            <Td>{item.nome}</Td>
                                        </>
                                    }
                                    botoes={[
                                        {
                                            type: "primary",
                                            icon: "fas fa-eye",
                                            disabled: !usuarioVisualizar,
                                            event: () => {
                                                let registro = {
                                                    ...item,
                                                };
                                                this.props.setModoTela("visualizacao", registro);
                                                this.props.initForm(registro);
                                            },
                                        },
                                        {
                                            type: "warning",
                                            icon: "fas fa-pencil-alt",
                                            disabled: !usuarioEdicao,
                                            event: () => {
                                                let registro = {
                                                    ...item,
                                                };
                                                this.props.setModoTela("cadastro", registro);
                                                this.props.initForm(registro);
                                            },
                                        },
                                        {
                                            type: "danger",
                                            icon: "fas fa-trash-alt",
                                            disabled: !usuarioEdicao,
                                            event: () => {
                                                let registro = {
                                                    ...item,
                                                };
                                                this.props.setModoTela("exclusao", registro);
                                                this.props.initForm(registro);
                                            },
                                        },
                                    ]}
                                />
                            ))}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.documentoAdm.modoTela,
	formularioValues: getFormValues("documentoAdmForm")(state),
	aguardando: state.documentoAdm.aguardando,
	filtro: state.documentoAdm.filtro,
	lista: state.documentoAdm.lista,
	listaDocumentoTipo: state.documentoAdm.listaDocumentoTipo,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			setFiltro,
			salvar,
			excluir,
			getLista,
			getListaDocumentoTipo,
			getUcValidarUsuarioImplantacao,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(DocumentoAdm);
