import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputNumber from "../common/form/labelAndInputNumber";
import LabelAndInputMask from "../common/form/labelAndInputMask";
import LabelAndInputDateTime from "../common/form/labelAndInputDateTime";
import Select from "../common/form/select";
import SelectAlteracao from "../common/form/selectAlteracao";
import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";

import { setModoTela, initForm, setValidado } from "./faturaActions";

class FaturaFormMultiplo extends Component {
  state = {};

  componentWillMount() {}

  render() {
    let readOnly = "";

    return (
      <ContentCard>
        <Form event={this.props.handleSubmit}>
          <ContentCardBody>
            <Row>
              <Field
                name="id_cliente"
                component={Select}
                //se usuario implantacao estiver logado
                //filtra para poder incluir apenas itens na UC
                //que ele é o implantador
                options={
                  this.props.usuarioLogado.usuario_implantacao
                    ? this.props.listaCliente.filter((cliente) =>
                        cliente.itens.find(
                          (uc) =>
                            uc.id_usuario_implantacao ==
                            this.props.usuarioLogado.id
                        )
                      )
                    : this.props.listaCliente
                }
                label="Cliente"
                cols="12 12 12 12"
                placeholder="Selecione o cliente"
                readOnly={readOnly}
              />
            </Row>
            <Row>
              <Field
                name="id_unidade_consumidora"
                component={Select}
                //se usuario implantacao estiver logado
                //filtra para poder incluir apenas itens na UC
                //que ele é o implantador
                options={this.props.listaUnidadeConsumidora.filter((item) => {
                  const implantacaoLogado =
                    this.props.usuarioLogado.usuario_implantacao;
                  const ucClienteSelecionado =
                    item.id_cliente == this.props.formularioValues.id_cliente;
                  if (implantacaoLogado) {
                    const usuarioImplantacaoVinculadoUc =
                      item.id_usuario_implantacao ==
                      this.props.usuarioLogado.id;
                    return (
                      usuarioImplantacaoVinculadoUc && ucClienteSelecionado
                    );
                  }
                  return ucClienteSelecionado;
                })}
                label="Unidade Consumidora"
                cols="12 12 12 12"
                placeholder="Selecione a unidade consumidora"
                readOnly={readOnly}
              />
            </Row>
            <Row>
              <Field
                name="competencia_inicial"
                component={LabelAndInputMask}
                label="Competência Inicial"
                placeholder="Informe a competência"
                cols="12 12 12 12"
                mask="99/9999"
                readOnly={readOnly}
              />
            </Row>
            <Row>
              <Field
                name="competencia_final"
                component={LabelAndInputMask}
                label="Competência Final"
                placeholder="Informe a competência"
                cols="12 12 12 12"
                mask="99/9999"
                readOnly={readOnly}
              />
            </Row>
          </ContentCardBody>
          <ContentCardFooter>
            <Row alignCenter>
              <Grid cols="6 6 4 3">
                <Button
                  text="Salvar"
                  submit
                  disabled={this.props.formularioValues.importacao_validada}
                  type={"success"}
                  icon={"fa fa-check"}
                />
              </Grid>
              <Grid cols="6 6 4 3">
                <Button
                  text="Voltar"
                  type={"warning"}
                  icon={"fa fa-chevron-left"}
                  event={() => this.props.setModoTela("lista")}
                />
              </Grid>
            </Row>
          </ContentCardFooter>
        </Form>
      </ContentCard>
    );
  }
}

FaturaFormMultiplo = reduxForm({ form: "faturaForm", destroyOnUnmount: false })(
  FaturaFormMultiplo
);
const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  usuarioLogado: state.auth.usuarioLogado,
  formularioValues: getFormValues("faturaForm")(state),
  registro: state.fatura.registro,
  listaCliente: state.fatura.listaCliente,
  listaUnidadeConsumidora: filtrarUcPorDataDesligamento(
    state.fatura.listaUnidadeConsumidora
  ),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      initForm,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(FaturaFormMultiplo);
