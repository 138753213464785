import React from "react";
import { Switch, Route, Redirect } from "react-router";

import Dashboard from "../dashboard/dashboard";
import Usuario from "../usuario/usuario";
import Cliente from "../cliente/cliente";
import Empenho from "../empenho/empenho";
import Fatura from "../fatura/fatura";
import FaturaVisualizacao from "../fatura/faturaVisualizacao";
import FaturaEngenharia from "../fatura/faturaEngenharia";
import FaturaVerificadorIcmsEnergia from "../fatura/faturaVerificadorIcmsEnergia";
import FaturaRelatorio from "../faturaRelatorio/faturaRelatorio";
import Geracao from "../geracao/geracao";
import GeracaoIntegracao from "../geracaoIntegracao/geracaoIntegracao";
import GeracaoLeitura from "../geracaoLeitura/geracaoLeitura";
import Analise from "../analise/analise";
import DemandaContrato from "../demandaContrato/demandaContrato";
import Rede from "../rede/rede";
import Documento from "../documento/documento";
import DocumentoAdm from "../documentoAdm/documentoAdm";
import DocumentoCliente from "../documento/documentoCliente";
import RedeVisualizacao from "../rede/redeVisualizacao";
import GestaoProcesso from "../gestaoProcesso/gestaoProcesso";
import Tributacao from "../tributacao/tributacao";

export default (props) => {
	const sessao = props.sessao || {};

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------
	const acessoRede = !sessao.acesso_cliente && (sessao.acesso_redes_visualizar || sessao.acesso_redes_editar);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoUsuario =
		sessao.acesso_usuario_visualizar || sessao.acesso_usuario_adicionar || sessao.acesso_usuario_editar || sessao.acesso_usuario_excluir;

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoTributacao =
		!sessao.acesso_cliente &&
		(sessao.acesso_tributacao_visualizar ||
			sessao.acesso_tributacao_adicionar ||
			sessao.acesso_tributacao_editar ||
			sessao.acesso_tributacao_excluir);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoEmpenho =
		!sessao.acesso_cliente &&
		(sessao.acesso_empenho_visualizar || sessao.acesso_empenho_adicionar || sessao.acesso_empenho_editar || sessao.acesso_empenho_excluir);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoCliente =
		!sessao.acesso_cliente &&
		(sessao.acesso_cadastro_cliente_visualizar ||
			sessao.acesso_cadastro_cliente_adicionar ||
			sessao.acesso_cadastro_cliente_editar ||
			sessao.acesso_cadastro_cliente_excluir);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoDocumento = sessao.acesso_documento_visualizar || sessao.acesso_documento_editar;

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoDocumentoAdm = sessao.acesso_documento_adm_visualizar || sessao.acesso_documento_adm_editar;

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoFatura =
		!sessao.acesso_cliente && (sessao.acesso_fatura_visualizar || sessao.acesso_fatura_editar || sessao.acesso_fatura_editar_fechado);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoFaturaVisualizacao = sessao.acesso_fatura_visualizar || sessao.acesso_fatura_editar || sessao.acesso_fatura_editar_fechado;

	//----------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoFaturaEngenharia = sessao.acesso_multa_visualizar || sessao.acesso_multa_editar || sessao.acesso_multa_editar_fechado;

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoFaturaIcmsEnergia = sessao.acesso_icms_energia_visualizar;

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoFaturaRelatorio = sessao.acesso_relatorio;

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoDemandaContrato =
		!sessao.acesso_cliente &&
		(sessao.acesso_cadastro_demanda_visualizar ||
			sessao.acesso_cadastro_demanda_adicionar ||
			sessao.acesso_cadastro_demanda_editar ||
			sessao.acesso_cadastro_demanda_excluir);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoGeracao =
		!sessao.acesso_cliente &&
		(sessao.acesso_cadastro_geracao_visualizar ||
			sessao.acesso_cadastro_geracao_adicionar ||
			sessao.acesso_cadastro_geracao_editar ||
			sessao.acesso_cadastro_geracao_excluir);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoGeracaoIntegracao = !sessao.acesso_cliente && (sessao.acesso_solar_visualizar || sessao.acesso_solar_editar);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoGeracaoLeitura = sessao.acesso_solar_visualizar || sessao.acesso_solar_editar;

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoGestaoProcesso = !sessao.acesso_cliente;

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoAnalise = sessao.acesso_analise;

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoRedesEletricas = sessao.acesso_redes_visualizar;

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------

	const acessoDocumentoCliente = sessao.acesso_documento_visualizar || sessao.acesso_documento_editar;

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------
	return (
		<div id="id-content-wrapper" className="content-wrapper" style={{ backgroundColor: "#e4e5eb" }}>
			<Switch>
				<Route exact path="/" component={Dashboard} />
				<Route path="/usuario" component={acessoUsuario ? Usuario : Dashboard} />
				<Route path="/cliente" component={acessoCliente ? Cliente : Dashboard} />
				<Route path="/empenho" component={acessoEmpenho ? Empenho : Dashboard} />
				<Route path="/tributacao" component={acessoTributacao ? Tributacao : Dashboard} />
				<Route path="/fatura" component={acessoFatura ? Fatura : Dashboard} />
				<Route path="/faturaVisualizacao" component={acessoFaturaVisualizacao ? FaturaVisualizacao : Dashboard} />
				<Route path="/faturaEngenharia" component={acessoFaturaEngenharia ? FaturaEngenharia : Dashboard} />
				<Route path="/verificadorIcmsEnergia" component={acessoFaturaIcmsEnergia ? FaturaVerificadorIcmsEnergia : Dashboard} />
				<Route path="/faturaRelatorio" component={acessoFaturaRelatorio ? FaturaRelatorio : Dashboard} />
				<Route path="/gestaoTarefa" component={acessoGestaoProcesso ? GestaoProcesso : Dashboard} />
				<Route
					path="/gestaoItemImportacao"
					component={Dashboard} //AINDA NÃO IMPLEMENTADO
				/>
				<Route path="/geracao" component={acessoGeracao ? Geracao : Dashboard} />
				<Route path="/geracaoIntegracao" component={acessoGeracaoIntegracao ? GeracaoIntegracao : Dashboard} />
				<Route path="/geracaoLeitura" component={acessoGeracaoLeitura ? GeracaoLeitura : Dashboard} />
				<Route path="/analise" component={acessoAnalise ? Analise : Dashboard} />
				<Route path="/demandaContrato" component={acessoDemandaContrato ? DemandaContrato : Dashboard} />
				<Route path="/rede" component={acessoRede ? Rede : Dashboard} />
				<Route path="/redesEletricas" component={acessoRedesEletricas ? RedeVisualizacao : Dashboard} />
				<Route path="/documento" component={acessoDocumento ? Documento : Dashboard} />
				<Route path="/documentoAdm" component={acessoDocumentoAdm ? DocumentoAdm : Dashboard} />
				<Route path="/documentoCliente" component={acessoDocumentoCliente ? DocumentoCliente : Dashboard} />

				<Redirect from="*" to="/" />
			</Switch>
		</div>
	);
};
