import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import "./displayPDF.css";
const options = {
	cMapUrl: "cmaps/",
	cMapPacked: true,
	standardFontDataUrl: "standard_fonts/",
};
export const PdfToBePrinted = React.forwardRef(({ pdfBase64, zoom, orientation = "portrait", onClick = () => ({}), style = {} }, ref) => {
	const [numPages, setNumPages] = useState(null);
	function onDocumentLoadSuccess({ numPages: nextNumPages }) {
		setNumPages(nextNumPages);
	}
	useEffect(() => {
		setPageSize(orientation);
		return () => {
			const child = document.getElementById("page-orientation");
			child.parentNode.removeChild(child);
		};
	}, [orientation]);

	return (
        <div className="displayPDF__container" ref={ref} onClick={onClick} style={style}>
            <div className="displayPDF__container__document">
                <Document file={pdfBase64} options={options} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} scale={zoom} pageNumber={index + 1} />
                    ))}
                </Document>
            </div>
        </div>
    );

	function setPageSize(cssPageSize) {
		const style = document.createElement("style");
		style.innerHTML = `@page {size: ${cssPageSize}}`;
		style.id = "page-orientation";
		document.head.appendChild(style);
	}
});
