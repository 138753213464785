import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardBody from "../../common/template/contentCardBody";
import NumberFormat from "react-number-format";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import { getListaGraficoDemanda, getDemanda, getDemandaContagem } from "../analiseActions";
import { montarGraficoClusteredColumnLineMensal } from "../../common/graficos/montarGraficoClusteredColumnLineMensal.js";

function analiseDemanda(props) {
    const {
        state,
        buscarPropriedadeUcFiltrada,
        filtro,
        contratoDemanda,
        listaAgendamentoDemanda,
        setAguardando,
        listaGraficoDemanda,
        listaDemandaContagem,
        getDemanda,
        getDemandaContagem,
        getListaGraficoDemanda,
    } = props;

    const [graficoDemandaMensal, setGraficoDemandaMensal] = useState(null);

    useEffect(() => {
        atualizarTodosGraficos();
        return desmontarTodosGraficos;
    }, []);

    useEffect(() => {
        const buscarDados = async () => {
            if (!state.aguardando) return;
            atualizarTodosGraficos();
            await Promise.all([getDemanda(), getDemandaContagem(), getListaGraficoDemanda()]);
            atualizarTodosGraficos();
            setAguardando(false);
        };

        buscarDados();
        atualizarTodosGraficos();
    }, [state.aguardando]);

    function atualizarTodosGraficos() {
        atualizarListaGraficoDemanda();
    }

    function desmontarTodosGraficos() {
        if (graficoDemandaMensal) graficoDemandaMensal.dispose();
    }

    function atualizarListaGraficoDemanda() {
        const exibirLinhas = filtro.unidades_consumidoras.length == 1;
        const graficoDemandaMensalAtualizado = montarGraficoClusteredColumnLineMensal(
            state,
            graficoDemandaMensal,
            "graficoDemandaMensal",
            listaGraficoDemanda.map((item) => ({
                ...item,
                anos: item.anos.map((ano) => ({
                    ...ano,
                    ano: ano.ano || "",
                })),
            })),
            "Demanda Mensal (kW)",
            "",
            "kW",
            exibirLinhas
        );
        setGraficoDemandaMensal(graficoDemandaMensalAtualizado);
    }

    let demandaAnoInicial =
        contratoDemanda && contratoDemanda.competencia_inicio
            ? String(contratoDemanda.competencia_inicio).substring(0, 4)
            : "";
    let demandaMesInicial =
        contratoDemanda && contratoDemanda.competencia_inicio
            ? String(contratoDemanda.competencia_inicio).substring(4, 6)
            : "";
    let demandaMesFinal =
        contratoDemanda && contratoDemanda.competencia_fim
            ? String(contratoDemanda.competencia_fim).substring(4, 6)
            : "";
    let demandaAnoFinal =
        contratoDemanda && contratoDemanda.competencia_fim
            ? String(contratoDemanda.competencia_fim).substring(0, 4)
            : "";

    let corCiclo = "#30303d";

    let anos = [];
    listaGraficoDemanda.forEach((item) => {
        item.anos.forEach((ano) => {
            if (anos.filter((itemAno) => itemAno == ano.ano).length <= 0) {
                anos.push(ano.ano);
            }
        });
    });

    if (demandaAnoInicial && anos.filter((itemAno) => itemAno == demandaAnoInicial).length <= 0) {
        anos.push(demandaAnoInicial);
    }

    if (demandaAnoFinal && anos.filter((itemAno) => itemAno == demandaAnoFinal).length <= 0) {
        anos.push(demandaAnoFinal);
    }

    anos = anos.sort((a, b) => {
        if (a.includes("Demanda") && b.includes("Demanda")) {
            return a < b ? 1 : -1;
        } else if (a.includes("Demanda") || b.includes("Demanda")) {
            return a < b ? 1 : -1;
        } else {
            return a < b ? -1 : 1;
        }
    });

    //SÓ MOSTRA QUANDO TIVER APENAS UMA UC SELECIONADA
    const quantidadeUcFiltro = filtro.unidades_consumidoras.length;
    let contagemDemanda = null;
    if (quantidadeUcFiltro == 1) {
        // se tiver apenas uma UC no filtro ou apenas um disponível para filtrar, mostra os cards abaixo da tabela com os totalizadores
        contagemDemanda = listaDemandaContagem.find((contagem) => {
            const ucEstaNoFiltro = contagem.id_unidade_consumidora == buscarPropriedadeUcFiltrada("id");
            return ucEstaNoFiltro;
        });
    }

    return (
        <ContentCard
            style={{
                color: "#fff",
                padding: 8,
                display:
                    !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("id_grupo_faturamento") == 1 || buscarPropriedadeUcFiltrada("id_grupo_faturamento") == 3
                        ? ""
                        : "none",
            }}
        >
            <ContentCardBody
                style={{
                    backgroundColor: "#30303d",
                    overflowX: "scroll",
                }}
            >
                {state.mobile ? (
                    <div>
                        <div id="graficoDemandaMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                    </div>
                ) : (
                    <div id="graficoDemandaMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                )}
                <ContentCardBody
                    style={{
                        minWidth: 890,
                    }}
                >
                    <Table responsive>
                        <THead>
                            <Tr>
                                <Th>Demanda kW</Th>
                                <Th
                                    alignCenter
                                    style={{
                                        backgroundColor: demandaMesInicial == "01" ? "#66bb6a" : demandaMesFinal == "01" ? "#ef5350" : null,
                                    }}
                                >
                                    Janeiro
                                    {demandaMesInicial == "01" ? " (I)" : demandaMesFinal == "01" ? " (F)" : ""}
                                </Th>
                                <Th
                                    alignCenter
                                    style={{
                                        backgroundColor: demandaMesInicial == "02" ? "#66bb6a" : demandaMesFinal == "02" ? "#ef5350" : null,
                                    }}
                                >
                                    Fevereiro
                                    {demandaMesInicial == "02" ? " (I)" : demandaMesFinal == "02" ? " (F)" : ""}
                                </Th>
                                <Th
                                    alignCenter
                                    style={{
                                        backgroundColor: demandaMesInicial == "03" ? "#66bb6a" : demandaMesFinal == "03" ? "#ef5350" : null,
                                    }}
                                >
                                    Março
                                    {demandaMesInicial == "03" ? " (I)" : demandaMesFinal == "03" ? " (F)" : ""}
                                </Th>
                                <Th
                                    alignCenter
                                    style={{
                                        backgroundColor: demandaMesInicial == "04" ? "#66bb6a" : demandaMesFinal == "04" ? "#ef5350" : null,
                                    }}
                                >
                                    Abril
                                    {demandaMesInicial == "04" ? " (I)" : demandaMesFinal == "04" ? " (F)" : ""}
                                </Th>
                                <Th
                                    alignCenter
                                    style={{
                                        backgroundColor: demandaMesInicial == "05" ? "#66bb6a" : demandaMesFinal == "05" ? "#ef5350" : null,
                                    }}
                                >
                                    Maio
                                    {demandaMesInicial == "05" ? " (I)" : demandaMesFinal == "05" ? " (F)" : ""}
                                </Th>
                                <Th
                                    alignCenter
                                    style={{
                                        backgroundColor: demandaMesInicial == "06" ? "#66bb6a" : demandaMesFinal == "06" ? "#ef5350" : null,
                                    }}
                                >
                                    Junho
                                    {demandaMesInicial == "06" ? " (I)" : demandaMesFinal == "06" ? " (F)" : ""}
                                </Th>
                                <Th
                                    alignCenter
                                    style={{
                                        backgroundColor: demandaMesInicial == "07" ? "#66bb6a" : demandaMesFinal == "07" ? "#ef5350" : null,
                                    }}
                                >
                                    Julho
                                    {demandaMesInicial == "07" ? " (I)" : demandaMesFinal == "07" ? " (F)" : ""}
                                </Th>
                                <Th
                                    alignCenter
                                    style={{
                                        backgroundColor: demandaMesInicial == "08" ? "#66bb6a" : demandaMesFinal == "08" ? "#ef5350" : null,
                                    }}
                                >
                                    Agosto
                                    {demandaMesInicial == "08" ? " (I)" : demandaMesFinal == "08" ? " (F)" : ""}
                                </Th>
                                <Th
                                    alignCenter
                                    style={{
                                        backgroundColor: demandaMesInicial == "09" ? "#66bb6a" : demandaMesFinal == "09" ? "#ef5350" : null,
                                    }}
                                >
                                    Setembro
                                    {demandaMesInicial == "09" ? " (I)" : demandaMesFinal == "09" ? " (F)" : ""}
                                </Th>
                                <Th
                                    alignCenter
                                    style={{
                                        backgroundColor: demandaMesInicial == "10" ? "#66bb6a" : demandaMesFinal == "10" ? "#ef5350" : null,
                                    }}
                                >
                                    Outubro
                                    {demandaMesInicial == "10" ? " (I)" : demandaMesFinal == "10" ? " (F)" : ""}
                                </Th>
                                <Th
                                    alignCenter
                                    style={{
                                        backgroundColor: demandaMesInicial == "11" ? "#66bb6a" : demandaMesFinal == "11" ? "#ef5350" : null,
                                    }}
                                >
                                    Novembro
                                    {demandaMesInicial == "11" ? " (I)" : demandaMesFinal == "11" ? " (F)" : ""}
                                </Th>
                                <Th
                                    alignCenter
                                    style={{
                                        backgroundColor: demandaMesInicial == "12" ? "#66bb6a" : demandaMesFinal == "12" ? "#ef5350" : null,
                                    }}
                                >
                                    Dezembro
                                    {demandaMesInicial == "12" ? " (I)" : demandaMesFinal == "12" ? " (F)" : ""}
                                </Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {anos.map((ano, i) => {
                                //adiciona cada período de teste encontrado na lista, para ser exibido na tabela
                                const listaPeriodoTeste = [];
                                listaDemandaContagem.map((demandaContagem) => {
                                    (demandaContagem.periodo_teste || []).map((periodoTeste) => {
                                        if (
                                            filtro.unidades_consumidoras.length == 1 &&
                                            filtro.unidades_consumidoras.find((uc) => uc.value == demandaContagem.id_unidade_consumidora)
                                        ) {
                                            listaPeriodoTeste.push(JSON.parse(periodoTeste));
                                        }
                                    });
                                });
                                return (
                                    <>
                                        {
                                            //se existir agendamento na UC selecionada, lista abaixo da 2ª linha da tabela, como linha amarela
                                            listaAgendamentoDemanda.length && i == 2
                                                ? listaAgendamentoDemanda.map((agendamento) => (
                                                      <Tr key={agendamento.competencia_formatada} style={{ backgroundColor: "#f1cf1a", color: "#111" }}>
                                                          <Td>{agendamento.competencia_formatada}</Td>
                                                          {Object.keys(agendamento)
                                                              .filter((item) => item.substring(0, 6) === "valor_")
                                                              .map((_, indiceAgendamento) => (
                                                                  <Td key={indiceAgendamento} alignCenter color="#111">
                                                                      {parseFloat(agendamento[`valor_${indiceAgendamento + 1}`] || 0)
                                                                          .toFixed(2)
                                                                          .replace(".", ",")}
                                                                  </Td>
                                                              ))}
                                                      </Tr>
                                                  ))
                                                : null
                                        }
                                        <Tr key={ano}>
                                            <Td color="#fff">{ano}</Td>
                                            {listaGraficoDemanda.map((mes) => {
                                                const anoMesEstaEmTeste = !!listaPeriodoTeste.find((periodoTeste) => periodoTeste.mes === mes.mes && periodoTeste.ano === ano);
                                                if (mes.mes == demandaMesInicial) {
                                                    if (demandaAnoInicial == ano) {
                                                        corCiclo = corCiclo == "#30303d" ? "#595967" : "#30303d";
                                                    } else {
                                                        corCiclo = "#30303d";
                                                    }
                                                }

                                                if (mes.anos.find((anoMes) => anoMes.ano == ano)) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            if (anoMes.ano.includes("Demanda Proposta")) {
                                                                return (
                                                                    <Td
                                                                        key={anoMes.ano}
                                                                        alignCenter
                                                                        backgroundColor={corCiclo}
                                                                        style={{
                                                                            border: anoMesEstaEmTeste ? "3px solid #257bfb" : null,
                                                                        }}
                                                                    >
                                                                        <NumberFormat
                                                                            className="form-control"
                                                                            style={{
                                                                                textAlign: "center",
                                                                                maxWidth: 80,
                                                                                height: 20,
                                                                                fontSize: 13,
                                                                                backgroundColor: anoMes.ano.includes("Demanda Proposta Máxima") ? "#cfd8dc" : null,
                                                                            }}
                                                                            disabled={anoMes.ano.includes("Demanda Proposta Máxima")}
                                                                            placeholder={"0"}
                                                                            decimalSeparator={","}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={2}
                                                                            value={String(anoMes.valor || "").replace(".", ",")}
                                                                            onChange={(data) => {
                                                                                let listaDemanda = listaGraficoDemanda.map((item) => {
                                                                                    return {
                                                                                        mes: item.mes,
                                                                                        anos: item.anos.map((itemAno) => {
                                                                                            if (mes.mes == item.mes && itemAno.ano == anoMes.ano) {
                                                                                                return {
                                                                                                    ...itemAno,
                                                                                                    valor: data.target.value.replace(",", "."),
                                                                                                };
                                                                                            } else if (mes.mes == item.mes && itemAno.ano == `${anoMes.ano} Máxima`) {
                                                                                                return {
                                                                                                    ...itemAno,
                                                                                                    valor: (parseFloat(data.target.value.replace(",", ".")) * 1.05).toFixed(2),
                                                                                                };
                                                                                            } else {
                                                                                                return itemAno;
                                                                                            }
                                                                                        }),
                                                                                    };
                                                                                });

                                                                                getListaGraficoDemanda(listaDemanda);

                                                                                const graficoDemandaMensalAtualizado = montarGraficoClusteredColumnLineMensal(
                                                                                    state,
                                                                                    graficoDemandaMensal,
                                                                                    "graficoDemandaMensal",
                                                                                    listaDemanda,
                                                                                    "Demanda Mensal (kW)"
                                                                                );
                                                                                setGraficoDemandaMensal(graficoDemandaMensalAtualizado);
                                                                            }}
                                                                        />
                                                                    </Td>
                                                                );
                                                            } else {
                                                                let corDemanda = "";
                                                                if (!anoMes.ano.includes("Demanda Contratada")) {
                                                                    let demandaAtual = contratoDemanda
                                                                        ? contratoDemanda.itens.filter((demandaItem) => demandaItem.competencia == `${anoMes.ano}${anoMes.mes}`)[0]
                                                                        : null;

                                                                    if (demandaAtual) {
                                                                        if (demandaAtual.quantidade * 1.05 <= anoMes.valor) {
                                                                            corDemanda = "#ef5350";
                                                                        } else if (demandaAtual.quantidade <= anoMes.valor) {
                                                                            corDemanda = "#66bb6a";
                                                                        } else {
                                                                            corDemanda = corCiclo;
                                                                        }
                                                                    } else {
                                                                        corDemanda = corCiclo;
                                                                    }
                                                                }
                                                                return (
                                                                    <Td
                                                                        key={anoMes.ano + "-" + anoMes.mes}
                                                                        alignCenter
                                                                        color="#fff"
                                                                        backgroundColor={corDemanda}
                                                                        style={{
                                                                            border: anoMesEstaEmTeste ? "3px solid #257bfb" : null,
                                                                        }}
                                                                    >
                                                                        {parseFloat(anoMes.valor || 0)
                                                                            .toFixed(2)
                                                                            .replace(".", ",")}
                                                                    </Td>
                                                                );
                                                            }
                                                        });
                                                } else {
                                                    return (
                                                        <Td
                                                            key={ano + "-" + mes.mes}
                                                            alignCenter
                                                            backgroundColor={corCiclo}
                                                            color="#fff"
                                                            style={{
                                                                border: anoMesEstaEmTeste ? "3px solid #257bfb" : null,
                                                            }}
                                                        >
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}

                                            {(() => {
                                                let listaMeses = [];
                                                for (let i = listaGraficoDemanda.length; i < 12; i++) {
                                                    listaMeses.push(i);
                                                }

                                                return listaMeses.map((mes) => (
                                                    <Td key={mes} alignCenter color="#fff">
                                                        0,00
                                                    </Td>
                                                ));
                                            })()}
                                        </Tr>
                                    </>
                                );
                            })}
                        </TBody>
                    </Table>
                </ContentCardBody>
                {contagemDemanda ? (
                    <Row>
                        <Grid cols="12 4 4 4">
                            <div className="info-box bg-yellow">
                                <span className="info-box-icon">
                                    <i className="fas fa-circle-notch"></i>
                                </span>
                                <div className="info-box-content">
                                    <span className="info-box-text">FATURAS DO CICLO RURAL</span>
                                    <span className="info-box-number">{contagemDemanda.lidas}</span>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            style={{
                                                width: `${(contagemDemanda.lidas * 100) / contagemDemanda.competencias}%`,
                                            }}
                                        ></div>
                                    </div>
                                    <span className="progress-description">
                                        {
                                            /*contagemDemanda.atingidas -
                              contagemDemanda.ultrapassagens*/ contagemDemanda.lidas
                                        }{" "}
                                        de 12{/*contagemDemanda.lidas*/}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid cols="12 4 4 4">
                            <div className="info-box bg-green">
                                <span className="info-box-icon">
                                    <i className="fas fa-angle-up"></i>
                                </span>
                                <div className="info-box-content">
                                    <span className="info-box-text">ATINGIU SEM ULTRAPASSAR</span>
                                    <span className="info-box-number">{contagemDemanda.atingidas - contagemDemanda.ultrapassagens}</span>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            style={{
                                                width: `${((contagemDemanda.lidas - (contagemDemanda.atingidas - contagemDemanda.ultrapassagens)) * 100) / contagemDemanda.lidas}%`,
                                            }}
                                        ></div>
                                    </div>
                                    <span className="progress-description">
                                        {
                                            /*contagemDemanda.atingidas -
                              contagemDemanda.ultrapassagens*/ contagemDemanda.lidas
                                        }{" "}
                                        de 12{/*contagemDemanda.lidas*/}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid cols="12 4 4 4">
                            <div className="info-box bg-red">
                                <span className="info-box-icon">
                                    <i className="fas fa-angle-double-up"></i>
                                </span>
                                <div className="info-box-content">
                                    <span className="info-box-text">ATINGIU COM ULTRAPASSAGEM</span>
                                    <span className="info-box-number">{contagemDemanda.ultrapassagens}</span>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            style={{
                                                width: `${(contagemDemanda.ultrapassagens * 100) / contagemDemanda.lidas}%`,
                                            }}
                                        ></div>
                                    </div>
                                    <span className="progress-description">
                                        {
                                            /*contagemDemanda.atingidas -
                              contagemDemanda.ultrapassagens*/ contagemDemanda.lidas
                                        }{" "}
                                        de 12{/*contagemDemanda.lidas*/}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                    </Row>
                ) : null}
                {filtro.id_tipo_contrato_demanda == 3 ? null : (
                    <ContentCardBody
                        style={{
                            minWidth: 890,
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th alignCenter colSpan="3">
                                        Atingidas
                                    </Th>
                                    <Th>Unidade Consumidora</Th>
                                    <Th></Th>
                                    <Th></Th>
                                    <Th alignCenter>Início</Th>
                                    <Th alignCenter>Fim</Th>
                                    <Th alignCenter>Faturas do Ciclo</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {listaDemandaContagem.map((item) => {
                                    if (item.data_desligamento) return null;
                                    //Configura o Tooltip de agendamento de demanda-------------------------------------------------------------------------------------
                                    let tooltipAgendamentoDemanda = (item.agendamentos || []).length ? (
                                        <div
                                            style={{
                                                backgroundColor: "#f1cf1a",
                                                color: "#111",
                                                padding: 4,
                                                borderRadius: "0.3rem",
                                                fontSize: "0.8rem",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexWrap: "wrap",
                                                width: 170,
                                                margin: 3,
                                            }}
                                        >
                                            <div style={{ margin: 0, textAlign: "center", minWidth: "100%" }}>
                                                <u>
                                                    <b>Agendamento de Alteração de Demanda:</b>
                                                </u>
                                            </div>
                                            {item.agendamentos.map((agendamentoDemanda) => (
                                                <div style={{ margin: 0, textAlign: "center", minWidth: "100%" }}>{`${agendamentoDemanda.mensagem_tooltip}`}</div>
                                            ))}
                                        </div>
                                    ) : null;
                                    //----------------------------------------------------------------------------------------------------------------------------------

                                    let restantes = item.competencias - item.lidas;
                                    let cor = "#fff";
                                    if (item.atingidas < 3) {
                                        if (restantes <= 3 && item.atingidas <= 1) {
                                            cor = "#c92e2b";
                                        } else if (restantes <= 4 && item.atingidas <= 2) {
                                            cor = "#eeff41";
                                        }
                                    }
                                    //Configura o Tooltip de período de testes ---------------------------------------------------------------------------------------
                                    const periodoTeste = item.periodo_teste || [];
                                    let tooltipPeriodoTeste = null;
                                    if (periodoTeste.length) {
                                        const inicioTeste = JSON.parse(periodoTeste[0]).mes + "/" + JSON.parse(periodoTeste[0]).ano;
                                        const fimTeste = JSON.parse(periodoTeste[periodoTeste.length - 1]).mes + "/" + JSON.parse(periodoTeste[periodoTeste.length - 1]).ano;
                                        tooltipPeriodoTeste = (
                                            <div
                                                style={{
                                                    backgroundColor: "#257bfb",
                                                    padding: 4,
                                                    borderRadius: "0.3rem",
                                                    fontSize: "0.8rem",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flexWrap: "wrap",
                                                    width: 190,
                                                    minHeight: `${1.3 * ((item.agendamentos || []).length + 2)}rem`, //+2 pq o tooltip teste tem 2 linhas
                                                    margin: 3,
                                                }}
                                            >
                                                <div style={{ margin: 0, textAlign: "center", minWidth: "100%" }}>
                                                    <u>
                                                        <b>{`Período de Testes ativo: `}</b>
                                                    </u>
                                                </div>
                                                <div style={{ margin: 0, textAlign: "center", minWidth: "100%" }}>{`Início ${inicioTeste} - Fim ${fimTeste}`}</div>
                                            </div>
                                        );
                                    }
                                    //----------------------------------------------------------------------------------------------------------------------------------
                                    return (
                                        <Tr key={item.id_unidade_consumidora}>
                                            <Td color={"#fff"} width={40} backgroundColor={item.atingidas >= 1 ? "#28a745" : "#fff"} alignCenter></Td>
                                            <Td
                                                width={40}
                                                borderLeft="1px solid #81c784"
                                                borderRight="1px solid #81c784"
                                                color={item.atingidas >= 2 ? "#fff" : "#000"}
                                                backgroundColor={item.atingidas >= 2 ? "#28a745" : "#fff"}
                                                alignCenter
                                            >
                                                {item.atingidas}
                                            </Td>
                                            <Td color={"#fff"} width={40} backgroundColor={item.atingidas >= 3 ? "#28a745" : "#fff"} alignCenter></Td>
                                            <Td color="#fff">{item.nome_unidade_consumidora}</Td>
                                            <Td>{tooltipAgendamentoDemanda}</Td>
                                            <Td color="#fff">{tooltipPeriodoTeste}</Td>
                                            <Td color="#fff" alignCenter>
                                                {String(item.competencia_inicio).substring(4, 6)}/{String(item.competencia_inicio).substring(0, 4)}
                                            </Td>
                                            <Td color="#fff" alignCenter>
                                                {String(item.competencia_fim).substring(4, 6)}/{String(item.competencia_fim).substring(0, 4)}
                                            </Td>
                                            <Td
                                                color={cor}
                                                title={
                                                    item.competencias - item.lidas > 3 - item.atingidas || item.atingidas >= 3
                                                        ? null
                                                        : item.competencias - item.lidas == 3 - item.atingidas
                                                        ? `É necessário atingir ${3 - item.atingidas} demanda${3 - item.atingidas > 1 ? "s" : ""} e resta${
                                                              item.competencias - item.lidas > 1 ? "m" : ""
                                                          } ${item.competencias - item.lidas} fatura${item.competencias - item.lidas > 1 ? "s" : ""} no ciclo rural`
                                                        : `É necessário atingir ${3 - item.atingidas} demanda${3 - item.atingidas > 1 ? "s" : ""} e ${
                                                              item.competencias == item.lidas
                                                                  ? "o ciclo rural já está completo"
                                                                  : `resta${item.competencias - item.lidas > 1 ? "m" : ""} ${item.competencias - item.lidas} fatura${
                                                                        item.competencias - item.lidas > 1 ? "s" : ""
                                                                    } no ciclo rural`
                                                          }`
                                                }
                                                backgroundColor={
                                                    item.competencias - item.lidas > 3 - item.atingidas || item.atingidas >= 3
                                                        ? null
                                                        : item.competencias - item.lidas == 3 - item.atingidas
                                                        ? "#ffb74d"
                                                        : "#e57373"
                                                }
                                                alignCenter
                                            >
                                                {item.lidas || 1} de {item.competencias}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </ContentCardBody>
                )}
            </ContentCardBody>
        </ContentCard>
    );
}
const mapStateToProps = (state) => ({
    filtro: state.analise.filtro,
    listaGraficoDemanda: state.analise.listaGraficoDemanda,
    listaDemandaContagem: state.analise.listaDemandaContagem,
    listaAgendamentoDemanda: state.analise.listaAgendamentoDemanda,
    contratoDemanda: state.analise.contratoDemanda,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDemanda,
            getDemandaContagem,
            getListaGraficoDemanda,
        },
        dispatch
    );
const AnaliseDemanda = connect(mapStateToProps, mapDispatchToProps)(analiseDemanda);
export { AnaliseDemanda };
