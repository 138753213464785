import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputNumber from "../common/form/labelAndInputNumber";
import LabelAndInputMask from "../common/form/labelAndInputMask";
import LabelAndInputDateTime from "../common/form/labelAndInputDateTime";
import Select from "../common/form/select";
import SelectAlteracao from "../common/form/selectAlteracao";
import FormatUtils from "../common/formatUtils/FormatUtils";

import { setModoTela, initFormAmpliacao as initForm } from "./geracaoActions";

class GeracaoAmpliacaoForm extends Component {
	state = {};

	componentWillMount() {}

	render() {
		let readOnly = this.props.excluir || this.props.visualizacao ? "readOnly" : "";

		let unidadeConsumidora = this.props.listaUnidadeConsumidora.filter((item) => item.id == this.props.formularioValues.id_unidade_consumidora)[0];

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name="competencia"
								component={LabelAndInputMask}
								label="Competência"
								placeholder="Informe a competência"
								cols="12 6 4"
								mask="99/9999"
								readOnly={readOnly}
							/>
						</Row>

						<Row>
							<LabelAndInputNumber
								name="valor_investido"
								label="Valor Investido"
								placeholder="Informe a valor"
								cols="12 4 3 4"
								casas={2}
								readOnly={readOnly}
								value={this.props.formularioValues.valor_investido}
								onChange={(data) => {
									this.props.initForm({
										...this.props.formularioValues,
										valor_investido: data.target.value,
									});
								}}
							/>
						</Row>

						<ContentCard>
							<ContentCardHeader>
								<label>Produção Esperada</label>
							</ContentCardHeader>
							<ContentCardBody>
								<Row>
									<LabelAndInputNumber
										name="janeiro"
										label="Janeiro"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.janeiro}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												janeiro: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="fevereiro"
										label="Fevereiro"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.fevereiro}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												fevereiro: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="marco"
										label="Março"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.marco}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												marco: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="abril"
										label="Abril"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.abril}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												abril: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="maio"
										label="Maio"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.maio}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												maio: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="junho"
										label="Junho"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.junho}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												junho: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="julho"
										label="Julho"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.julho}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												julho: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="agosto"
										label="Agosto"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.agosto}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												agosto: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="setembro"
										label="Setembro"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.setembro}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												setembro: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="outubro"
										label="Outubro"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.outubro}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												outubro: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="novembro"
										label="Novembro"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.novembro}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												novembro: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="dezembro"
										label="Dezembro"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.dezembro}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												dezembro: data.target.value,
											});
										}}
									/>
								</Row>
							</ContentCardBody>
						</ContentCard>

						<ContentCard>
							<ContentCardHeader>
								<label>Informações do Inversor</label>
							</ContentCardHeader>
							<ContentCardBody>
								<Row>
									<LabelAndInputNumber
										name="inversor_quantidade"
										label="Quantidade"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={0}
										readOnly={readOnly}
										value={this.props.formularioValues.inversor_quantidade}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												inversor_quantidade: data.target.value,
											});
										}}
									/>

									<Field
										name="inversor_marca_modelo"
										component={LabelAndInput}
										label="Marca e Modelo"
										placeholder="Informe a marca e o modelo"
										cols="6 4 3 6"
										readOnly={readOnly}
									/>

									<LabelAndInputNumber
										name="inversor_potencia_individual"
										label="Potênia Individual (Kw)"
										placeholder="Informe a potência"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.inversor_potencia_individual}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												inversor_potencia_individual: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="inversor_potencia_total"
										label="Potência Total (KW)"
										placeholder="Informe a potência"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.inversor_potencia_total}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												inversor_potencia_total: data.target.value,
											});
										}}
									/>
								</Row>
							</ContentCardBody>
						</ContentCard>

						<ContentCard>
							<ContentCardHeader>
								<label>Informações do Módulo</label>
							</ContentCardHeader>
							<ContentCardBody>
								<Row>
									<LabelAndInputNumber
										name="modulos_quantidade"
										label="Quantidade"
										placeholder="Informe a quantidade"
										cols="6 4 3 2"
										casas={0}
										readOnly={readOnly}
										value={this.props.formularioValues.modulos_quantidade}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												modulos_quantidade: data.target.value,
											});
										}}
									/>

									<Field
										name="modulos_marca_modelo"
										component={LabelAndInput}
										label="Marca e Modelo"
										placeholder="Informe a marca e o modelo"
										cols="12 6 3 6"
										readOnly={readOnly}
									/>

									<LabelAndInputNumber
										name="modulos_potencia_individual"
										label="Potênia Individual (Wtts)"
										placeholder="Informe a potência"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.modulos_potencia_individual}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												modulos_potencia_individual: data.target.value,
											});
										}}
									/>

									<LabelAndInputNumber
										name="modulos_potencia_total"
										label="Potência Total (Kw)"
										placeholder="Informe a potência"
										cols="6 4 3 2"
										casas={2}
										readOnly={readOnly}
										value={this.props.formularioValues.modulos_potencia_total}
										onChange={(data) => {
											this.props.initForm({
												...this.props.formularioValues,
												modulos_potencia_total: data.target.value,
											});
										}}
									/>
								</Row>
							</ContentCardBody>
						</ContentCard>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols="6 6 4 3">
								{this.props.excluir ? (
									<Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} disabled={this.props.visualizacao} />
								) : (
									<Button text="Salvar" submit type={"success"} icon={"fa fa-check"} disabled={this.props.visualizacao} />
								)}
							</Grid>
							<Grid cols="6 6 4 3">
								<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
	}
}

GeracaoAmpliacaoForm = reduxForm({ form: "geracaoAmpliacaoForm", destroyOnUnmount: false })(GeracaoAmpliacaoForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues("geracaoAmpliacaoForm")(state),
	registro: state.geracao.registro,
	listaCliente: state.geracao.listaCliente,
	listaUnidadeConsumidora: state.geracao.listaUnidadeConsumidora,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(GeracaoAmpliacaoForm);
