const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaProduto: [],
	listaUnidadeMedida: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'FATURA_ITEM_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'FATURA_ITEM_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'FATURA_ITEM_PRODUTO_SELECT_LISTADO':
			return {
				...state,
				listaProduto: action.payload.data
			};

        case 'FATURA_ITEM_UNIDADE_MEDIDA_SELECT_LISTADO':
			return {
				...state,
				listaUnidadeMedida: action.payload.data
			};

        default:
            return state;
    }
}
