import React from "react";
import Grid from "../layout/grid";

export default (props) => {
  return (
    <Grid cols={props.cols}>
      <div className="form-group">
        <label htmlFor={props.name}>{props.label}</label>
        {props.onChange ? (
          <input
            {...props.input}
            className="form-control"
            placeholder={props.placeholder}
            disabled={props.readOnly}
            type={props.type}
            value={(props.input || props).value}
            onChange={props.onChange}
            maxLength={props.maxLength}
          />
        ) : (
          <input
            {...props.input}
            className="form-control"
            placeholder={props.placeholder}
            disabled={props.readOnly}
            type={props.type}
            value={(props.input || props).value}
            maxLength={props.maxLength}
          />
        )}
      </div>
    </Grid>
  );
};
