import React from "react";
import Grid from "../layout/grid";
import Select from "react-select";

export default (props) => {
	const propsInput = props.input || {};
	const listaOptions = (props.options || []).map((opt) => ({
		value: String(opt.id),
		label: opt.valor,
	}));
	const valueRecebido = props.value || propsInput.value;
	const valueMultiplo = valueRecebido instanceof Array && valueRecebido.length >= 0;
	const valueIndividual = listaOptions.find((opt) => opt.value == valueRecebido) || "";

	const valueSelecionado = valueMultiplo ? valueRecebido : valueIndividual;

	const onChange = props.onChange || propsInput.onChange;
	const onBlur = () => (propsInput.onBlur ? propsInput.onBlur() : null);
	return (
		<Grid cols={props.cols} style={{ marginBlock: props.gridMarginBlock != null ? props.gridMarginBlock : 3 }}>
			<div className="form-group" style={{ ...props.style }}>
				<label htmlFor={props.name}>{props.label}</label>
				<Select
					menuPortalTarget={document.body}
					styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
					placeholder={props.placeholder}
					isDisabled={props.readOnly}
					value={valueSelecionado}
					isClearable={props.isClearable == null || props.isClearable}
					isSearchable={props.isSearchable == null || props.isSearchable}
					isMulti={props.isMulti}
					onChange={(item) => onChange(item ? (props.getTarget ? { name: props.name, value: item.value } : props.isMulti ? item : item.value) : null)}
					onBlur={() => onBlur()}
					options={listaOptions}
				/>
			</div>
		</Grid>
	);
};
