import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";

import AuthReducer from "../auth/authReducer";
import DashboardReducer from "../dashboard/dashboardReducer";
import TabReducer from "../common/tab/tabReducer";
import UsuarioReducer from "../usuario/usuarioReducer";
import ClienteReducer from "../cliente/clienteReducer";
import EmpenhoReducer from "../empenho/empenhoReducer";
import FaturaReducer from "../fatura/faturaReducer";
import DisplayPDFReducer from "../displayPDF/displayPDFReducer";
import FaturaRelatorioReducer from "../faturaRelatorio/faturaRelatorioReducer";
import FaturaItemReducer from "../fatura/faturaItem/faturaItemReducer";
import GeracaoReducer from "../geracao/geracaoReducer";
import AnaliseReducer from "../analise/analiseReducer";
import DemandaContratoReducer from "../demandaContrato/demandaContratoReducer";
import RedeReducer from "../rede/redeReducer";
import GeracaoIntegracaoReducer from "../geracaoIntegracao/geracaoIntegracaoReducer";
import GeracaoLeituraReducer from "../geracaoLeitura/geracaoLeituraReducer";
import DocumentoReducer from "../documento/documentoReducer";
import DocumentoAdmReducer from "../documentoAdm/documentoAdmReducer";
import NotificacaoReducer from "../notificacao/notificacaoReducer";
import GestaoProcessoReducer from "../gestaoProcesso/gestaoProcessoReducer";
import TributacaoReducer from "../tributacao/tributacaoReducer";

const rootReducer = combineReducers({
	auth: AuthReducer,
	dashboard: DashboardReducer,
	usuario: UsuarioReducer,
	cliente: ClienteReducer,
	empenho: EmpenhoReducer,
	fatura: FaturaReducer,
	displayPDF: DisplayPDFReducer,
	faturaItem: FaturaItemReducer,
	faturaRelatorio: FaturaRelatorioReducer,
	demandaContrato: DemandaContratoReducer,
	geracao: GeracaoReducer,
	analise: AnaliseReducer,
	rede: RedeReducer,
	geracaoIntegracao: GeracaoIntegracaoReducer,
	geracaoLeitura: GeracaoLeituraReducer,
	tributacao: TributacaoReducer,
	documento: DocumentoReducer,
	documentoAdm: DocumentoAdmReducer,
	notificacao: NotificacaoReducer,
	gestaoProcesso: GestaoProcessoReducer,
	tab: TabReducer,
	form: formReducer,
	toastr: toastrReducer,
});

export default rootReducer;
