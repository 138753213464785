import React from "react";
export default function divBox(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 80,
        height: 80,
        borderRadius: "0.3rem",
        border: "1px solid #17a2b8",
        color: "#17a2b8",
      }}
    >
      {props.children}
    </div>
  );
}
