import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./faturaForm";
import Select from "../common/form/select";
import { Loading } from "../common/loading/loading";
import { DisplayPDF } from "../displayPDF/displayPDF";
import {
	setModoTela,
	initForm,
	setFiltro,
	salvar,
	salvarMultiplos,
	excluir,
	getLista,
	getListaCliente,
	getListaUnidadeConsumidora,
	buscaPdf,
} from "./faturaActions";
import { reprocessar, reprocessarTodas, setAguardando } from "./faturaItem/faturaItemActions";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import { htmlToPdf } from "../htmlToPdf/htmlToPdf";

import TabelaFaturaEngenhariaPDF from "../htmlToPdf/TabelaFaturaEngenhariaPDF";
import TableInCell from "../common/table/tableInCell";
import { adicionarPontosMilhar } from "../utils/adicionarPontosMilhar";
import { setError } from "../common/toast/toast";

class FaturaEngenharia extends Component {
    state = {
        statusAmareloClaro: true,
        statusAmareloEscuro: true,
        statusLaranja: true,
        statusRoxo: true,
        statusBranco: true,
    };

    async componentWillMount() {
        this.props.getListaCliente();
        this.props.getListaUnidadeConsumidora();

        if (this.props.usuarioLogado && this.props.usuarioLogado.administrador) {
            await this.props.getLista();
        } else if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
            this.props.setFiltro({
                ...this.props.filtro,
                id_cliente: this.props.usuarioLogado.id_cliente,
            });
            await this.props.getLista();
        }
    }

    render() {
        if (this.props.pdf) {
            return (
                <DisplayPDF closePdf={this.props.setArquivoPDF} pdfBase64={this.props.pdf} nomePdf={this.props.nomePdf} orientation={this.props.orientation} zoomParametro={1.5} />
            );
        }
        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}
                <Content>
                    {this.props.modoTela == "engenharia" ? <Form engenharia /> : null}
                    {this.props.modoTela == "lista" ? this.lista() : null}
                </Content>
            </div>
        );
    }

    getStyleSlimCard(bgColor, textColor) {
        return {
            borderRadius: 8,
            cursor: "pointer",
            backgroundColor: bgColor,
            color: textColor || null,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 10px 0px 10px",
            width: "100%",
            minHeight: "40px",
            margin: 5,
            boxShadow: "#9b9b9b 0px 1px 2px 1px",
        };
    }

    lista() {
        let totalValorImportado = 0;
        let totalValorFatura = 0;
        let totalenergia = 0;
        let totalEnergiaPonta = 0;
        let totalEnergiaForaPonta = 0;
        let totalEnergiaReservado = 0;
        let totalDemanda = 0;
        let totalDemandaPonta = 0;
        let totalDemandaGeracao = 0;
        let totalValorDemanda = 0;
        let totalValorDemandaPonta = 0;
        let totalValorDemandaGeracao = 0;
        let totalEnergiaReativo = 0;
        let totalGastoReativo = 0;
        let totalGastoDemandaComplementar = 0;
        let totalGastoDemandaComplementarPonta = 0;
        let totalGastoDemandaUltrapassagem = 0;
        let totalGastoDemandaUltrapassagemPonta = 0;
        let totalGastoDemandaUltrapassagemGeracao = 0;

        let statusAtivos = [];
        if (this.state.statusAmareloClaro) statusAtivos.push("Fatura com multa leve");
        if (this.state.statusAmareloEscuro) statusAtivos.push("Fatura com multa moderada");
        if (this.state.statusLaranja) statusAtivos.push("Faturas com multa elevada");
        if (this.state.statusRoxo) statusAtivos.push("Fatura com multa severa");
        if (this.state.statusBranco) statusAtivos.push("Sem multa regulatória");
        if (this.state.statusAmareloClaro && this.state.statusAmareloEscuro && this.state.statusLaranja && this.state.statusRoxo && this.state.statusBranco)
            statusAtivos = "Todas as faturas";

        //DADOS PARA IMPRESSÃO DO RELATÓRIO ---------------
        const cabecalho = (
            <NewTr
                backgroundColorTr={"#FFFFFF"}
                backgroundColorTd={"#F9F9F9"}
                celulas={
                    <>
                        <Th style={{ fontSize: "0.8rem" }} sticky minWidth={50}>
                            Status
                        </Th>
                        <Th style={{ fontSize: "0.8rem" }} sticky minWidth={90}>
                            Competência
                        </Th>
                        <Th style={{ fontSize: "0.8rem" }} sticky minWidth={50}>
                            Cliente
                        </Th>
                        <Th style={{ fontSize: "0.8rem" }} sticky minWidth={50}>
                            UC
                        </Th>
                        <Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
                            Total Fatura
                        </Th>
                        <Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
                            Energia (kWh)
                        </Th>

                        <Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
                            Demanda
                        </Th>

                        <Th style={{ fontSize: "0.7rem" }} sticky minWidth={70} alignCenter>
                            ICMS (%)
                        </Th>

                        <Th style={{ fontSize: "0.7rem" }} sticky minWidth={70} alignCenter backgroundColor="#E0E0E0">
                            Multa Reativo (R$)
                        </Th>
                        <Th style={{ fontSize: "0.7rem" }} sticky minWidth={105} alignCenter backgroundColor="#E0E0E0">
                            Multa Demanda Complementar (R$)
                        </Th>
                        <Th style={{ fontSize: "0.7rem" }} sticky minWidth={110} alignCenter backgroundColor="#E0E0E0">
                            Multa Demanda Ultrapassagem (R$)
                        </Th>
                        <Th style={{ fontSize: "0.7rem" }} sticky minWidth={110} alignCenter backgroundColor="#E0E0E0">
                            Multa Total (R$)
                        </Th>
                        <Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
                            PDF
                        </Th>
                    </>
                }
            />
        );
        const totalCelulas = cabecalho.props.celulas.props.children.length;
        const linhaModificada = Array.from(cabecalho.props.celulas.props.children).splice(0, totalCelulas - 1);
        const cabecalhoSemColunasBotoes = (
            <NewTr
                celulas={linhaModificada.map((el, i) => {
                    const copia = React.cloneElement(
                        el,
                        {
                            key: i,
                            sticky: false,
                            style: { fontSize: "0.85rem", paddingTop: 30 },
                        },
                        [...el.props.children]
                    );
                    return copia;
                })}
            />
        );

        let rodape = null;
        const linhas = [];
        //-------------------------------------------------
        return (
            <>
                <ContentCard>
                    <ContentCardBody>
                        <Row>
                            {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                                <Select
                                    name="id_cliente"
                                    options={this.props.listaCliente.filter((item) => {
                                        return this.props.lista.find((registro) => registro.id_cliente == item.id);
                                    })}
                                    label="Cliente"
                                    cols="12 6 4"
                                    placeholder="Selecione o Cliente"
                                    value={this.props.filtro.id_cliente}
                                    onChange={(value) => {
                                        this.props.setFiltro({
                                            ...this.props.filtro,
                                            id_cliente: value,
                                        });
                                        this.props.getLista();
                                    }}
                                />
                            ) : null}

                            <Select
                                name="id_unidade_consumidora"
                                options={this.props.listaUnidadeConsumidora.filter((item) => {
                                    const clienteSelecionado = !this.props.filtro.id_cliente || item.id_cliente == this.props.filtro.id_cliente;
                                    const existeRegistroDaUC = this.props.lista.find((registro) => registro.id_unidade_consumidora == item.id);
                                    return clienteSelecionado && existeRegistroDaUC;
                                })}
                                label="Unidade Consumidora"
                                cols="12 6 4"
                                placeholder="Selecione a UC"
                                value={this.props.filtro.id_unidade_consumidora}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        id_unidade_consumidora: value,
                                    });
                                    this.props.getLista();
                                }}
                            />

                            <Select
                                name="ano"
                                options={Array.from({ length: 20 }, (v, i) => ({
                                    id: new Date().getFullYear() - 5 + i,
                                    valor: new Date().getFullYear() - 5 + i,
                                }))}
                                label="Ano"
                                cols="12 6 2"
                                placeholder="Selecione o ano"
                                value={this.props.filtro.ano}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        ano: value,
                                    });
                                    this.props.getLista();
                                }}
                            />

                            <Select
                                name="mes"
                                options={this.props.listaMeses}
                                label="Mês"
                                cols="12 6 2"
                                placeholder="Selecione o mês"
                                value={this.props.filtro.mes}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        mes: value,
                                    });
                                    this.props.getLista();
                                }}
                            />
                        </Row>
                        {this.props.usuarioLogado ? (
                            <details open={window.innerWidth >= 950} style={{ marginBottom: 20 }}>
                                <summary>
                                    <b>
                                        Filtro por Status
                                        {window.innerWidth >= 950 || !statusAtivos ? "" : ` (${statusAtivos})`.replace(/\,/g, ", ")}
                                    </b>
                                </summary>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        marginBottom: 20,
                                        minHeight: "fit-content",
                                    }}
                                >
                                    <Grid
                                        cols="12 12 6 6"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <label style={this.getStyleSlimCard("#6dc9ff")} htmlFor="filtro-todas">
                                            <LabelAndCheckbox
                                                style={{ justifyContent: "center" }}
                                                readOnly={this.props.aguardando}
                                                modoCard={true}
                                                colorIcon="#000"
                                                id="filtro-todas"
                                                label="Todas as faturas"
                                                onChange={(e) =>
                                                    this.setState({
                                                        ...this.state,
                                                        dadosImpressao: {
                                                            ...this.state.dadosImpressao,
                                                            linhas: [],
                                                        },
                                                        statusAmareloClaro: e.target.checked,
                                                        statusAmareloEscuro: e.target.checked,
                                                        statusLaranja: e.target.checked,
                                                        statusRoxo: e.target.checked,
                                                        statusBranco: e.target.checked,
                                                    })
                                                }
                                                value={
                                                    this.state.statusAmareloClaro &&
                                                    this.state.statusAmareloEscuro &&
                                                    this.state.statusLaranja &&
                                                    this.state.statusRoxo &&
                                                    this.state.statusBranco
                                                }
                                            />
                                        </label>
                                    </Grid>
                                    <Grid
                                        cols="12 12 6 6"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <label style={this.getStyleSlimCard("#ffffff", "#333333")} htmlFor="filtro-branco">
                                            <LabelAndCheckbox
                                                style={{ justifyContent: "center" }}
                                                readOnly={this.props.aguardando}
                                                modoCard={true}
                                                colorIcon="#000"
                                                id="filtro-branco"
                                                label="Sem multa regulatória"
                                                onChange={(e) =>
                                                    this.setState({
                                                        ...this.state,
                                                        dadosImpressao: {
                                                            ...this.state.dadosImpressao,
                                                            linhas: [],
                                                        },
                                                        statusBranco: e.target.checked,
                                                    })
                                                }
                                                value={this.state.statusBranco}
                                            />
                                        </label>
                                    </Grid>

                                    <Grid
                                        cols="12 12 6 3"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <label style={this.getStyleSlimCard("#fff176", "#000")} htmlFor="filtro-amarelo-claro">
                                            <LabelAndCheckbox
                                                style={{ justifyContent: "center" }}
                                                readOnly={this.props.aguardando}
                                                modoCard={true}
                                                colorIcon="#000"
                                                id="filtro-amarelo-claro"
                                                label="Fatura com multa leve"
                                                onChange={(e) =>
                                                    this.setState({
                                                        ...this.state,
                                                        dadosImpressao: {
                                                            ...this.state.dadosImpressao,
                                                            linhas: [],
                                                        },
                                                        statusAmareloClaro: e.target.checked,
                                                    })
                                                }
                                                value={this.state.statusAmareloClaro}
                                            />
                                        </label>
                                    </Grid>
                                    {window.innerWidth >= 992 ? (
                                        <Grid
                                            cols="12 12 6 3"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <label style={this.getStyleSlimCard("#ffb74d", "#000000")} htmlFor="filtro-amarelo-escuro">
                                                <LabelAndCheckbox
                                                    style={{ justifyContent: "center" }}
                                                    readOnly={this.props.aguardando}
                                                    modoCard={true}
                                                    colorIcon="#000"
                                                    id="filtro-amarelo-escuro"
                                                    label="Fatura com multa moderada"
                                                    onChange={(e) =>
                                                        this.setState({
                                                            ...this.state,
                                                            dadosImpressao: {
                                                                ...this.state.dadosImpressao,
                                                                linhas: [],
                                                            },
                                                            statusAmareloEscuro: e.target.checked,
                                                        })
                                                    }
                                                    divMargin={5}
                                                    value={this.state.statusAmareloEscuro}
                                                />
                                            </label>
                                        </Grid>
                                    ) : null}
                                    <Grid
                                        cols="12 12 6 3"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <label style={this.getStyleSlimCard("#ff7043", "#000000")} htmlFor="filtro-laranja">
                                            <LabelAndCheckbox
                                                style={{ justifyContent: "center" }}
                                                readOnly={this.props.aguardando}
                                                modoCard={true}
                                                colorIcon="#000"
                                                id="filtro-laranja"
                                                label="Fatura com multa elevada"
                                                onChange={(e) =>
                                                    this.setState({
                                                        ...this.state,
                                                        dadosImpressao: {
                                                            ...this.state.dadosImpressao,
                                                            linhas: [],
                                                        },
                                                        statusLaranja: e.target.checked,
                                                    })
                                                }
                                                value={this.state.statusLaranja}
                                            />
                                        </label>
                                    </Grid>
                                    <Grid
                                        cols="12 12 6 3"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <label style={this.getStyleSlimCard("#8f32a2", "#ffffff")} htmlFor="filtro-roxo">
                                            <LabelAndCheckbox
                                                style={{ justifyContent: "center" }}
                                                readOnly={this.props.aguardando}
                                                modoCard={true}
                                                colorIcon="#000"
                                                id="filtro-roxo"
                                                label="Fatura com multa severa"
                                                onChange={(e) =>
                                                    this.setState({
                                                        ...this.state,
                                                        dadosImpressao: {
                                                            ...this.state.dadosImpressao,
                                                            linhas: [],
                                                        },
                                                        statusRoxo: e.target.checked,
                                                    })
                                                }
                                                value={this.state.statusRoxo}
                                            />
                                        </label>
                                    </Grid>
                                </div>
                            </details>
                        ) : null}
                    </ContentCardBody>
                </ContentCard>

                <ContentCard>
                    <ContentCardHeader>
                        <Grid cols="12 3 3 3">
                            <Button
                                text="Imprimir Relatório"
                                type={"primary"}
                                event={async () => {
                                    if (!this.props.filtro.unidades_consumidoras.length && !this.props.filtro.id_cliente) {
                                        return setError("Selecione um cliente ou alguma unidade consumidora para gerar o relatório");
                                    }
                                    await this.gerarRelatorio({
                                        statusAtivos,
                                        cabecalhoSemColunasBotoes,
                                        rodape,
                                        linhas,
                                    });
                                }}
                            />
                        </Grid>
                    </ContentCardHeader>
                    <ContentCardBody>
                        <Table responsive>
                            <THead>{cabecalho}</THead>
                            <TBody>
                                {this.props.lista
                                    .filter((item) => {
                                        let itemStatusBranco = false;
                                        let itemStatusAmareloClaro = false;
                                        let itemStatusAmareloEscuro = false;
                                        let itemStatusLaranja = false;
                                        let itemStatusRoxo = false;
                                        let nivelErro = 0;
                                        if (item.erros_engenharia && item.erros_engenharia.length > 0) {
                                            nivelErro = item.erros_engenharia[0].nivel || 1;
                                        }
                                        switch (nivelErro) {
                                            case 1:
                                                itemStatusAmareloClaro = this.state.statusAmareloClaro;
                                                break;
                                            case 2:
                                                itemStatusAmareloEscuro = this.state.statusAmareloEscuro;
                                                break;
                                            case 3:
                                                itemStatusLaranja = this.state.statusLaranja;
                                                break;
                                            case 4:
                                                itemStatusRoxo = this.state.statusRoxo;
                                                break;
                                            default:
                                                itemStatusBranco = this.state.statusBranco;
                                                break;
                                        }
                                        return itemStatusBranco || itemStatusAmareloClaro || itemStatusAmareloEscuro || itemStatusLaranja || itemStatusRoxo;
                                    })
                                    .map((item, i, array) => {
                                        totalValorImportado += parseFloat(item.total > 0 ? item.total : 0);
                                        totalValorFatura += parseFloat(item.valor_fatura > 0 ? item.valor_fatura : 0);
                                        totalenergia += parseFloat(item.energia || 0);
                                        totalEnergiaPonta += parseFloat(item.energia_ponta || 0);
                                        totalEnergiaForaPonta += parseFloat(item.energia_fora_ponta || 0);
                                        totalEnergiaReservado += parseFloat(item.energia_reservado || 0);
                                        totalDemanda += parseFloat(item.demanda || 0);
                                        totalDemandaPonta += parseFloat(item.demanda_ponta || 0);
                                        totalDemandaGeracao += parseFloat(item.demanda_geracao || 0);
                                        totalValorDemanda += parseFloat(item.valor_demanda || 0);
                                        totalValorDemandaPonta += parseFloat(item.valor_demanda_ponta || 0);
                                        totalValorDemandaGeracao += parseFloat(item.valor_demanda_geracao || 0);
                                        totalEnergiaReativo += parseFloat(item.energia_reativo || 0);
                                        totalGastoReativo += parseFloat(item.gasto_reativo || 0);
                                        totalGastoDemandaComplementar += parseFloat(item.gasto_demanda_complementar || 0);
                                        totalGastoDemandaComplementarPonta += parseFloat(item.gasto_demanda_complementar_ponta || 0);
                                        totalGastoDemandaUltrapassagem += parseFloat(item.gasto_demanda_ultrapassagem || 0);
                                        totalGastoDemandaUltrapassagemPonta += parseFloat(item.gasto_demanda_ultrapassagem_ponta || 0);
                                        totalGastoDemandaUltrapassagemGeracao += parseFloat(item.gasto_demanda_ultrapassagem_geracao || 0);
                                        let mensagemTooltip = "";
                                        let corFundo = null;
                                        let corFundoEscuro = "#f8f2f2";
                                        let corFonte = "#000";
                                        if (item.erros_engenharia && item.erros_engenharia.length > 0) {
                                            let nivelErro = item.erros_engenharia[0].nivel || 1;

                                            switch (nivelErro) {
                                                case 1:
                                                    corFundo = "#fff176";
                                                    corFundoEscuro = "#b0a757";
                                                    corFonte = "#000";
                                                    mensagemTooltip = "multa leve, a ação de reduzir esse valor não representará logística";
                                                    break;
                                                case 2:
                                                    corFundo = "#ffb74d";
                                                    corFundoEscuro = "#c19048";
                                                    corFonte = "#000";
                                                    mensagemTooltip = "multa moderada, deve ter atenção na frequência e verificar montantes anuais no módulo de análise";
                                                    break;
                                                case 3:
                                                    corFundo = "#ff7043";
                                                    corFundoEscuro = "#b85a3d";
                                                    corFonte = "#fff";
                                                    mensagemTooltip = "multa elevada, deve ter atenção na frequência e verificar montantes anuais no módulo de análise";
                                                    break;
                                                case 4:
                                                    corFundo = "#8f32a2";
                                                    corFundoEscuro = "#641475";
                                                    corFonte = "#fff";
                                                    mensagemTooltip = "multa severa, deve buscar auxílio da engenharia para minimizar esse efeito";
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                        const destacarConsumoReativo = (item.erros_engenharia || []).find((erro) => erro.tipo === "Consumo Reativo");
                                        const destacarDemandaComplementarForaPonta = (item.erros_engenharia || []).find(
                                            (erro) => erro.tipo === "Demanda Complementar" || erro.tipo === "Demanda Complementar Fora Ponta"
                                        );
                                        const destacarDemandaComplementarPonta = (item.erros_engenharia || []).find((erro) => erro.tipo === "Demanda Complementar Ponta");

                                        const destacarDemandaUltrapassagemForaPonta = (item.erros_engenharia || []).find(
                                            (erro) => erro.tipo === "Demanda Ultrapassagem" || erro.tipo === "Demanda Ultrapassagem Fora Ponta"
                                        );
                                        const destacarDemandaUltrapassagemPonta = (item.erros_engenharia || []).find((erro) => erro.tipo === "Demanda Ultrapassagem Ponta");
                                        const destacarDemandaUltrapassagemGeracao = (item.erros_engenharia || []).find((erro) => erro.tipo === "Demanda Ultrapassagem Geração");

                                        const listaErros = (
                                            <div>
                                                <span>Verifique:</span>
                                                <ul style={{ paddingLeft: 20 }}>
                                                    {item.erros_engenharia.map((erro) => (
                                                        <li>{erro.tipo}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        );
                                        const faturaSemErros = !(item.erros_engenharia || []).length;
                                        let msgErroEngenharia = "Indefinido";
                                        if (item.engenharia_validada) {
                                            msgErroEngenharia = faturaSemErros ? "Validado" : listaErros;
                                        } else if (item.importado) {
                                            msgErroEngenharia = faturaSemErros ? "Importado" : listaErros;
                                        } else {
                                            msgErroEngenharia = "Não Importado";
                                        }

                                        const linha = (
                                            <NewTr
                                                title={mensagemTooltip}
                                                backgroundColorTr={corFundo}
                                                key={item.id}
                                                style={{
                                                    height: 40,
                                                    color: corFonte,
                                                }}
                                                celulas={
                                                    <>
                                                        <Td
                                                            style={{
                                                                fontSize: "0.8rem",
                                                            }}
                                                        >
                                                            {msgErroEngenharia}
                                                        </Td>
                                                        <Td style={{ fontSize: "0.8rem" }}>{`${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(
                                                            0,
                                                            4
                                                        )}`}</Td>
                                                        <Td style={{ fontSize: "0.8rem" }} minWidth={100}>
                                                            {item.nome_cliente}
                                                        </Td>
                                                        <Td style={{ fontSize: "0.8rem" }} minWidth={150}>
                                                            {item.nome_unidade_consumidora}
                                                        </Td>
                                                        <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                            {parseFloat(item.valor_fatura || 0)
                                                                .toFixed(2)
                                                                .replace(".", ",")}
                                                        </Td>
                                                        <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                            <TableInCell
                                                                lista={[
                                                                    {
                                                                        cabecalho: "Total (kWh)",
                                                                        valor: adicionarPontosMilhar(item.energia || 0),
                                                                    },
                                                                    {
                                                                        cabecalho: "Ponta (kWh)",
                                                                        valor: adicionarPontosMilhar(item.energia_ponta || 0),
                                                                    },
                                                                    {
                                                                        cabecalho: "Fora Ponta (kWh)",
                                                                        valor: adicionarPontosMilhar(item.energia_fora_ponta || 0),
                                                                    },
                                                                    {
                                                                        cabecalho: "Reservado (kWh)",
                                                                        valor: adicionarPontosMilhar(item.energia_reservado || 0),
                                                                    },
                                                                ]}
                                                            />
                                                        </Td>

                                                        <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                            <TableInCell
                                                                lista={[
                                                                    {
                                                                        cabecalho: "Ponta",
                                                                        valores: [
                                                                            adicionarPontosMilhar(item.demanda_ponta || 0) + " kW",
                                                                            adicionarPontosMilhar(item.valor_demanda_ponta || 0) + " R$",
                                                                        ],
                                                                    },
                                                                    {
                                                                        cabecalho: "Fora Ponta",
                                                                        valores: [
                                                                            adicionarPontosMilhar(item.demanda || 0) + " kW",
                                                                            adicionarPontosMilhar(item.valor_demanda || 0) + " R$",
                                                                        ],
                                                                    },
                                                                    {
                                                                        cabecalho: "Demanda G",
                                                                        valores: [
                                                                            adicionarPontosMilhar(item.demanda_geracao || 0) + " kW",
                                                                            adicionarPontosMilhar(item.valor_demanda_geracao || 0) + " R$",
                                                                        ],
                                                                    },
                                                                ]}
                                                            />
                                                        </Td>
                                                        <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                            <TableInCell
                                                                lista={[
                                                                    {
                                                                        cabecalho: "Esperado (%)",
                                                                        valor: isNaN(parseFloat(item.icms_esperado)) ? " - " : adicionarPontosMilhar(item.icms_esperado),
                                                                    },
                                                                    {
                                                                        cabecalho: "Importado (%)",
                                                                        valor: isNaN(parseFloat(item.icms_importado)) ? " - " : adicionarPontosMilhar(item.icms_importado),
                                                                    },
                                                                ]}
                                                            />
                                                        </Td>
                                                        <Td style={{ fontSize: "0.8rem" }} alignCenter backgroundColor={corFundoEscuro}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    height: "80%",
                                                                    width: "100%",
                                                                    padding: 2,
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    textWeight: "bold",
                                                                    backgroundColor: destacarConsumoReativo ? "#db0000" : null,
                                                                    color: destacarConsumoReativo ? "#ffffff" : null,
                                                                }}
                                                            >
                                                                {parseFloat(item.gasto_reativo || 0)
                                                                    .toFixed(2)
                                                                    .replace(".", ",")}
                                                            </div>
                                                        </Td>

                                                        <Td style={{ fontSize: "0.8rem" }} alignCenter backgroundColor={corFundoEscuro}>
                                                            <TableInCell
                                                                lista={[
                                                                    {
                                                                        cabecalho: "Ponta (R$)",
                                                                        valor: (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    height: "80%",
                                                                                    width: "100%",
                                                                                    padding: 2,
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    textWeight: "bold",
                                                                                    backgroundColor: destacarDemandaComplementarPonta ? "#db0000" : null,
                                                                                    color: destacarDemandaComplementarPonta ? "#ffffff" : null,
                                                                                }}
                                                                            >
                                                                                {adicionarPontosMilhar(item.gasto_demanda_complementar_ponta || 0)}
                                                                            </div>
                                                                        ),
                                                                    },
                                                                    {
                                                                        cabecalho: "Fora Ponta (R$)",
                                                                        valor: (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    height: "80%",
                                                                                    width: "100%",
                                                                                    padding: 2,
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    textWeight: "bold",
                                                                                    backgroundColor: destacarDemandaComplementarForaPonta ? "#db0000" : null,
                                                                                    color: destacarDemandaComplementarForaPonta ? "#ffffff" : null,
                                                                                }}
                                                                            >
                                                                                {adicionarPontosMilhar(item.gasto_demanda_complementar)}
                                                                            </div>
                                                                        ),
                                                                    },
                                                                    {
                                                                        cabecalho: "Total (R$)",
                                                                        valor: adicionarPontosMilhar(
                                                                            parseFloat(item.gasto_demanda_complementar || 0) +
                                                                                parseFloat(item.gasto_demanda_complementar_ponta || 0)
                                                                        ),
                                                                    },
                                                                ]}
                                                            />
                                                        </Td>
                                                        <Td style={{ fontSize: "0.8rem" }} alignCenter backgroundColor={corFundoEscuro}>
                                                            <TableInCell
                                                                lista={[
                                                                    {
                                                                        cabecalho: "Ponta (R$)",
                                                                        valor: (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    height: "80%",
                                                                                    width: "100%",
                                                                                    padding: 2,
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    textWeight: "bold",
                                                                                    backgroundColor: destacarDemandaUltrapassagemPonta ? "#db0000" : null,
                                                                                    color: destacarDemandaUltrapassagemPonta ? "#ffffff" : null,
                                                                                }}
                                                                            >
                                                                                {adicionarPontosMilhar(item.gasto_demanda_ultrapassagem_ponta || 0)}
                                                                            </div>
                                                                        ),
                                                                    },
                                                                    {
                                                                        cabecalho: "Fora Ponta (R$)",
                                                                        valor: (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    height: "80%",
                                                                                    width: "100%",
                                                                                    padding: 2,
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    textWeight: "bold",
                                                                                    backgroundColor: destacarDemandaUltrapassagemForaPonta ? "#db0000" : null,
                                                                                    color: destacarDemandaUltrapassagemForaPonta ? "#ffffff" : null,
                                                                                }}
                                                                            >
                                                                                {adicionarPontosMilhar(item.gasto_demanda_ultrapassagem)}
                                                                            </div>
                                                                        ),
                                                                    },
                                                                    {
                                                                        cabecalho: "Geração (R$)",
                                                                        valor: (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    height: "80%",
                                                                                    width: "100%",
                                                                                    padding: 2,
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    textWeight: "bold",
                                                                                    backgroundColor: destacarDemandaUltrapassagemGeracao ? "#db0000" : null,
                                                                                    color: destacarDemandaUltrapassagemGeracao ? "#ffffff" : null,
                                                                                }}
                                                                            >
                                                                                {adicionarPontosMilhar(item.gasto_demanda_ultrapassagem_geracao || 0)}
                                                                            </div>
                                                                        ),
                                                                    },
                                                                    {
                                                                        cabecalho: "Total (R$)",
                                                                        valor: adicionarPontosMilhar(
                                                                            parseFloat(item.gasto_demanda_ultrapassagem || 0) +
                                                                                parseFloat(item.gasto_demanda_ultrapassagem_ponta || 0) +
                                                                                parseFloat(item.gasto_demanda_ultrapassagem_geracao || 0)
                                                                        ),
                                                                    },
                                                                ]}
                                                            />
                                                        </Td>
                                                        <Td style={{ fontSize: "0.8rem" }} alignCenter backgroundColor={corFundoEscuro}>
                                                            {(
                                                                parseFloat(item.gasto_reativo || 0) +
                                                                parseFloat(item.gasto_demanda_complementar || 0) +
                                                                parseFloat(item.gasto_demanda_complementar_ponta || 0) +
                                                                parseFloat(item.gasto_demanda_ultrapassagem || 0) +
                                                                parseFloat(item.gasto_demanda_ultrapassagem_ponta || 0) +
                                                                parseFloat(item.gasto_demanda_ultrapassagem_geracao || 0)
                                                            )
                                                                .toFixed(2)
                                                                .replace(".", ",")}
                                                        </Td>

                                                        <Td style={{ fontSize: "0.8rem" }} alignCenter minWidth={50}>
                                                            {item.arquivo_pdf ? (
                                                                <ButtonTable
                                                                    type={"primary"}
                                                                    icon={"fas fa-file-pdf"}
                                                                    visible={true}
                                                                    event={() => {
                                                                        this.props.buscaPdf(
                                                                            item.id,
                                                                            `UC-${item.nome_unidade_consumidora.split("-")[0].trim()}_${String(item.competencia).substring(
                                                                                4,
                                                                                6
                                                                            )}-${String(item.competencia).substring(0, 4)}`
                                                                        );
                                                                        this.props.setArquivoPDF();
                                                                    }}
                                                                />
                                                            ) : null}
                                                        </Td>
                                                    </>
                                                }
                                                botoes={[
                                                    {
                                                        type: "primary",
                                                        icon: "fas fa-eye",
                                                        event: () => {
                                                            this.props.setModoTela("engenharia", {
                                                                ...item,
                                                                competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            });
                                                            this.props.initForm({
                                                                ...item,
                                                                competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            });
                                                        },
                                                    },
                                                ]}
                                            ></NewTr>
                                        );

                                        const totalCelulas = linha.props.celulas.props.children.length;
                                        let linhaModificada = Array.from(linha.props.celulas.props.children).splice(0, totalCelulas - 1);
                                        linhaModificada = React.Children.map(linhaModificada, (child, index) => {
                                            // 0 = Msg Erro Importação
                                            if (index == 0) {
                                                return React.cloneElement(child, {
                                                    children: <div>{msgErroEngenharia.length > 32 ? msgErroEngenharia.substring(0, 32) + "..." : msgErroEngenharia}</div>,
                                                });
                                            }

                                            // 2 = cliente
                                            if (index == 2) {
                                                return React.cloneElement(child, {
                                                    children: <div>{item.nome_cliente.length > 30 ? item.nome_cliente.substring(0, 30) + "..." : item.nome_cliente}</div>,
                                                });
                                            }

                                            // 3 = UC
                                            if (index == 3) {
                                                const nome_unidade_consumidora = item.nome_unidade_consumidora;
                                                return React.cloneElement(child, {
                                                    children: (
                                                        <div>
                                                            {nome_unidade_consumidora.length > 40 ? `${nome_unidade_consumidora.substring(0, 40)}...` : nome_unidade_consumidora}
                                                        </div>
                                                    ),
                                                });
                                            }
                                            return child;
                                        });
                                        const linhaSemColunaBotoes = React.cloneElement(linha, {
                                            celulas: linhaModificada,
                                            botoes: [],
                                        });

                                        linhas.push(linhaSemColunaBotoes);
                                        //ao chegar na última iteração constrói o rodapé
                                        if (i === array.length - 1) {
                                            rodape = this.renderizarRodape({
                                                totalValorImportado,
                                                totalValorFatura,
                                                totalenergia,
                                                totalEnergiaPonta,
                                                totalEnergiaForaPonta,
                                                totalEnergiaReservado,
                                                totalDemanda,
                                                totalDemandaPonta,
                                                totalDemandaGeracao,
                                                totalValorDemanda,
                                                totalValorDemandaPonta,
                                                totalValorDemandaGeracao,
                                                totalEnergiaReativo,
                                                totalGastoReativo,
                                                totalGastoDemandaComplementar,
                                                totalGastoDemandaComplementarPonta,
                                                totalGastoDemandaUltrapassagem,
                                                totalGastoDemandaUltrapassagemPonta,
                                                totalGastoDemandaUltrapassagemGeracao,
                                            });
                                        }

                                        return linha;
                                    })}
                                {rodape}
                            </TBody>
                        </Table>
                    </ContentCardBody>
                </ContentCard>
            </>
        );
    }

    async gerarRelatorio({ statusAtivos, cabecalhoSemColunasBotoes, rodape, linhas }) {
        this.props.setAguardando(true);
        const filtro = (
            <b style={{ fontSize: "0.8rem" }}>
                Cliente:
                {" " + ((this.props.listaCliente.find((cliente) => cliente.id == this.props.filtro.id_cliente) || {}).valor || "Todos") + " - "}
                UC:
                {" " + ((this.props.listaUnidadeConsumidora.find((uc) => uc.id == this.props.filtro.id_unidade_consumidora) || {}).valor || "Todas") + " - "}
                Ano:{" " + (this.props.filtro.ano || "") + " - "}
                Mês:
                {` ${(this.props.listaMeses.find((mes) => mes.id == this.props.filtro.mes) || {}).valor || "Todos"} - `}
                Filtro por Status: {String(statusAtivos).replace(/\,/g, ", ")}
            </b>
        );
        const pdfBase64 = await htmlToPdf(
            <TabelaFaturaEngenhariaPDF
                dadosImpressao={{
                    filtro,
                    cabecalhoSemColunasBotoes,
                    rodape,
                    linhas,
                }}
            />,
            this.props.setAguardando
        );
        this.props.setArquivoPDF(pdfBase64, "Relatório identificador de multas.pdf", "landscape");
    }

    renderizarRodape({
        totalValorImportado,
        totalValorFatura,
        totalenergia,
        totalEnergiaPonta,
        totalEnergiaForaPonta,
        totalEnergiaReservado,
        totalDemanda,
        totalDemandaPonta,
        totalDemandaGeracao,
        totalValorDemanda,
        totalValorDemandaPonta,
        totalValorDemandaGeracao,
        totalEnergiaReativo,
        totalGastoReativo,
        totalGastoDemandaComplementar,
        totalGastoDemandaComplementarPonta,
        totalGastoDemandaUltrapassagem,
        totalGastoDemandaUltrapassagemPonta,
        totalGastoDemandaUltrapassagemGeracao,
    }) {
        const rodape = (
            <Tr>
                <Td style={{ fontSize: "0.8rem" }} colSpan={4} fontWeight="bold">
                    Total
                </Td>
                <Td style={{ fontSize: "0.8rem" }} fontWeight="bold">
                    {parseFloat(totalValorFatura || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} fontWeight="bold">
                    <TableInCell
                        lista={[
                            {
                                cabecalho: "Total (kWh)",
                                valor: parseFloat(totalenergia || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Ponta (kWh)",
                                valor: parseFloat(totalEnergiaPonta || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Fora Ponta (kWh)",
                                valor: parseFloat(totalEnergiaForaPonta || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Reservado (kWh)",
                                valor: parseFloat(totalEnergiaReservado || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                        ]}
                    />
                </Td>

                <Td style={{ fontSize: "0.8rem" }} fontWeight="bold">
                    <TableInCell
                        lista={[
                            {
                                cabecalho: "Ponta",
                                valores: [
                                    parseFloat(totalDemandaPonta || 0)
                                        .toFixed(0)
                                        .replace(".", ",") + " kW",
                                    parseFloat(totalValorDemandaPonta || 0)
                                        .toFixed(0)
                                        .replace(".", ",") + " R$",
                                ],
                            },
                            {
                                cabecalho: "Fora Ponta",
                                valores: [
                                    parseFloat(totalDemanda || 0)
                                        .toFixed(0)
                                        .replace(".", ",") + " kW",
                                    parseFloat(totalValorDemanda || 0)
                                        .toFixed(0)
                                        .replace(".", ",") + " R$",
                                ],
                            },
                            {
                                cabecalho: "Demanda G",
                                valores: [
                                    parseFloat(totalDemandaGeracao || 0)
                                        .toFixed(0)
                                        .replace(".", ",") + " kW",
                                    parseFloat(totalValorDemandaGeracao || 0)
                                        .toFixed(0)
                                        .replace(".", ",") + " R$",
                                ],
                            },
                        ]}
                    />
                </Td>

                <Td style={{ fontSize: "0.8rem" }} fontWeight="bold">
                    {/*rodapé do icms*/}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {parseFloat(totalGastoReativo || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} fontWeight="bold">
                    <TableInCell
                        lista={[
                            {
                                cabecalho: "Ponta (R$)",
                                valor: parseFloat(totalGastoDemandaComplementarPonta || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Fora Ponta (R$)",
                                valor: parseFloat(totalGastoDemandaComplementar || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Total (R$)",
                                valor: parseFloat(totalGastoDemandaComplementarPonta + totalGastoDemandaComplementar)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                        ]}
                    />
                </Td>
                <Td style={{ fontSize: "0.8rem" }} fontWeight="bold">
                    <TableInCell
                        lista={[
                            {
                                cabecalho: "Ponta (R$)",
                                valor: parseFloat(totalGastoDemandaUltrapassagemPonta || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Fora Ponta (R$)",
                                valor: parseFloat(totalGastoDemandaUltrapassagem || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Geração (R$)",
                                valor: parseFloat(totalGastoDemandaUltrapassagemGeracao || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Total (R$)",
                                valor: parseFloat(totalGastoDemandaUltrapassagem + totalGastoDemandaUltrapassagemPonta + totalGastoDemandaUltrapassagemGeracao)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                        ]}
                    />
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {parseFloat(
                        totalGastoReativo +
                            totalGastoDemandaComplementarPonta +
                            totalGastoDemandaComplementar +
                            totalGastoDemandaUltrapassagem +
                            totalGastoDemandaUltrapassagemPonta +
                            totalGastoDemandaUltrapassagemGeracao
                    )
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td></Td>
                <Td></Td>
            </Tr>
        );
        return rodape;
    }
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.fatura.modoTela,
	aguardando: state.fatura.aguardando,
	filtro: state.fatura.filtro,
	lista: state.fatura.lista,
	listaCliente: state.fatura.listaCliente,
	listaUnidadeConsumidora: state.fatura.listaUnidadeConsumidora,
	listaMeses: state.fatura.listaMeses,
	pdf: state.displayPDF.pdf,
	nomePdf: state.displayPDF.nomePdf,
	orientation: state.displayPDF.orientation,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			setFiltro,
			salvar,
			salvarMultiplos,
			excluir,
			getLista,
			getListaCliente,
			getListaUnidadeConsumidora,
			reprocessar,
			reprocessarTodas,
			buscaPdf,
			setArquivoPDF,
			setAguardando,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(FaturaEngenharia);
