import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardFooter from "../common/template/contentCardFooter";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./demandaContratoForm";
import FormAgendamento from "./demandaAgendamentoForm";
import Select from "../common/form/select";
import { DisplayPDF } from "../displayPDF/displayPDF";
import { Loading } from "../common/loading/loading";

import {
    setModoTela,
    initForm,
    setFiltro,
    salvar,
    excluir,
    salvarAgendamento,
    excluirAgendamento,
    getLista,
    getListaCliente,
    getListaUnidadeConsumidora,
    getListaDemandaContratoTipo,
    importarContrato,
    importarCusd,
    importarCcer,
    importarCicloRural,
    buscaContrato,
    buscaCusd,
    buscaCcer,
    buscaCicloRural,
} from "./demandaContratoActions";

import { setArquivoPDF } from "../displayPDF/displayPDFActions";

class DemandaContrato extends Component {
    state = {
        file: null,
    };
    componentWillMount() {
        this.props.getListaCliente();
        this.props.getListaUnidadeConsumidora();
        this.props.getListaDemandaContratoTipo();
        this.props.getLista();
    }
    render() {
        //Sem acesso
        if (!this.props.usuarioLogado || this.props.usuarioLogado.acesso_cliente) {
            return <div />;
        }
        if (this.props.pdf) {
            return <DisplayPDF closePdf={() => this.props.setArquivoPDF()} pdfBase64={this.props.pdf} nomePdf={this.props.nomePdf} />;
        }
        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}

                <Content>
                    {this.props.modoTela === "visualizacao" ? <Form visualizar onSubmit={() => {}} /> : null}
                    {this.props.modoTela === "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
                    {this.props.modoTela === "importarContrato" ? this.importarContrato() : null}
                    {this.props.modoTela === "importarCusd" ? this.importarCusd() : null}
                    {this.props.modoTela === "importarCcer" ? this.importarCcer() : null}
                    {this.props.modoTela === "importarCicloRural" ? this.importarCicloRural() : null}
                    {this.props.modoTela === "cadastroAgendamento" ? <FormAgendamento onSubmit={this.props.salvarAgendamento} /> : null}
                    {this.props.modoTela === "exclusaoAgendamento" ? <FormAgendamento excluir onSubmit={this.props.excluirAgendamento} /> : null}
                    {this.props.modoTela === "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
                    {this.props.modoTela === "lista" ? this.lista() : null}
                </Content>
            </div>
        );
    }

    lista() {
        return (
            <ContentCard>
                <ContentCardHeader>
                    <Row>
                        <Grid cols="6 6 4 2">
                            <Button
                                text="Adicionar"
                                type={"success"}
                                icon={"fa fa-plus"}
                                disabled={!this.props.usuarioLogado.acesso_cadastro_demanda_adicionar}
                                event={() => {
                                    this.props.setModoTela("cadastro", {});
                                    this.props.initForm({ itens: [] });
                                }}
                            />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody>
                    <Row>
                        <Select
                            name="id_cliente"
                            options={this.props.listaCliente}
                            label="Cliente"
                            cols="12 6 4"
                            placeholder="Selecione o Cliente"
                            value={this.props.filtro.id_cliente}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_cliente: value,
                                });
                                this.props.getLista();
                            }}
                        />

                        <Select
                            name="id_unidade_consumidora"
                            options={this.props.listaUnidadeConsumidora.filter((item) => {
                                return (
                                    (!this.props.filtro.id_cliente || item.id_cliente == this.props.filtro.id_cliente) &&
                                    this.props.lista.find((contrato) => contrato.id_unidade_consumidora == item.id)
                                );
                            })}
                            label="Unidade Consumidora"
                            cols="12 6 4"
                            placeholder="Selecione a UC"
                            value={this.props.filtro.id_unidade_consumidora}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_unidade_consumidora: value,
                                });
                                this.props.getLista();
                            }}
                        />
                    </Row>

                    <Table responsive>
                        <THead>
                            <NewTr
                                backgroundColorTr={"#FFFFFF"}
                                backgroundColorTd={"#F5F5F5"}
                                celulas={
                                    <>
                                        <Th sticky colSpan={1}>
                                            Ciclo Rural
                                        </Th>
                                        <Th sticky colSpan={1}>
                                            Cliente
                                        </Th>
                                        <Th sticky colSpan={1}>
                                            UC
                                        </Th>
                                        <Th sticky colSpan={1} alignCenter>
                                            Contrato CUSD
                                        </Th>
                                        <Th sticky colSpan={1} alignCenter>
                                            Contrato CCER
                                        </Th>
                                        <Th sticky colSpan={1} alignCenter>
                                            Ciclo Rural
                                        </Th>
                                    </>
                                }
                            />
                        </THead>
                        <TBody>
                            {this.props.lista.map((item) => {
                                return (
                                    <NewTr
                                        key={item.id}
                                        backgroundColorTr={"#FFFFFF"}
                                        backgroundColorTd={"#F5F5F5"}
                                        celulas={
                                            <>
                                                <Td>{`${this.props.listaMeses.filter((mes) => mes.id == item.mes_inicio)[0].valor} até ${
                                                    this.props.listaMeses.filter((mes) => mes.id == item.mes_fim)[0].valor
                                                }`}</Td>
                                                <Td>{item.nome_cliente}</Td>
                                                <Td>{item.nome_unidade_consumidora}</Td>
                                                <Td alignCenter minWidth={50}>
                                                    {item.arquivo_cusd ? (
                                                        <ButtonTable
                                                            type={"primary"}
                                                            icon={"fas fa-file-pdf"}
                                                            visible={true}
                                                            disabled={!this.props.usuarioLogado.acesso_cadastro_demanda_visualizar}
                                                            event={() => {
                                                                this.props.buscaCusd(item.id, `CUSD_UC-${item.nome_unidade_consumidora.split("-")[0].trim()}`);
                                                                this.props.setArquivoPDF();
                                                            }}
                                                        />
                                                    ) : null}
                                                </Td>
                                                <Td alignCenter minWidth={50}>
                                                    {item.arquivo_ccer ? (
                                                        <ButtonTable
                                                            type={"primary"}
                                                            icon={"fas fa-file-pdf"}
                                                            visible={true}
                                                            disabled={!this.props.usuarioLogado.acesso_cadastro_demanda_visualizar}
                                                            event={() => {
                                                                this.props.buscaCcer(item.id, `CCER_UC-${item.nome_unidade_consumidora.split("-")[0].trim()}`);
                                                                this.props.setArquivoPDF();
                                                            }}
                                                        />
                                                    ) : null}
                                                </Td>
                                                <Td alignCenter minWidth={50}>
                                                    {item.arquivo_ciclo_rural ? (
                                                        <ButtonTable
                                                            type={"primary"}
                                                            icon={"fas fa-file-pdf"}
                                                            visible={true}
                                                            disabled={!this.props.usuarioLogado.acesso_cadastro_demanda_visualizar}
                                                            event={() => {
                                                                this.props.buscaCicloRural(item.id, `ciclo_rural_UC-${item.nome_unidade_consumidora.split("-")[0].trim()}`);
                                                                this.props.setArquivoPDF();
                                                            }}
                                                        />
                                                    ) : null}
                                                </Td>
                                            </>
                                        }
                                        botoes={[
                                            {
                                                type: "primary",
                                                icon: "fas fa-eye",
                                                disabled: !this.props.usuarioLogado.acesso_cadastro_demanda_visualizar,
                                                event: () => {
                                                    //cria a lista de valores valor_1 ..... valor_12 e adiciona ao registro
                                                    const valores = {};
                                                    for (let i = 1; i <= 12; i++) {
                                                        valores[`valor_${i}`] = parseFloat(item[`valor_${i}`]) > 0 ? parseFloat(item[`valor_${i}`]) : null;
                                                        valores[`valor_${i}_ponta`] = parseFloat(item[`valor_${i}_ponta`]) > 0 ? parseFloat(item[`valor_${i}_ponta`]) : null;
                                                        valores[`valor_${i}_geracao`] = parseFloat(item[`valor_${i}_geracao`]) > 0 ? parseFloat(item[`valor_${i}_geracao`]) : null;
                                                    }
                                                    const registro = { ...item, ...valores };
                                                    this.props.setModoTela("visualizacao", registro);
                                                    this.props.initForm(registro);
                                                },
                                            },
                                            {
                                                type: "warning",
                                                icon: "fas fa-pencil-alt",
                                                disabled: !this.props.usuarioLogado.acesso_cadastro_demanda_editar || item.bloquearEdicaoUsuarioImplantacao,
                                                event: () => {
                                                    //cria a lista de valores valor_1 ..... valor_12 e adiciona ao registro
                                                    const valores = {};
                                                    for (let i = 1; i <= 12; i++) {
                                                        valores[`valor_${i}`] = parseFloat(item[`valor_${i}`]) > 0 ? parseFloat(item[`valor_${i}`]) : null;
                                                        valores[`valor_${i}_ponta`] = parseFloat(item[`valor_${i}_ponta`]) > 0 ? parseFloat(item[`valor_${i}_ponta`]) : null;
                                                        valores[`valor_${i}_geracao`] = parseFloat(item[`valor_${i}_geracao`]) > 0 ? parseFloat(item[`valor_${i}_geracao`]) : null;
                                                    }
                                                    const registro = { ...item, ...valores };

                                                    this.props.setModoTela("cadastro", registro);
                                                    this.props.initForm(registro);
                                                },
                                            },

                                            {
                                                type: "danger",
                                                icon: "fas fa-trash-alt",
                                                disabled: !this.props.usuarioLogado.acesso_cadastro_demanda_excluir || item.bloquearEdicaoUsuarioImplantacao,
                                                event: () => {
                                                    //cria a lista de valores valor_1 ..... valor_12 e adiciona ao registro
                                                    const valores = {};
                                                    for (let i = 1; i <= 12; i++) {
                                                        valores[`valor_${i}`] = parseFloat(item[`valor_${i}`]) > 0 ? parseFloat(item[`valor_${i}`]) : null;
                                                        valores[`valor_${i}_ponta`] = parseFloat(item[`valor_${i}_ponta`]) > 0 ? parseFloat(item[`valor_${i}_ponta`]) : null;
                                                        valores[`valor_${i}_geracao`] = parseFloat(item[`valor_${i}_geracao`]) > 0 ? parseFloat(item[`valor_${i}_geracao`]) : null;
                                                    }
                                                    const registro = { ...item, ...valores };
                                                    this.props.setModoTela("exclusao", registro);
                                                    this.props.initForm(registro);
                                                },
                                            },
                                        ]}
                                    />
                                );
                            })}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }

    importarContrato() {
        return (
            <ContentCard>
                <ContentCardBody>
                    <div className="form-group">
                        <div className="custom-file">
                            <input
                                className="custom-file-input"
                                type="file"
                                id="customFile"
                                value={this.state.file}
                                onChange={(event) => {
                                    event.preventDefault();
                                    let reader = new FileReader();
                                    let file = event.target.files[0];

                                    // if (!file.type.includes('application/pdf')) {
                                    // 	//toastr.error('Erro', 'Selecione apenas PDF.');
                                    // 	return;
                                    // }

                                    reader.onloadend = () => {
                                        this.props.importarContrato(this.props.formularioValues, reader.result);
                                    };

                                    reader.readAsDataURL(file);
                                }}
                            />
                            <label className="custom-file-label" for="customFile">
                                Selecione
                            </label>
                        </div>
                    </div>
                </ContentCardBody>
                <ContentCardFooter>
                    <Row alignCenter>
                        <Grid cols="6 6 4 3">
                            <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("cadastro")} />
                        </Grid>
                    </Row>
                </ContentCardFooter>
            </ContentCard>
        );
    }

    importarCusd() {
        return (
            <ContentCard>
                <ContentCardBody>
                    <div className="form-group">
                        <div className="custom-file">
                            <input
                                className="custom-file-input"
                                type="file"
                                id="customFile"
                                value={this.state.file}
                                onChange={(event) => {
                                    event.preventDefault();
                                    let reader = new FileReader();
                                    let file = event.target.files[0];

                                    // if (!file.type.includes('application/pdf')) {
                                    // 	//toastr.error('Erro', 'Selecione apenas PDF.');
                                    // 	return;
                                    // }

                                    reader.onloadend = () => {
                                        this.props.importarCusd(this.props.formularioValues, reader.result);
                                    };

                                    reader.readAsDataURL(file);
                                }}
                            />
                            <label className="custom-file-label" for="customFile">
                                Selecione
                            </label>
                        </div>
                    </div>
                </ContentCardBody>
                <ContentCardFooter>
                    <Row alignCenter>
                        <Grid cols="6 6 4 3">
                            <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("cadastro")} />
                        </Grid>
                    </Row>
                </ContentCardFooter>
            </ContentCard>
        );
    }

    importarCcer() {
        return (
            <ContentCard>
                <ContentCardBody>
                    <div className="form-group">
                        <div className="custom-file">
                            <input
                                className="custom-file-input"
                                type="file"
                                id="customFile"
                                value={this.state.file}
                                onChange={(event) => {
                                    event.preventDefault();
                                    let reader = new FileReader();
                                    let file = event.target.files[0];

                                    // if (!file.type.includes('application/pdf')) {
                                    // 	//toastr.error('Erro', 'Selecione apenas PDF.');
                                    // 	return;
                                    // }

                                    reader.onloadend = () => {
                                        this.props.importarCcer(this.props.formularioValues, reader.result);
                                    };

                                    reader.readAsDataURL(file);
                                }}
                            />
                            <label className="custom-file-label" for="customFile">
                                Selecione
                            </label>
                        </div>
                    </div>
                </ContentCardBody>
                <ContentCardFooter>
                    <Row alignCenter>
                        <Grid cols="6 6 4 3">
                            <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("cadastro")} />
                        </Grid>
                    </Row>
                </ContentCardFooter>
            </ContentCard>
        );
    }

    importarCicloRural() {
        return (
            <ContentCard>
                <ContentCardBody>
                    <div className="form-group">
                        <div className="custom-file">
                            <input
                                className="custom-file-input"
                                type="file"
                                id="customFile"
                                value={this.state.file}
                                onChange={(event) => {
                                    event.preventDefault();
                                    let reader = new FileReader();
                                    let file = event.target.files[0];

                                    // if (!file.type.includes('application/pdf')) {
                                    // 	//toastr.error('Erro', 'Selecione apenas PDF.');
                                    // 	return;
                                    // }

                                    reader.onloadend = () => {
                                        this.props.importarCicloRural(this.props.formularioValues, reader.result);
                                    };

                                    reader.readAsDataURL(file);
                                }}
                            />
                            <label className="custom-file-label" for="customFile">
                                Selecione
                            </label>
                        </div>
                    </div>
                </ContentCardBody>
                <ContentCardFooter>
                    <Row alignCenter>
                        <Grid cols="6 6 4 3">
                            <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("cadastro")} />
                        </Grid>
                    </Row>
                </ContentCardFooter>
            </ContentCard>
        );
    }
}

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    modoTela: state.demandaContrato.modoTela,
    formularioValues: getFormValues("demandaContratoForm")(state),
    aguardando: state.demandaContrato.aguardando,
    filtro: state.demandaContrato.filtro,
    lista: state.demandaContrato.lista,
    listaCliente: state.demandaContrato.listaCliente,
    listaUnidadeConsumidora: state.demandaContrato.listaUnidadeConsumidora,
    listaMeses: state.demandaContrato.listaMeses,
    pdf: state.displayPDF.pdf,
    nomePdf: state.displayPDF.nomePdf,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initForm,
            setFiltro,
            salvar,
            excluir,
            salvarAgendamento,
            excluirAgendamento,
            importarContrato,
            importarCusd,
            importarCcer,
            importarCicloRural,
            getLista,
            getListaCliente,
            getListaUnidadeConsumidora,
            getListaDemandaContratoTipo,
            buscaContrato,
            buscaCusd,
            buscaCcer,
            buscaCicloRural,
            setArquivoPDF,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(DemandaContrato);
