import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import InfoBox from "../common/template/infoBox";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Select from "../common/form/select";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark.js";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";
import FormatUtils from "../common/formatUtils/FormatUtils";
import { DisplayPDF } from "../displayPDF/displayPDF";

import {
    setFiltro,
    getListaGraficoCustoPorUnidadeUltimoMes,
    getListaGraficoCustoPorUnidadeAcumuladoAno,
    getDemanda,
    getDemandaContagem,
    getListaGraficoCustoAnual,
    getListaConsumoPercentualPorHorarioUltimoMes,
    getListaCliente,
    getListaUnidadeConsumidora,
    getListaDemandaContratoTipo,
    getListaGraficoGeracaoQuantidadeAnual,
} from "./dashboardActions";

import { getTotalFaturasImportadas, buscaPdf } from "../fatura/faturaActions";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";
import { getTotalUsuarios, getLogados } from "../usuario/usuarioActions";
import { Loading } from "../common/loading/loading";

class Dashboard extends Component {
    state = {
        aguardando: false,
        cores: [
            am4core.color("#1976d2"),
            am4core.color("#dfe30b"),
            am4core.color("#d65e33"),
            am4core.color("#00c853"),
            am4core.color("#9575cd"),
            am4core.color("#ff9800"),
        ],
        graficoCustoAnual: null,
        graficoCustoUnidadeUltimoMes: null,
        dialogLogados: false,
        modalLogadosSemana: false,
    };

    componentDidUpdate(prevProps) {
        const fechouPDF = prevProps.pdf !== this.props.pdf && !this.props.pdf;
        const mudouUsuarioLogado =
            this.props.usuarioLogado &&
            JSON.stringify(prevProps.usuarioLogado) !== JSON.stringify(this.props.usuarioLogado);

        //ao abrir o sistema no dashboard, inicialmente usuarioLogado é nulo, até carregar o state
        //então a hora que tiver usuario logado, seta o filtro pra carregar a tabela de resumo de demanda
        // e carrega os graficos com o respectivo filtro
        if (mudouUsuarioLogado) {
            this.props.setFiltro({
                ...this.props.filtro,
                id_cliente: this.props.usuarioLogado.administrador ? null : this.props.usuarioLogado.id_cliente,
            });
            this.props.getDemandaContagem();
            this.listar(this.props.usuarioLogado.administrador || this.props.listaGraficoCustoAnual.length > 0);
        }

        if (fechouPDF && this.props.usuarioLogado) {
            this.listar(this.props.usuarioLogado.administrador || this.props.listaGraficoCustoAnual.length > 0);
        }
    }

    componentWillMount() {
        this.props.getTotalUsuarios();
        this.props.getLogados();
        this.props.getTotalFaturasImportadas();
        this.props.getListaCliente();
        this.props.getListaUnidadeConsumidora();
        this.props.getListaDemandaContratoTipo();

        //caso abra o sistema em outra tela que não seja o dashboard, ao abrir o dashboard já terá usuário logado
        //então seta o filtro id_cliente para listar a tabela de resumo de demanda faz a listagem dos graficos
        if (this.props.usuarioLogado) {
            this.props.setFiltro({
                ...this.props.filtro,
                id_cliente: this.props.usuarioLogado.administrador ? null : this.props.usuarioLogado.id_cliente,
            });

            this.props.getDemandaContagem();
            this.listar(this.props.usuarioLogado.administrador || this.props.listaGraficoCustoAnual.length > 0);
        }
    }

    componentWillUnmount() {
        if (this.graficoCustoAnual) {
            this.graficoCustoAnual.dispose();
        }
        if (this.graficoCustoUnidadeUltimoMes) {
            this.graficoCustoUnidadeUltimoMes.dispose();
        }
    }

    listar(naoAtualizar) {
        if (!naoAtualizar) {
            this.props.getDemanda();
        }

        let retornos = 0;

        if (naoAtualizar) {
            this.graficoCustoAnual = this.montarGraficoClusteredColumnAnual(
                this.graficoCustoAnual,
                "graficoCustoAnual",
                this.props.listaGraficoCustoAnual,
                "Custo Geral Acumulado Anual (R$)",
                "R$",
                ""
            );

            this.graficoCustoUnidadeUltimoMes = this.montarGraficoPieGrupo(
                this.graficoCustoUnidadeUltimoMes,
                "graficoCustoUnidadeUltimoMes",
                this.props.listaGraficoCustoUnidadeUltimoMes.map((item) => {
                    return {
                        ...item,
                        agrupador: `${item.agrupador.substring(
                            0,
                            item.agrupador.length > 30 ? 30 : item.agrupador.length
                        )}${item.agrupador.length > 30 ? "..." : ""}`,
                        evento: () => {
                            this.props.buscaPdf(item.id_fatura, `${item.agrupador}`, true);

                            this.props.setArquivoPDF();
                        },
                    };
                }),
                `Representação de Custos ${
                    this.props.filtro.mes_base == 1 ? "da Última Fatura" : "da Fatura do Mês Anterior"
                } de Cada UC (R$)`,
                "R$",
                ""
            );
        } else {
            this.setState({
                ...this.state,
                aguardando: true,
            });

            this.validarAguardando(retornos, 4);

            this.props.getListaGraficoCustoAnual(() => {
                // console.log(`CUSTO: ${(new Date().getTime() - time) / 1000}`);
                retornos++;
                this.validarAguardando(retornos, 4);
                this.graficoCustoAnual = this.montarGraficoClusteredColumnAnual(
                    this.graficoCustoAnual,
                    "graficoCustoAnual",
                    this.props.listaGraficoCustoAnual,
                    "Custo Geral Acumulado Anual (R$)",
                    "R$",
                    ""
                );
            });
            this.props.getListaGraficoCustoPorUnidadeUltimoMes(() => {
                retornos++;
                this.validarAguardando(retornos, 4);
                this.graficoCustoUnidadeUltimoMes = this.montarGraficoPieGrupo(
                    this.graficoCustoUnidadeUltimoMes,
                    "graficoCustoUnidadeUltimoMes",
                    this.props.listaGraficoCustoUnidadeUltimoMes.map((item) => {
                        return {
                            ...item,
                            agrupador: `${item.agrupador.substring(
                                0,
                                item.agrupador.length > 30 ? 30 : item.agrupador.length
                            )}${item.agrupador.length > 30 ? "..." : ""}`,
                            evento: () => {
                                this.props.buscaPdf(item.id_fatura, `${item.agrupador}`, true);
                                this.props.setArquivoPDF();
                            },
                        };
                    }),
                    `Representação de Custos ${
                        this.props.filtro.mes_base == 1 ? "da Última Fatura" : "da Fatura do Mês Anterior"
                    } de Cada UC (R$)`,
                    "R$",
                    ""
                );
            });

            this.props.getListaGraficoCustoPorUnidadeAcumuladoAno(() => {
                retornos++;
                this.validarAguardando(retornos, 4);
            });

            this.props.getListaConsumoPercentualPorHorarioUltimoMes(() => {
                retornos++;
                this.validarAguardando(retornos, 4);
            });

            this.props.getListaGraficoGeracaoQuantidadeAnual(() => {
                // console.log(`GERACAO: ${(new Date().getTime() - time) / 1000}`);
                // retornos++;
                // this.validarAguardando(retornos, 4);
            });
        }
    }

    validarAguardando(retornos, total) {
        if (retornos == total) {
            this.setState({ ...this.state, aguardando: false });
        }
    }

    montarGraficoClusteredColumnAnual(component, nome, dados = [], titulo, prefixo, sufixo) {
        if (component) {
            component.dispose();
        }

        let chart = am4core.create(nome, am4charts.XYChart3D);
        chart.colors.step = 1;

        chart.colors.list = this.state.cores;

        chart.language.locale = locale;

        let title = chart.titles.create();
        title.text = titulo;
        title.fontSize = 16;
        title.marginBottom = 30;

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "Gráfico";
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.menu.items = [
            {
                label: "...",
                menu: [
                    {
                        type: "png",
                        label: "PNG",
                    },
                ],
            },
        ];

        // chart.legend = new am4charts.Legend()
        // chart.legend.position = 'top'
        // chart.legend.paddingBottom = 20
        // chart.legend.labels.template.maxWidth = 95
        chart.language.locale["_decimalSeparator"] = ",";
        chart.language.locale["_thousandSeparator"] = ".";
        chart.numberFormatter.numberFormat = "###,###,###,###.00";

        var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        xAxis.dataFields.category = "category";
        xAxis.renderer.cellStartLocation = 0.1;
        xAxis.renderer.cellEndLocation = 0.9;
        xAxis.renderer.grid.template.location = 0;
        xAxis.renderer.labels.template.wrap = true;
        xAxis.renderer.labels.template.maxWidth = 200;

        var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = 0;

        let arrangeColumns = () => {
            var series = chart.series.getIndex(0);

            var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
            if (series.dataItems.length > 1) {
                var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
                var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
                var delta = ((x1 - x0) / chart.series.length) * w;
                if (am4core.isNumber(delta)) {
                    var middle = chart.series.length / 2;

                    var newIndex = 0;
                    chart.series.each(function (series) {
                        if (!series.isHidden && !series.isHiding) {
                            series.dummyData = newIndex;
                            newIndex++;
                        } else {
                            series.dummyData = chart.series.indexOf(series);
                        }
                    });
                    var visibleCount = newIndex;
                    var newMiddle = visibleCount / 2;

                    chart.series.each(function (series) {
                        var trueIndex = chart.series.indexOf(series);
                        var newIndex = series.dummyData;

                        var dx = (newIndex - trueIndex + middle - newMiddle) * delta;

                        series.animate(
                            { property: "dx", to: dx },
                            series.interpolationDuration,
                            series.interpolationEasing
                        );
                        series.bulletsContainer.animate(
                            { property: "dx", to: dx },
                            series.interpolationDuration,
                            series.interpolationEasing
                        );
                    });
                }
            }
        };

        let createSeries = (value, name) => {
            var series = chart.series.push(new am4charts.ColumnSeries3D());
            series.dataFields.valueY = value;
            series.dataFields.categoryX = "category";
            series.name = name;

            series.events.on("hidden", arrangeColumns);
            series.events.on("shown", arrangeColumns);

            var bullet = series.bullets.push(new am4charts.LabelBullet());
            bullet.interactionsEnabled = false;
            bullet.dy = 2;
            bullet.label.text = "{valueY}";
            bullet.label.fill = am4core.color("#ffffff");
            bullet.label.truncate = false;
            // bullet.label.hideOversized = true;

            series.columns.template.tooltipText = `{categoryX}: [bold]${prefixo} {valueY.formatNumber("###,###,###,###.00")} ${sufixo}[/]`;
            series.columns.template.fillOpacity = 0.8;

            return series;
        };

        let data = [];
        dados.forEach((item) => {
            item.anos.forEach((itemAno) => {
                if (data.filter((serie) => serie.category == itemAno.ano).length == 0) {
                    data.push({
                        category: String(itemAno.ano),
                        value: 0,
                    });
                }
                data = data.map((serie) => {
                    if (serie.category == itemAno.ano) {
                        return {
                            ...serie,
                            value: serie.value + (itemAno.valor == "NaN" ? 0 : itemAno.valor),
                        };
                    } else {
                        return serie;
                    }
                });
            });
        });

        data.sort((a, b) => {
            return a.category == "Início" ? -1 : b.category == "Início" ? 1 : a.category < b.category ? -1 : 1;
        });

        chart.data = data;

        createSeries("value", "");

        return chart;
    }

    montarGraficoPieGrupo(component, nome, dados = [], titulo, prefixo, sufixo) {
        if (component) {
            component.dispose();
        }

        var chart = am4core.create(nome, am4charts.PieChart);
        chart.colors.step = 1;
        chart.innerRadius = 50;

        chart.language.locale = locale;

        let title = chart.titles.create();
        title.text = titulo;
        title.fontSize = 16;
        title.marginBottom = 10;

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "Gráfico";
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.menu.items = [
            {
                label: "...",
                menu: [
                    {
                        type: "png",
                        label: "PNG",
                    },
                ],
            },
        ];

        // chart.legend = new am4charts.Legend()
        // chart.legend.position = 'top'
        // chart.legend.paddingBottom = 20
        // chart.legend.labels.template.maxWidth = 95
        chart.language.locale["_decimalSeparator"] = ",";
        chart.language.locale["_thousandSeparator"] = ".";
        chart.numberFormatter.numberFormat = "###,###,###,###.00";
        chart.hiddenState.properties.opacity = 0;
        // chart.legend = new am4charts.Legend();

        var series = chart.series.push(new am4charts.PieSeries3D());
        series.dataFields.value = "value";
        series.dataFields.category = "category";
        series.colors.list = this.state.cores;

        series.slices.template.tooltipText = `{category}: [bold]${prefixo} {value.formatNumber("###,###,###,###.00")} ${sufixo}[/]`;

        series.slices.template.events.on("hit", function (ev) {
            let series = ev.target.dataItem.component;
            series.slices.each(function (item) {
                if (item.isActive) {
                    item.isActive = false;
                    let itemDado = dados.filter((dado) => dado.agrupador == item.dataItem.properties.category)[0];
                    if (itemDado && itemDado.evento) {
                        itemDado.evento();
                    }
                }
            });
        });

        var interfaceColors = new am4core.InterfaceColorSet();

        let data = [];
        let total = 0;
        dados.forEach((item) => {
            data.push({
                category: item.agrupador,
                value: item.valor,
            });
            total += parseFloat(item.valor);
        });

        chart.data = data;

        var label = series.createChild(am4core.Label);
        label.text = `R$ ${FormatUtils.formatarValorTela(total)}`;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = 18;

        return chart;
    }

    render() {
        if (!this.props.usuarioLogado) return <></>;
        if (this.props.pdf) {
            return (
                <DisplayPDF
                    closePdf={() => this.props.setArquivoPDF()}
                    pdfBase64={this.props.pdf}
                    nomePdf={this.props.nomePdf}
                />
            );
        }
        am4core.useTheme(am4themes_dark);

        let unidadeConsumidora = this.props.listaUnidadeConsumidora.filter(
            (item) => item.id == this.props.filtro.id_unidade_consumidora
        )[0];

        let geracao =
            this.props.listaUnidadeConsumidora.filter(
                (item) =>
                    item.geracao &&
                    item.id_cliente ==
                        (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente
                            ? this.props.usuarioLogado.id_cliente
                            : this.props.filtro.id_cliente)
            ).length > 0;

        let valorUnidadeAnualTotal = 0;
        this.props.listaGraficoCustoPorUnidadeAcumuladoAno.forEach((item) => {
            valorUnidadeAnualTotal =
                parseFloat(item.valor) > valorUnidadeAnualTotal ? parseFloat(item.valor) : valorUnidadeAnualTotal;
        });

        let todasImportadas = false;
        if (
            this.props.listaGraficoCustoUnidadeUltimoMes.length > 0 &&
            this.props.listaGraficoCustoUnidadeUltimoMes.length ==
                this.props.listaUnidadeConsumidora.filter((uc) => uc.id_cliente == this.props.filtro.id_cliente).length
        ) {
            todasImportadas = true;
        }

        return (
            <div>
                {this.state.aguardando ? <Loading /> : null}

                {this.state.dialogLogados ? this.modalLogados() : null}

                {this.state.dialogLogadosSemana ? this.modalLogadosSemana() : null}

                <Content>
                    <ContentCard>
                        <ContentCardHeader>
                            <Row>
                                {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                                    <Select
                                        name="id_cliente"
                                        options={this.props.listaCliente}
                                        label="Cliente"
                                        cols="12 4 4 4"
                                        placeholder="Selecione o Cliente"
                                        value={this.props.filtro.id_cliente}
                                        onChange={(value) => {
                                            this.props.setFiltro({
                                                ...this.props.filtro,
                                                id_cliente: value,
                                            });

                                            this.listar();
                                        }}
                                    />
                                ) : null}

                                <Select
                                    name="mes_base"
                                    options={this.props.listaMesBase}
                                    label="Mês Base"
                                    cols="12 4 4 4"
                                    placeholder="Selecione o Mês Base"
                                    value={this.props.filtro.mes_base}
                                    onChange={(value) => {
                                        this.props.setFiltro({
                                            ...this.props.filtro,
                                            mes_base: value,
                                        });

                                        this.listar();
                                    }}
                                />
                                <Select
                                    name="demanda_contrato_tipos"
                                    options={this.props.listaDemandaContratoTipo.filter((tipo) => {
                                        const ucFiltradaPeloCliente = this.props.listaUnidadeConsumidora.filter(
                                            (uc) => uc.id_cliente == this.props.filtro.id_cliente
                                        );
                                        const condicaoForaPonta = tipo.id == 1;
                                        const condicaoPonta =
                                            tipo.id == 2 &&
                                            !!ucFiltradaPeloCliente.find((uc) => uc.id_modalidade_tarifaria == 2); //alguma uc do cliente selecionado tem q ser horosazonal azul
                                        const condicaoGeracao =
                                            tipo.id == 3 && !!ucFiltradaPeloCliente.find((uc) => uc.geracao); //alguma uc do cliente selecionado tem q ser geradora
                                        return condicaoForaPonta || condicaoPonta || condicaoGeracao;
                                    })}
                                    label="Tipo de Contrato de Demanda"
                                    cols="12 4 4 4"
                                    placeholder="Selecione o tipo de contrato de demanda"
                                    value={this.props.filtro.id_tipo_contrato_demanda}
                                    onChange={(id_tipo_contrato_demanda) => {
                                        this.props.setFiltro({
                                            ...this.props.filtro,
                                            id_tipo_contrato_demanda,
                                        });
                                        this.props.getDemandaContagem();
                                        this.listar();
                                    }}
                                />
                            </Row>
                            {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                                <>
                                    <Row>
                                        <Grid cols="12 4 3 3">
                                            <InfoBox
                                                icon="fas fa-user"
                                                text="Total de Usuários"
                                                number={this.props.totalUsuarios}
                                            />
                                        </Grid>
                                        <Grid cols="12 4 3 3">
                                            <InfoBox
                                                icon="fas fa-user-check"
                                                text="Usuários Logados"
                                                number={this.props.logados}
                                                onClick={() =>
                                                    this.setState({
                                                        ...this.state,
                                                        dialogLogados: this.props.usuarioLogado.acesso_usuario_logado,
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid cols="12 4 3 3">
                                            <InfoBox
                                                icon="fas fa-calendar-check"
                                                text="Logaram na Semana"
                                                number={this.props.acessos_semana}
                                                onClick={() =>
                                                    this.setState({
                                                        ...this.state,
                                                        dialogLogadosSemana:
                                                            this.props.usuarioLogado.acesso_usuario_logado,
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid cols="12 4 3 3">
                                            <InfoBox
                                                icon="fas fa-users"
                                                text="Total de Clientes"
                                                number={this.props.listaCliente.length}
                                            />
                                        </Grid>
                                        <Grid cols="12 4 3 3">
                                            <InfoBox
                                                icon="fas fa-home"
                                                text="Total de Unidades Consumidoras"
                                                number={this.props.listaUnidadeConsumidora.length}
                                            />
                                        </Grid>

                                        <Grid cols="12 4 3 3">
                                            <InfoBox
                                                icon="fas fa-solar-panel"
                                                text="Total de Sistemas Solares"
                                                number={`${
                                                    this.props.listaUnidadeConsumidora.filter(
                                                        (item) =>
                                                            item.geracao && !item.remota && item.geracao_integracao
                                                    ).length
                                                } de ${
                                                    this.props.listaUnidadeConsumidora.filter(
                                                        (item) => item.geracao && !item.remota
                                                    ).length
                                                } unidades integradas`}
                                            />
                                        </Grid>
                                        <Grid cols="12 4 3 3">
                                            <InfoBox
                                                icon="fas fa-network-wired"
                                                text="Total de Unidades Remotas"
                                                number={
                                                    this.props.listaUnidadeConsumidora.filter((item) => item.remota)
                                                        .length
                                                }
                                            />
                                        </Grid>
                                        <Grid cols="12 4 3 3">
                                            <InfoBox
                                                icon="fas fa-file-invoice-dollar"
                                                text="Total de Faturas Importadas"
                                                number={this.props.totalFaturasImportadas}
                                            />
                                        </Grid>
                                    </Row>
                                </>
                            ) : null}
                        </ContentCardHeader>
                    </ContentCard>

                    <Row>
                        <Grid cols="12 12 12 8">
                            <ContentCard style={{ backgroundColor: "#30303d", color: "#fff" }}>
                                <ContentCardBody>
                                    {todasImportadas ? (
                                        <div
                                            style={{
                                                border: "2px solid green",
                                                textAlign: "center",
                                                width: 250,
                                                color: "green",
                                            }}
                                        >
                                            Todas as faturas foram importadas
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                border: "2px solid red",
                                                textAlign: "center",
                                                width: 250,
                                                color: "red",
                                            }}
                                        >
                                            Faltam faturas a serem importadas
                                        </div>
                                    )}
                                    <div
                                        id="graficoCustoUnidadeUltimoMes"
                                        style={{ width: "100%", height: "500px" }}
                                    ></div>
                                </ContentCardBody>
                            </ContentCard>
                            <ContentCard
                                style={{
                                    backgroundColor: "#30303d",
                                    color: "#fff",
                                    padding: 10,
                                }}
                            >
                                <ContentCardBody>
                                    <p className="text-center" style={{ fontSize: 16, margin: 0 }}>
                                        Aproveitamento Horário do{" "}
                                        {this.props.filtro.mes_base == 1 ? "Último Mês" : "Mês Anterior"}
                                    </p>
                                    <Row style={{ marginBottom: 20 }}>
                                        <Grid
                                            cols="12 12 12 12"
                                            style={{
                                                maxHeight: 450,
                                                padding: 10,
                                                overflowX: "scroll",
                                                overflowY: "hidden",
                                            }}
                                        >
                                            <Table responsive maxHeight={430} minWidth={750}>
                                                <THead>
                                                    <Tr>
                                                        <Th minHeight={67} sticky backgroundColor="#30303d"></Th>
                                                        <Th
                                                            minHeight={67}
                                                            sticky
                                                            colSpan={3}
                                                            alignCenter
                                                            backgroundColor="#44444f"
                                                            style={{ textWrap: "nowrap" }}
                                                        >
                                                            Perfil de Consumo{" "}
                                                            <p style={{ margin: 0 }}>
                                                                (Aproveitamento Horário % de kWh)
                                                            </p>
                                                        </Th>
                                                        <Th
                                                            minHeight={67}
                                                            sticky
                                                            colSpan={3}
                                                            alignCenter
                                                            backgroundColor="#30303d"
                                                            verticalAlign="middle"
                                                        >
                                                            Custos de Tarifas
                                                        </Th>
                                                        <Th
                                                            minHeight={67}
                                                            sticky
                                                            colSpan={2}
                                                            backgroundColor="#30303d"
                                                        ></Th>
                                                    </Tr>
                                                    <Tr>
                                                        <Th top={65} sticky backgroundColor="#30303d"></Th>
                                                        <Th
                                                            top={65}
                                                            sticky
                                                            alignCenter
                                                            color="#ff6e81"
                                                            backgroundColor="#44444f"
                                                        >
                                                            Horário Ponta
                                                        </Th>
                                                        <Th
                                                            top={65}
                                                            sticky
                                                            alignCenter
                                                            color="#67f087"
                                                            backgroundColor="#44444f"
                                                        >
                                                            Horário F.Ponta
                                                        </Th>
                                                        <Th
                                                            top={65}
                                                            sticky
                                                            alignCenter
                                                            color="#a3b0f0"
                                                            backgroundColor="#44444f"
                                                        >
                                                            Horário Reservado
                                                        </Th>
                                                        <Th
                                                            top={65}
                                                            sticky
                                                            alignCenter
                                                            color="#ff6e81"
                                                            backgroundColor="#30303d"
                                                        >
                                                            Horário Ponta
                                                        </Th>
                                                        <Th
                                                            top={65}
                                                            sticky
                                                            alignCenter
                                                            color="#67f087"
                                                            backgroundColor="#30303d"
                                                        >
                                                            Horário F.Ponta
                                                        </Th>
                                                        <Th
                                                            top={65}
                                                            sticky
                                                            alignCenter
                                                            color="#a3b0f0"
                                                            backgroundColor="#30303d"
                                                        >
                                                            Horário Reservado
                                                        </Th>
                                                        <Th
                                                            top={65}
                                                            sticky
                                                            alignCenter
                                                            color="#fff"
                                                            backgroundColor="#30303d"
                                                            title="Tarifa Média Bruta"
                                                        >
                                                            TMB
                                                        </Th>
                                                        <Th
                                                            top={65}
                                                            sticky
                                                            alignCenter
                                                            color="#fff"
                                                            backgroundColor="#30303d"
                                                            title="Tarifa Média Líquida (sem demanda e sem reativos"
                                                        >
                                                            TML
                                                        </Th>
                                                    </Tr>
                                                </THead>
                                                <TBody>
                                                    {this.props.listaConsumoPercentualPorHorarioUltimoMes.map(
                                                        (item, i) => {
                                                            let ponta = parseFloat(item.percentual_ponta || 0);
                                                            let foraPonta = parseFloat(item.percentual_fora_ponta || 0);
                                                            let reservado = parseFloat(item.percentual_reservado || 0);

                                                            let resto = 100 - ponta - foraPonta - reservado;

                                                            if (ponta > 0 && ponta > foraPonta && ponta > reservado) {
                                                                ponta += resto;
                                                            } else if (foraPonta > 0 && foraPonta > reservado) {
                                                                foraPonta += resto;
                                                            } else if (reservado > 0) {
                                                                reservado += resto;
                                                            }

                                                            return (
                                                                <Tr key={i}>
                                                                    <Td color="#fff">{item.agrupador}</Td>
                                                                    <Td
                                                                        alignCenter
                                                                        color="#ff6e81"
                                                                        backgroundColor="#44444f"
                                                                    >
                                                                        {ponta.toFixed(2).replace(".", ",")}%
                                                                    </Td>
                                                                    <Td
                                                                        alignCenter
                                                                        color="#67f087"
                                                                        backgroundColor="#44444f"
                                                                    >
                                                                        {foraPonta.toFixed(2).replace(".", ",")}%
                                                                    </Td>
                                                                    <Td
                                                                        alignCenter
                                                                        color="#a3b0f0"
                                                                        backgroundColor="#44444f"
                                                                    >
                                                                        {reservado.toFixed(2).replace(".", ",")}%
                                                                    </Td>
                                                                    <Td alignCenter color="#ff6e81" backgroundColor="">
                                                                        R${" "}
                                                                        {parseFloat(item.tarifa_ponta || 0)
                                                                            .toFixed(2)
                                                                            .replace(".", ",")}
                                                                    </Td>
                                                                    <Td alignCenter color="#67f087" backgroundColor="">
                                                                        R${" "}
                                                                        {parseFloat(item.tarifa_fora_ponta || 0)
                                                                            .toFixed(2)
                                                                            .replace(".", ",")}
                                                                    </Td>
                                                                    <Td alignCenter color="#a3b0f0" backgroundColor="">
                                                                        R${" "}
                                                                        {parseFloat(item.tarifa_reservado || 0)
                                                                            .toFixed(2)
                                                                            .replace(".", ",")}
                                                                    </Td>
                                                                    <Td alignCenter color="#fff" backgroundColor="">
                                                                        R${" "}
                                                                        {parseFloat(item.tarifa_media_geral || 0)
                                                                            .toFixed(2)
                                                                            .replace(".", ",")}
                                                                    </Td>
                                                                    <Td alignCenter color="#fff" backgroundColor="">
                                                                        R${" "}
                                                                        {parseFloat(item.tarifa_economia || 0)
                                                                            .toFixed(2)
                                                                            .replace(".", ",")}
                                                                    </Td>
                                                                </Tr>
                                                            );
                                                        }
                                                    )}
                                                </TBody>
                                            </Table>
                                        </Grid>
                                    </Row>
                                    <Row>
                                        <div
                                            className="callout callout-danger"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100vw",
                                                marginBottom: 10,
                                                color: "#000",
                                            }}
                                            onClick={() =>
                                                this.setState({
                                                    ...this.state,
                                                    orientacaoHorarioPonta: this.state.orientacaoHorarioPonta
                                                        ? false
                                                        : true,
                                                })
                                            }
                                        >
                                            <p>
                                                <strong style={{ color: "#bd2130" }}>Horário Ponta:</strong> Representa
                                                o custo mais elevado e tem duração de 3h por dia.
                                            </p>
                                            {true || this.state.orientacaoHorarioPonta ? (
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                colSpan="2"
                                                                style={{
                                                                    backgroundColor: "#fff59d",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                    textAlign: "left",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Orientações para Perfil de Consumo Ideal (valores
                                                                aproximados dependendo de cada local).
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    backgroundColor: "#c5e1a5",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                }}
                                                            >
                                                                Irrigações
                                                            </td>
                                                            <td
                                                                style={{
                                                                    backgroundColor: "#dcedc8",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Próximo a 1%
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    backgroundColor: "#a5d6a7",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                }}
                                                            >
                                                                Armazéns
                                                            </td>
                                                            <td
                                                                style={{
                                                                    backgroundColor: "#c8e6c9",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Entre 10% e 20%
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ) : null}
                                        </div>
                                        <div
                                            className="callout callout-success"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100vw",
                                                marginBottom: 10,
                                                color: "#000",
                                            }}
                                            onClick={() =>
                                                this.setState({
                                                    ...this.state,
                                                    orientacaoHorarioForaPonta: this.state.orientacaoHorarioForaPonta
                                                        ? false
                                                        : true,
                                                })
                                            }
                                        >
                                            <p>
                                                <strong style={{ color: "#1e7e34" }}>Horário Fora Ponta:</strong>{" "}
                                                Representa um custo mediano e representa os horarios restantes do dia.
                                            </p>
                                            {true || this.state.orientacaoHorarioForaPonta ? (
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                colSpan="2"
                                                                style={{
                                                                    backgroundColor: "#fff59d",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                    textAlign: "left",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Orientações para Perfil de Consumo Ideal (valores
                                                                aproximados dependendo de cada local).
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    backgroundColor: "#c5e1a5",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                }}
                                                            >
                                                                Irrigações
                                                            </td>
                                                            <td
                                                                style={{
                                                                    backgroundColor: "#dcedc8",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Próximo a 60%
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    backgroundColor: "#a5d6a7",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                }}
                                                            >
                                                                Armazéns
                                                            </td>
                                                            <td
                                                                style={{
                                                                    backgroundColor: "#c8e6c9",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Entre 80% e 90%
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ) : null}
                                        </div>
                                        <div
                                            className="callout callout-purple"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100vw",
                                                marginBottom: 10,
                                                color: "#000",
                                            }}
                                            onClick={() =>
                                                this.setState({
                                                    ...this.state,
                                                    orientacaoHorarioReservado: this.state.orientacaoHorarioReservado
                                                        ? false
                                                        : true,
                                                })
                                            }
                                        >
                                            <p>
                                                <strong style={{ color: "#7281bd" }}>Horário Reservado:</strong> Apenas
                                                em irrigação, representa o custo mais baixo do dia. Tem duração de 8,5h
                                                por dia (geralmente a noite, podento ter horários diferentes de acordo
                                                com contrato com a distribuidora de energia).
                                            </p>
                                            {true || this.state.orientacaoHorarioReservado ? (
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                colSpan="2"
                                                                style={{
                                                                    backgroundColor: "#fff59d",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                    textAlign: "left",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Orientações para Perfil de Consumo Ideal (valores
                                                                aproximados dependendo de cada local).
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    backgroundColor: "#c5e1a5",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                }}
                                                            >
                                                                Irrigações
                                                            </td>
                                                            <td
                                                                style={{
                                                                    backgroundColor: "#dcedc8",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Próximo a 40%
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ) : null}
                                        </div>
                                    </Row>
                                </ContentCardBody>
                            </ContentCard>
                        </Grid>
                        <Grid cols="12 12 12 4" style={{ display: "flex", flexDirection: "column" }}>
                            <ContentCard style={{ backgroundColor: "#30303d", color: "#fff", paddingBlock: 15 }}>
                                <ContentCardBody
                                    padding="0"
                                    style={{
                                        backgroundColor: "#30303d",
                                        minHeight: window.innerWidth < 992 ? 0 : 510,
                                        maxHeight: 897,
                                        overflowY: "scroll",
                                    }}
                                >
                                    <div style={{ width: "100%" }}>
                                        <div
                                            className="text-center"
                                            style={{
                                                position: "sticky",
                                                top: 0,
                                                backgroundColor: "#30303d",
                                                height: 40,
                                                zIndex: 1,
                                                fontSize: 16,
                                            }}
                                        >
                                            Custo Acumulado Anual por UC (R$)
                                        </div>
                                        {this.props.listaGraficoCustoPorUnidadeAcumuladoAno.map((item, i) => (
                                            <div
                                                key={i}
                                                className="progress-group"
                                                style={{ paddingLeft: 8, paddingRight: 8 }}
                                            >
                                                <Row>
                                                    <Grid cols="8">{item.agrupador}</Grid>
                                                    <Grid cols="4">
                                                        <strong>
                                                            <span className="float-right">
                                                                R$ {FormatUtils.formatarValorTela(item.valor, 2)}
                                                            </span>
                                                        </strong>
                                                    </Grid>
                                                </Row>
                                                <div className="progress progress-sm">
                                                    <div
                                                        className="progress-bar"
                                                        style={{
                                                            backgroundColor: this.state.cores[i],
                                                            width: `${
                                                                (parseFloat(item.valor) * 100) / valorUnidadeAnualTotal
                                                            }%`,
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </ContentCardBody>
                            </ContentCard>
                            <ContentCard style={{ backgroundColor: "#30303d", color: "#fff", height: "100%" }}>
                                <ContentCardBody>
                                    <div id="graficoCustoAnual" style={{ width: "100%", height: 383 }}></div>
                                </ContentCardBody>
                            </ContentCard>
                        </Grid>
                    </Row>

                    <ContentCard
                        style={{
                            backgroundColor: "#30303d",
                            color: "#fff",
                            display: !unidadeConsumidora || unidadeConsumidora.id_grupo_faturamento == 1 ? "" : "none",
                        }}
                    >
                        <ContentCardBody
                            style={{
                                backgroundColor: "#30303d",
                                overflowX: "scroll",
                            }}
                        >
                            <ContentCardBody
                                style={{
                                    minWidth: 980,
                                }}
                            >
                                <p className="text-center" style={{ fontSize: 16 }}>
                                    Resumo dos Contratos de Demandas
                                </p>
                                <Table responsive>
                                    <THead>
                                        <Tr>
                                            <Th alignCenter colSpan="3">
                                                Atingidas
                                            </Th>
                                            <Th>Unidade Consumidora</Th>
                                            <Th></Th>
                                            <Th></Th>
                                            <Th alignCenter>Início</Th>
                                            <Th alignCenter>Fim</Th>
                                            <Th alignCenter>Faturas do Ciclo</Th>
                                            <Th alignCenter>Sazonalidade</Th>
                                        </Tr>
                                    </THead>
                                    <TBody>
                                        {!this.props.filtro.id_cliente
                                            ? null
                                            : this.props.listaDemandaContagem.map((item, i) => {
                                                  let restantes = item.competencias - item.lidas;
                                                  let cor = "#fff";
                                                  if (item.atingidas < 3) {
                                                      if (restantes <= 3 && item.atingidas <= 1) {
                                                          cor = "#ef5350";
                                                      } else if (restantes <= 4 && item.atingidas <= 2) {
                                                          cor = "#eeff41";
                                                      }
                                                  }
                                                  //Configura o Tooltip de agendamento de demanda-------------------------------------------------------------------------------------
                                                  let tooltipAgendamentoDemanda = item.listaAgendamentoDemanda
                                                      .length ? (
                                                      <div
                                                          style={{
                                                              backgroundColor: "#f1cf1a",
                                                              color: "#111",
                                                              padding: 4,
                                                              borderRadius: "0.3rem",
                                                              fontSize: "0.8rem",
                                                              display: "flex",
                                                              justifyContent: "center",
                                                              flexWrap: "wrap",
                                                              width: 170,
                                                              margin: 3,
                                                          }}
                                                      >
                                                          <div
                                                              style={{
                                                                  margin: 0,
                                                                  textAlign: "center",
                                                                  minWidth: "100%",
                                                              }}
                                                          >
                                                              <u>
                                                                  <b>Agendamento de Alteração de Demanda:</b>
                                                              </u>
                                                          </div>
                                                          {item.listaAgendamentoDemanda.map((agendamentoDemanda) => (
                                                              <div
                                                                  style={{
                                                                      margin: 0,
                                                                      textAlign: "center",
                                                                      minWidth: "100%",
                                                                  }}
                                                              >{`${agendamentoDemanda.mensagem_tooltip}`}</div>
                                                          ))}
                                                      </div>
                                                  ) : null;
                                                  //----------------------------------------------------------------------------------------------------------------------------------

                                                  //Configura o Tooltip de período de testes -----------------------------------------------------------------------------------------------
                                                  const periodoTeste = item.periodo_teste || [];
                                                  let tooltipPeriodoTeste = null;
                                                  if (periodoTeste.length) {
                                                      const inicioTeste =
                                                          JSON.parse(periodoTeste[0]).mes +
                                                          "/" +
                                                          JSON.parse(periodoTeste[0]).ano;
                                                      const fimTeste =
                                                          JSON.parse(periodoTeste[periodoTeste.length - 1]).mes +
                                                          "/" +
                                                          JSON.parse(periodoTeste[periodoTeste.length - 1]).ano;
                                                      tooltipPeriodoTeste = (
                                                          <div
                                                              style={{
                                                                  backgroundColor: "#257bfb",
                                                                  padding: 4,
                                                                  borderRadius: "0.3rem",
                                                                  fontSize: "0.8rem",
                                                                  display: "flex",
                                                                  justifyContent: "center",
                                                                  alignItems: "center",
                                                                  flexWrap: "wrap",
                                                                  width: 190,
                                                                  minHeight: `${
                                                                      1.3 * (item.listaAgendamentoDemanda.length + 2)
                                                                  }rem`, //+2 pq o tooltip teste tem 2 linhas
                                                                  margin: 3,
                                                              }}
                                                          >
                                                              <div
                                                                  style={{
                                                                      margin: 0,
                                                                      textAlign: "center",
                                                                      minWidth: "100%",
                                                                  }}
                                                              >
                                                                  <u>
                                                                      <b>{`Período de Testes ativo: `}</b>
                                                                  </u>
                                                              </div>
                                                              <div
                                                                  style={{
                                                                      margin: 0,
                                                                      textAlign: "center",
                                                                      minWidth: "100%",
                                                                  }}
                                                              >{`Início ${inicioTeste} - Fim ${fimTeste}`}</div>
                                                          </div>
                                                      );
                                                  }
                                                  //----------------------------------------------------------------------------------------------------------------------------------------
                                                  return (
                                                      <Tr key={item.id_unidade_consumidora}>
                                                          <Td
                                                              color={"#fff"}
                                                              width={40}
                                                              backgroundColor={item.atingidas >= 1 ? "#28a745" : "#fff"}
                                                              alignCenter
                                                          ></Td>
                                                          <Td
                                                              width={40}
                                                              borderLeft="1px solid #81c784"
                                                              borderRight="1px solid #81c784"
                                                              color={item.atingidas >= 2 ? "#fff" : "#000"}
                                                              backgroundColor={item.atingidas >= 2 ? "#28a745" : "#fff"}
                                                              alignCenter
                                                          >
                                                              {item.atingidas}
                                                          </Td>
                                                          <Td
                                                              color={"#fff"}
                                                              width={40}
                                                              backgroundColor={item.atingidas >= 3 ? "#28a745" : "#fff"}
                                                              alignCenter
                                                          ></Td>
                                                          <Td color="#fff">{item.nome_unidade_consumidora}</Td>
                                                          <Td paddingInline="10">{tooltipAgendamentoDemanda}</Td>
                                                          <Td paddingInline="10" color="#fff">
                                                              {tooltipPeriodoTeste}
                                                          </Td>
                                                          <Td color="#fff" alignCenter>
                                                              {String(item.competencia_inicio).substring(4, 6)}/
                                                              {String(item.competencia_inicio).substring(0, 4)}
                                                          </Td>
                                                          <Td color="#fff" alignCenter>
                                                              {String(item.competencia_fim).substring(4, 6)}/
                                                              {String(item.competencia_fim).substring(0, 4)}
                                                          </Td>
                                                          <Td
                                                              color={cor}
                                                              fontWeight="bold"
                                                              title={
                                                                  item.competencias - item.lidas > 3 - item.atingidas ||
                                                                  item.atingidas >= 3
                                                                      ? null
                                                                      : item.competencias - item.lidas ==
                                                                          3 - item.atingidas
                                                                        ? `É necessário atingir ${
                                                                              3 - item.atingidas
                                                                          } demanda${
                                                                              3 - item.atingidas > 1 ? "s" : ""
                                                                          } e resta${
                                                                              item.competencias - item.lidas > 1
                                                                                  ? "m"
                                                                                  : ""
                                                                          } ${item.competencias - item.lidas} fatura${
                                                                              item.competencias - item.lidas > 1
                                                                                  ? "s"
                                                                                  : ""
                                                                          } no ciclo rural`
                                                                        : `É necessário atingir ${
                                                                              3 - item.atingidas
                                                                          } demanda${
                                                                              3 - item.atingidas > 1 ? "s" : ""
                                                                          } e ${
                                                                              item.competencias == item.lidas
                                                                                  ? "o ciclo rural já está completo"
                                                                                  : `resta${
                                                                                        item.competencias - item.lidas >
                                                                                        1
                                                                                            ? "m"
                                                                                            : ""
                                                                                    } ${
                                                                                        item.competencias - item.lidas
                                                                                    } fatura${
                                                                                        item.competencias - item.lidas >
                                                                                        1
                                                                                            ? "s"
                                                                                            : ""
                                                                                    } no ciclo rural`
                                                                          }`
                                                              }
                                                              backgroundColor={
                                                                  item.competencias - item.lidas > 3 - item.atingidas ||
                                                                  item.atingidas >= 3
                                                                      ? null
                                                                      : item.competencias - item.lidas ==
                                                                          3 - item.atingidas
                                                                        ? "#ffb74d"
                                                                        : "#e57373"
                                                              }
                                                              alignCenter
                                                          >
                                                              {item.lidas || 1} de {item.competencias}
                                                          </Td>
                                                          <Td color="#fff" alignCenter>
                                                              {parseFloat(item.sazonalidade)
                                                                  .toFixed(2)
                                                                  .replace(".", ",")}
                                                          </Td>
                                                      </Tr>
                                                  );
                                              })}
                                    </TBody>
                                </Table>
                            </ContentCardBody>
                        </ContentCardBody>
                    </ContentCard>
                </Content>
            </div>
        );
    }

    modalLogados() {
        return (
            <div className="modal fade show" id="modal-xl" style={{ display: "block" }} aria-modal="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Usuários Logados</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.setState({ ...this.state, dialogLogados: false })}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Table responsive>
                                <THead>
                                    <Tr>
                                        <Th>Nome</Th>
                                        <Th>Cliente</Th>
                                    </Tr>
                                </THead>
                                <TBody>
                                    {this.props.listaLogados
                                        .filter((item) => item.tipo == "logado")
                                        .map((item) => (
                                            <Tr key={item.id}>
                                                <Td>{item.nome}</Td>
                                                <Td>{item.nome_tipo}</Td>
                                            </Tr>
                                        ))}
                                </TBody>
                            </Table>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                style={{ visibility: "hidden" }}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.setState({ ...this.state, dialogLogados: false })}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    modalLogadosSemana() {
        return (
            <div className="modal fade show" id="modal-xl" style={{ display: "block" }} aria-modal="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Usuários Logados</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.setState({ ...this.state, dialogLogadosSemana: false })}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Table responsive>
                                <THead>
                                    <Tr>
                                        <Th>Nome</Th>
                                        <Th>Cliente</Th>
                                    </Tr>
                                </THead>
                                <TBody>
                                    {this.props.listaLogados
                                        .filter((item) => item.tipo == "logado" || item.tipo == "semana")
                                        .map((item) => (
                                            <Tr key={item.id}>
                                                <Td>{item.nome}</Td>
                                                <Td>{item.nome_tipo}</Td>
                                            </Tr>
                                        ))}
                                </TBody>
                            </Table>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                style={{ visibility: "hidden" }}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.setState({ ...this.state, dialogLogadosSemana: false })}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    usuarioLogado: state.auth.usuarioLogado,
    filtro: state.dashboard.filtro,
    listaDemandaContagem: state.dashboard.listaDemandaContagem,
    listaGraficoCustoAnual: state.dashboard.listaGraficoCustoAnual,
    listaGraficoCustoUnidadeUltimoMes: state.dashboard.listaGraficoCustoUnidadeUltimoMes,
    listaConsumoPercentualPorHorarioUltimoMes: state.dashboard.listaConsumoPercentualPorHorarioUltimoMes,
    listaGraficoCustoPorUnidadeAcumuladoAno: state.dashboard.listaGraficoCustoPorUnidadeAcumuladoAno,
    listaCliente: state.dashboard.listaCliente,
    listaDemandaContratoTipo: state.dashboard.listaDemandaContratoTipo,
    listaUnidadeConsumidora: state.dashboard.listaUnidadeConsumidora,
    listaMesBase: state.dashboard.listaMesBase,
    totalFaturasImportadas: state.fatura.totalFaturasImportadas,
    totalUsuarios: state.usuario.totalUsuarios,
    logados: state.usuario.logados,
    listaLogados: state.usuario.listaLogados,
    acessos_semana: state.usuario.acessos_semana,
    pdf: state.displayPDF.pdf,
    nomePdf: state.displayPDF.nomePdf,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setFiltro,
            getListaGraficoCustoPorUnidadeUltimoMes,
            getListaGraficoCustoPorUnidadeAcumuladoAno,
            getDemanda,
            getDemandaContagem,
            getListaGraficoCustoAnual,
            getListaConsumoPercentualPorHorarioUltimoMes,
            getListaCliente,
            getTotalFaturasImportadas,
            getTotalUsuarios,
            getLogados,
            getListaUnidadeConsumidora,
            getListaDemandaContratoTipo,
            getListaGraficoGeracaoQuantidadeAnual,
            buscaPdf,
            setArquivoPDF,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
