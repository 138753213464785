import React, { Component } from "react";
import THead from "../common/table/tHead";
import TBody from "../common/table/tBody";

export default class TabelaFaturaEngenhariaPDF extends Component {
	render() {
		const { cabecalhoSemColunasBotoes, linhas, rodape, filtro } = this.props.dadosImpressao;
		const todasPaginas = [];
		const LINHAS_POR_PAGINA = 8;
		linhas.push(rodape);
		const quantidadePaginas = Math.ceil(linhas.length / LINHAS_POR_PAGINA);
		//inicia com o valor padrão, primeira página vai ser 0,depois 14 ...
		let minLinhaProxPagina = 0;
		for (let i = 0; i < quantidadePaginas; i++) {
			const linhasDaPagina = [];
			//inicia com o valor padrão, primeira página vai ser 14,depois 28 ...
			let maxLinhasPaginaAtual = minLinhaProxPagina + LINHAS_POR_PAGINA;

			//começa valendo 0, depois 14, 28 e por ai vai ..;
			for (let j = minLinhaProxPagina; j < maxLinhasPaginaAtual && j < linhas.length; j++) {
				linhasDaPagina.push(linhas[j]);
			}

			//altera o inicio da prox pagina
			minLinhaProxPagina = maxLinhasPaginaAtual;

			todasPaginas.push(
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						height: 1295,
						width: 1754,
						// height: 2480,
						// width: 3508,
						paddingInline: 38,
					}}
				>
					{filtro}
					<table className="table">
						<THead>{cabecalhoSemColunasBotoes}</THead>
						<TBody>{linhasDaPagina.map((linha) => linha)}</TBody>
					</table>
				</div>
			);
		}
		return (
			<>
				{todasPaginas.map((pagina, i) => (
					<div key={i}> {pagina}</div>
				))}
			</>
		);
	}
}
