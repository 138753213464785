import React, { Component } from "react";
import { HashRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../common/template/header";
import SideBar from "../common/template/sideBar";
import Messages from "../common/msg/messages";

import Routes from "./routes";

import { buscarSessao } from "../auth/authActions";

class App extends Component {
  componentWillMount() {
    this.props.buscarSessao();
  }

  render() {
    return (
      <HashRouter>
        <div className="wrapper">
          <Header />
          <SideBar sessao={this.props.usuarioLogado} />
          <Routes sessao={this.props.usuarioLogado} />
          <Messages />
        </div>
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  usuarioLogado: state.auth.usuarioLogado,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buscarSessao,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(App);
