import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import { MapLeaflet } from "../common/mapLeaflet/MapLeaflet";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import Select from "../common/form/select";
import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";

import { setModoTela, initForm } from "./geracaoIntegracaoActions";

class GeracaoIntegracaoForm extends Component {
	render() {
		let readOnly = this.props.excluir || this.props.visualizar ? "readOnly" : "";

		return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<Row>
							<Grid cols="12 12 6 6">
								<ContentCardBody>
									<Row>
										<Select
											cols="12 12 12 12"
											name="id_cliente"
											//se usuario implantacao estiver logado
											//filtra para poder incluir apenas itens na UC
											//que ele é o implantador
											options={
												this.props.usuarioLogado.usuario_implantacao
													? this.props.listaCliente.filter((cliente) =>
															cliente.itens.find((uc) => uc.id_usuario_implantacao == this.props.usuarioLogado.id)
													  )
													: this.props.listaCliente
											}
											label="Cliente"
											placeholder="Selecione o Cliente"
											value={this.props.formularioValues.id_cliente}
											onChange={(value) => {
												this.props.initForm({
													...this.props.formularioValues,
													id_cliente: value,
												});
											}}
											readOnly={readOnly}
										/>
									</Row>
									<Row>
										<Select
											cols="12 12 12 12"
											name="id_unidade_consumidora"
											//se usuario implantacao estiver logado
											//filtra para poder incluir apenas itens na UC
											//que ele é o implantador
											options={this.props.listaUnidadeConsumidora.filter((item) => {
												const implantacaoLogado = this.props.usuarioLogado.usuario_implantacao;
												const ucClienteSelecionado = item.id_cliente == this.props.formularioValues.id_cliente;
												if (implantacaoLogado) {
													const usuarioImplantacaoVinculadoUc = item.id_usuario_implantacao == this.props.usuarioLogado.id;
													return usuarioImplantacaoVinculadoUc && ucClienteSelecionado;
												}
												return ucClienteSelecionado;
											})}
											label="Unidade Consumidora"
											placeholder="Selecione a UC"
											value={this.props.formularioValues.id_unidade_consumidora}
											onChange={(value) => {
												this.props.initForm({
													...this.props.formularioValues,
													id_unidade_consumidora: value,
												});
											}}
											readOnly={readOnly}
										/>
									</Row>
									<Row>
										<Field
											name="tipo"
											cols="12 12 12 12"
											component={Select}
											options={this.props.listaTiposIntegracao}
											label="Tipo"
											placeholder="Informe o tipo da integração"
											readOnly={readOnly}
										/>
									</Row>
									<Row>
										<Field
											cols="12 12 12 12"
											name="usuario"
											component={LabelAndInput}
											label="Usuário Integração"
											placeholder="Informe o nome de usuário da integração"
											readOnly={readOnly}
										/>
									</Row>
									<Row>
										<Field
											cols="12 12 12 12"
											name="senha"
											component={LabelAndInput}
											label="Senha Integração"
											placeholder="Informe a senha do usuário da integração"
											readOnly={readOnly}
										/>
									</Row>
									{this.props.formularioValues.tipo === "FRONIUS" && (
										<Row>
											<Field
												cols="12 12 12 12"
												name="chave_api"
												component={LabelAndInput}
												label="Chave da API (necessária para a integração da Fronius)"
												placeholder='{"AccessKeyId": "xxxxxxxxxxxxxxx","AccessKeyValue": "xxxxxx-xxxx-xxxx-xxxx-xxxxx"}'
												readOnly={readOnly}
											/>
										</Row>
									)}
									{this.props.formularioValues.tipo === "RENOCLIENT (Renovigi)" && (
										<Row>
											<Field
												cols="12 12 12 12"
												name="chave_api"
												component={LabelAndInput}
												label="Chave da Empresa (necessária para a integração da RENOCLIENT - Renovigi)"
												placeholder="xxxx_xxXXxXx0Xxx"
												readOnly={readOnly}
											/>
										</Row>
									)}
									{this.props.formularioValues.tipo === "SOLAREDGE" && (
										<Row>
											<Field
												cols="12 12 12 12"
												name="chave_api"
												component={LabelAndInput}
												label="Chave de API (necessária para a integração da SOLAREDGE)"
												placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
												readOnly={readOnly}
											/>
										</Row>
									)}
									{this.props.formularioValues.tipo === "SOLIS" && (
										<>
											<Row>
												<Field
													cols="12 12 12 12"
													name="id_chave_api"
													component={LabelAndInput}
													label="ID da Chave (KeyId) - necessário para a integração da Solis"
													placeholder="xxxxxxxxxxxxxxxxxxx"
													readOnly={readOnly}
												/>
											</Row>
											<Row>
												<Field
													cols="12 12 12 12"
													name="segredo_chave_api"
													component={LabelAndInput}
													label="Chave Secreta (KeySecret) - necessária para a integração da Solis"
													placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
													readOnly={readOnly}
												/>
											</Row>
											<Row>
												<Field
													cols="12 12 12 12"
													name="url_api"
													component={LabelAndInput}
													label="API URL - necessária para a integração da Solis"
													placeholder="https://www.xxxxxxxxxx.xxx:xxxxx/"
													readOnly={readOnly}
												/>
											</Row>
										</>
									)}
									{this.props.formularioValues.tipo === "FUSION SOLAR (Huawei)" && (
										<Row>
											<Field
												cols="12 12 12 12"
												name="codigo_empresa"
												component={LabelAndInput}
												label="Código da Empresa - obtido no menu Mais > Sistema > Gerenciamento da Empresa > Informações da empresa da plataforma da Fusion Solar"
												placeholder="NE=xxxxxxxx"
												readOnly={readOnly}
											/>
										</Row>
									)}
									{[
										"SOLARVIEW",
										"SOLIS",
										"FRONIUS",
										"SOLARMAN",
										"WEG",
										"RENOCLIENT (Renovigi)",
										"FUSION SOLAR (Huawei)",
										"ISOLARCLOUD (Sungrow)",
										"SOLAREDGE",
									].includes(this.props.formularioValues.tipo) && (
										<Row>
											<Field
												name="id_sistema_fotovoltaico"
												component={LabelAndInput}
												label={
													this.props.formularioValues.tipo === "SOLIS"
														? "Link da Instalação - obtido visualizando a instalação no site da Solis"
														: this.props.formularioValues.tipo === "FRONIUS"
														? "Link da Instalação - obtido visualizando a instalação no site da Fronius"
														: this.props.formularioValues.tipo === "SOLARMAN"
														? 'Nome da Instalação - obtido no menu "Detalhes da Instalação" na plataforma Solarman'
														: this.props.formularioValues.tipo === "WEG"
														? "Nome da Usina - obtido acessando a plataforma da Weg e clicando na usina"
														: this.props.formularioValues.tipo === "ISOLARCLOUD (Sungrow)"
														? "Nome da Usina - obtido acessando a plataforma da iSolarCloud"
														: this.props.formularioValues.tipo === "SOLARVIEW"
														? "Link da Instalação - obtido acessando a plataforma da SolarView e clicando na unidade"
														: this.props.formularioValues.tipo === "RENOCLIENT (Renovigi)"
														? "Nome da Planta - obtido acessando a plataforma da Renovigi e visualizando a lista de plantas"
														: this.props.formularioValues.tipo === "FUSION SOLAR (Huawei)"
														? "Nome da Instalação - obtido acessando o menu Instalações da plataforma da Fusion Solar"
														: this.props.formularioValues.tipo === "SOLAREDGE"
														? "ID do site - obtido acessando o menu Admin da instalação na plataforma da SOLAREDGE"
														: ""
												}
												placeholder={
													this.props.formularioValues.tipo === "SOLIS"
														? "https://www.soliscloud.com/#/station/stationDetails/generalSituation/xxxxxxxxxxxxxxxxxxx"
														: this.props.formularioValues.tipo === "SOLARVIEW"
														? "https://my.solarview.com.br/monitoramento?und=xxxxx"
														: this.props.formularioValues.tipo === "FRONIUS"
														? "https://www.solarweb.com/PvSystems/PvSystem?pvSystemId=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
														: ["SOLARMAN", "FUSION SOLAR (Huawei)"].includes(this.props.formularioValues.tipo)
														? "Nome da instalação"
														: this.props.formularioValues.tipo === "WEG" || this.props.formularioValues.tipo === "ISOLARCLOUD (Sungrow)"
														? "Nome da Usina"
														: this.props.formularioValues.tipo === "RENOCLIENT (Renovigi)"
														? "Nome da Planta"
														: this.props.formularioValues.tipo === "SOLAREDGE"
														? "xxxxxxx"
														: ""
												}
												cols="12 12 12 12"
												readOnly={readOnly}
											/>
										</Row>
									)}
								</ContentCardBody>
							</Grid>
							<Grid cols="12 12 6 6">
								<div style={{ padding: "13px 5px 5px 0px" }}>
									<b>Coordenadas</b>
								</div>
								<div style={{ width: "98%", height: 450 }}>
									<MapLeaflet
										getCurrentPosition={!this.props.formularioValues.id}
										latitude={this.props.formularioValues.latitude}
										longitude={this.props.formularioValues.longitude}
										onChange={(coordenadas) => {
											this.props.initForm({
												...this.props.formularioValues,
												latitude: String(coordenadas.latitude).substring(0, 15),
												longitude: String(coordenadas.longitude).substring(0, 15),
											});
										}}
									/>
								</div>
							</Grid>
						</Row>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols="6 6 4 3">
									{this.props.excluir ? (
										<Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} disabled={this.props.visualizar} />
									) : (
										<Button text={"Salvar"} submit type={"success"} icon={"fa fa-check"} disabled={this.props.visualizar} />
									)}
								</Grid>
								<Grid cols="6 6 4 3">
									<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
			</>
		);
	}
}

GeracaoIntegracaoForm = reduxForm({
	form: "geracaoIntegracaoForm",
	destroyOnUnmount: false,
})(GeracaoIntegracaoForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues("geracaoIntegracaoForm")(state),
	registro: state.geracaoIntegracao.registro,
	filtro: state.geracaoIntegracao.filtro,
	lista: state.geracaoIntegracao.lista,
	listaTiposIntegracao: state.geracaoIntegracao.listaTiposIntegracao,
	listaCliente: state.geracaoIntegracao.listaCliente,
	listaUnidadeConsumidora: filtrarUcPorDataDesligamento(state.geracaoIntegracao.listaUnidadeConsumidora),
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(GeracaoIntegracaoForm);
