import axios from "axios";
import consts from "../consts";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";
import { initialize } from "redux-form";
import DateFormat from "../common/dateFormat/DateFormat";
import confirmDialog from "../common/dialog/confirmDialog";
import { retornarApenasNumeros } from "../utils/retornarApenasNumeros";

export function setModoTela(modo, registro = {}) {
    return {
        type: "CLIENTE_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}

export function setModoTelaUC(modoUC, registroUC = {}) {
    return {
        type: "UC_MODO_TELA",
        payload: {
            modoUC: modoUC,
            registroUC: registroUC,
        },
    };
}

export function initForm(registro = {}) {
    return [initialize("clienteForm", registro)];
}

export function initUcForm(registro = {}) {
    return [initialize("unidadeConsumidoraForm", registro)];
}

export function setAguardando(aguardando) {
    return {
        type: "CLIENTE_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function getLista() {
    return async (dispatch, getState) => {
        dispatch(setAguardando(true));
        try {
            const request = await axios.get(`${consts.API_URL}/cliente`);
            const listaClientes = request.data || [];
            const usuarioLogado = getState().auth.usuarioLogado;
            const listaClientesFiltrada = filtrarClientesUsuarioImplantacao(usuarioLogado, listaClientes); // se usuario logado não for implantação retorna a lista original
            dispatch({
                type: "CLIENTE_LISTADO",
                payload: listaClientesFiltrada,
            });
        } catch (erro) {
            setError("Não foi possível carregar a lista de clientes");
        } finally {
            dispatch(setAguardando(false));
        }
    };
}
function filtrarClientesUsuarioImplantacao(usuarioLogado, listaClientes) {
    if (usuarioLogado && !usuarioLogado.usuario_implantacao) return listaClientes;

    const clientesMapeados = listaClientes.map((cliente) => {
        //adiciona parametro bloquearEdicaoClienteUsuarioImplantacao ao cliente da lista para sumir os botoes editar, anexar e excluir
        //só pode editar um cliente se o id do usuário estiver vinculado ao id_usuario_implantacao de todas as UCs do cliente
        const bloquearEdicaoClienteUsuarioImplantacao = !!cliente.itens.find(
            (uc) => uc.id_usuario_implantacao !== usuarioLogado.id,
        );

        //cria uma propriedade que diz se a UC está vinculada ou não ao usuario implantacao
        //para que ele possa apenas ver as UCs existentes, mas possa editar a que ele cadastrou
        const unidadesConsumidoras = cliente.itens.map((uc) => {
            //se o usuario implantacao não estiver vinculado a UC ele nao poderá editá-la
            const bloquearEdicaoUcUsuarioImplantacao = uc.id_usuario_implantacao != usuarioLogado.id;
            return { ...uc, bloquearEdicaoUcUsuarioImplantacao };
        });
        return {
            ...cliente,
            bloquearEdicaoClienteUsuarioImplantacao,
            itens: unidadesConsumidoras,
        };
    });
    return clientesMapeados;
}

export function salvarUC(registro) {
    return async (dispatch, getState) => {
        try {
            if (
                !window.confirm(
                    `Deseja realmente ${registro.id ? "salvar as alterações na" : "incluir a"} unidade consumidora?`,
                )
            )
                return {};
            dispatch(setAguardando(true));

            const registroFormatado = { ...registro };

            registroFormatado.percentual_geracao = registroFormatado.percentual_geracao || 0;
            registroFormatado.data_desligamento = registroFormatado.data_desligamento
                ? DateFormat.formatarDataTelaParaSql(registroFormatado.data_desligamento)
                : null;

            //VALIDA UF E ATIVIDADE DA UC PARA SETAR A TRIBUTAÇÂO----------------------------------------------------------------------------

            //para novas UCs exige id_estado e id_atividade
            if (!registroFormatado.id && (!registroFormatado.id_estado || !registroFormatado.id_atividade)) {
                setError("Informe a UF e a atividade da UC");
                return {};
            }

            //Deve também encontrar uma tributacao pra UF e atividade selecionada
            const listaTributacao = getState().tributacao.lista;
            const ucTributacaoNaoLocalizada =
                (registroFormatado.id_estado || registroFormatado.id_atividade) &&
                !listaTributacao.find(
                    (tributacao) =>
                        tributacao.id_estado == registroFormatado.id_estado &&
                        tributacao.id_atividade == registroFormatado.id_atividade,
                );

            if (ucTributacaoNaoLocalizada) {
                setError(
                    `Não foi localizada a tributação referente a UF e a atividade da UC "${ucTributacaoNaoLocalizada.nome}"`,
                );
                return {};
            }

            //remove id_estado e id_atividade, para incluir id_tributacao, que é o atributo vinculado a UC
            const tributacao =
                listaTributacao.find(
                    (tributacao) =>
                        tributacao.id_estado == registroFormatado.id_estado &&
                        tributacao.id_atividade == registroFormatado.id_atividade,
                ) || {};
            delete registroFormatado.id_estado;
            delete registroFormatado.id_atividade;

            registroFormatado.id_tributacao = tributacao.id || null;

            //----------------------------------------------------------------------------------------------------------------------------------
            if (!registroFormatado.id) {
                // console.log("Incluindo UC:", registroFormatado);
                await axios.post(`${consts.API_URL}/unidadeConsumidora`, registroFormatado);
                await dispatch(getListaUCsCliente(registroFormatado.id_cliente));
                setSuccess("Unidade Consumidora incluída com sucesso.");
            } else {
                const ucOriginal = getState().cliente.registroUC;

                //se o usuario ou senha da UC editada mudou, ou se é uma UC nova, define a mensagem da ultima importação para nulo
                if (
                    registroFormatado.usuario !== ucOriginal.usuario ||
                    registroFormatado.senha !== ucOriginal.senha ||
                    registroFormatado.cpf_cnpj_unidade_consumidora !== ucOriginal.cpf_cnpj
                ) {
                    registroFormatado.mensagem_ultima_importacao_fatura = null;
                }

                // console.log("Alterando UC:", registroFormatado);
                await axios.put(`${consts.API_URL}/unidadeConsumidora`, registroFormatado);
                await dispatch(getListaUCsCliente(registroFormatado.id_cliente));
                setSuccess("Unidade Consumidora alterada com sucesso.");
            }
        } catch (error) {
            setErroAPI(error);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}
export function reativarUC(registro) {
    return async (dispatch, getState) => {
        try {
            if (!window.confirm("Deseja realmente reativar a unidade consumidora?")) return {};
            // console.log("Reativando UC:", registro);
            dispatch(setAguardando(true));
            await axios.put(`${consts.API_URL}/unidadeConsumidora/reativar`, { id: registro.id || 0 });
            await dispatch(getListaUCsCliente(registro.id_cliente));
            setSuccess(`Unidade Consumidora reativada com sucesso.`);
        } catch (error) {
            setErroAPI(error);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}
export function excluirUC(registro) {
    return async (dispatch, getState) => {
        try {
            const msgConfirmacao = registro.exclusao_definitiva
                ? "Esta alteração não poderá ser revertida, deseja realmente excluir a unidade consumidora PERMANENTEMENTE do sistema?"
                : "Deseja realmente inativar a unidade consumidora?";
            if (!window.confirm(msgConfirmacao)) return {};
            // console.log("Excluindo UC:", registro);
            dispatch(setAguardando(true));
            await axios.delete(
                `${consts.API_URL}/unidadeConsumidora?id=${registro.id || 0}&exclusao_definitiva=${
                    registro.exclusao_definitiva
                }`,
            );
            await dispatch(getListaUCsCliente(registro.id_cliente));
            setSuccess(`Unidade Consumidora ${registro.exclusao_definitiva ? "excluida" : "inativada"} com sucesso.`);
        } catch (error) {
            setErroAPI(error);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

async function getListaUCsCliente(id_cliente) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const request = await axios.get(`${consts.API_URL}/unidadeConsumidora?id_cliente=${id_cliente || 0}`);
            const itens = request.data || [];

            //adiciona propriedade de bloqueio para usuário implantação
            const usuarioLogado = getState().auth.usuarioLogado;
            const cliente = getState().cliente.registro;

            //atualiza o cliente com as UCs atualizadas
            const clienteComUCsAtualizadas = { ...cliente, itens };
            const listaClientes = [clienteComUCsAtualizadas];

            //tem só o cliente carregado no formulário, usa uma lista pra reaproveitar a função filtrarClientesUsuarioImplantacao
            const listaClientesFiltrada = filtrarClientesUsuarioImplantacao(usuarioLogado, listaClientes); // se usuario logado não for implantação retorna a lista original
            dispatch(setModoTelaUC(null)); //fecha o formulário de UC
            const modoTela = getState().cliente.modoTela;
            dispatch(setModoTela(modoTela, listaClientesFiltrada[0] || {}));
            dispatch(initForm(listaClientesFiltrada[0] || {}));
            // console.log("Lista de UC:", listaClientesFiltrada[0]);
        } catch (error) {
            setErroAPI(error);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function salvar(registro) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const registroSemPontuacao = removerPontuacaoCamposCliente(registro);

            if (!registroSemPontuacao.id) {
                if (!window.confirm("Deseja realmente incluir o cliente?")) return {};
                const retorno = await axios.post(`${consts.API_URL}/cliente`, registroSemPontuacao);
                if (window.confirm("Deseja incluir Unidades Consumidoras para o cliente?")) {
                    const modoTela = getState().cliente.modoTela;
                    dispatch(setModoTela(modoTela, retorno.data));
                    dispatch(initForm(retorno.data || {}));
                    return {};
                }
            } else {
                if (!window.confirm("Deseja realmente salvar as alterações?")) return {};
                await axios.put(`${consts.API_URL}/cliente`, registroSemPontuacao);
            }
            setSuccess(`Cliente ${registroSemPontuacao.id ? "alterado" : "incluído"} com sucesso.`);

            await dispatch(getLista());
            dispatch(setModoTela("lista"));
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function gerarAcesso(registro) {
    return (dispatch, getState) => {
        axios
            .post(`${consts.API_URL}/usuario`, {
                nome: registro.nome,
                login: registro.cpf ? retornarApenasNumeros(registro.cpf) : retornarApenasNumeros(registro.cnpj),
                administrador: false,
                acesso_cliente: true,
                id_cliente: registro.id,
                senha: Math.random().toString(36).substring(7),
            })
            .then((resp) => {
                setSuccess("Operação Realizada com sucesso.");
                dispatch(
                    initForm({
                        ...registro,
                        id_usuario: resp.data.id,
                        login: resp.data.login,
                        senha_cliente: resp.data.senha_cliente,
                    }),
                );
                dispatch(
                    setModoTela("cadastro", {
                        ...registro,
                        id_usuario: resp.data.id,
                        login: resp.data.login,
                        senha_cliente: resp.data.senha_cliente,
                    }),
                );
                dispatch(getLista());
            })
            .catch((e) => {
                setErroAPI(e);
            });
    };
}

export function excluir(registro) {
    return (dispatch) => {
        confirmDialog("Deseja realmente excluir?", () => {
            axios
                .delete(`${consts.API_URL}/cliente?id=${registro.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
    return {
        type: "CLIENTE_CIDADE_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaConcessionaria() {
    const request = axios.get(`${consts.API_URL}/concessionaria/listarSelect`);
    return {
        type: "CLIENTE_CONCESSIONARIA_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaGrupoFaturamento() {
    const request = axios.get(`${consts.API_URL}/grupoFaturamento/listarSelect`);
    return {
        type: "CLIENTE_GRUPO_FATURAMENTO_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaModalidadeTarifaria() {
    const request = axios.get(`${consts.API_URL}/modalidadeTarifaria/listarSelect`);
    return {
        type: "CLIENTE_MODALIDADE_TARIFARIA_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaTipoMedicao() {
    const request = axios.get(`${consts.API_URL}/tipoMedicao/listarSelect`);
    return {
        type: "CLIENTE_TIPO_MEDICAO_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaTipoMercado() {
    const request = axios.get(`${consts.API_URL}/tipoMercado/listarSelect`);
    return {
        type: "CLIENTE_TIPO_MERCADO_SELECT_LISTADO",
        payload: request,
    };
}
export function initFormAnexo(registro = {}) {
    return [initialize("clienteAnexoForm", registro)];
}

export function getListaAnexo(id_cliente) {
    const request = axios.get(`${consts.API_URL}/clienteAnexo?id_cliente=${id_cliente}`);
    return {
        type: "CLIENTE_ANEXO_LISTADO",
        payload: request,
    };
}

export function salvarAnexo(registro) {
    return (dispatch, getState) => {
        dispatch(setAguardando(true));

        if (!registro.id) {
            axios
                .post(`${consts.API_URL}/clienteAnexo`, {
                    ...registro,
                })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getListaAnexo(registro.id_cliente));
                    dispatch(setModoTela("listaAnexo", getState().cliente.registro));
                    dispatch(setAguardando(false));
                })
                .catch((e) => {
                    setErroAPI(e);
                    dispatch(setAguardando(false));
                });
        } else {
            axios
                .put(`${consts.API_URL}/clienteAnexo`, {
                    ...registro,
                })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getListaAnexo(registro.id_cliente));
                    dispatch(setModoTela("listaAnexo", getState().cliente.registro));
                    dispatch(setAguardando(false));
                })
                .catch((e) => {
                    setErroAPI(e);
                    dispatch(setAguardando(false));
                });
        }
    };
}

export function excluirAnexo(registro) {
    return (dispatch, getState) => {
        axios
            .delete(`${consts.API_URL}/clienteAnexo?id=${registro.id}`)
            .then((resp) => {
                setSuccess("Operação Realizada com sucesso.");
                dispatch(getListaAnexo(registro.id_cliente));
                dispatch(setModoTela("listaAnexo", getState().cliente.registro));
            })
            .catch((e) => {
                setErroAPI(e);
            });
    };
}

function removerPontuacaoCamposCliente(registro) {
    const registroSemPontuacao = JSON.parse(JSON.stringify(registro));
    registroSemPontuacao.cpf = retornarApenasNumeros(registro.cpf);
    registroSemPontuacao.cnpj = retornarApenasNumeros(registro.cnpj);
    registroSemPontuacao.faturamento_cpf = retornarApenasNumeros(registro.faturamento_cpf);
    registroSemPontuacao.faturamento_cnpj = retornarApenasNumeros(registro.faturamento_cnpj);
    registroSemPontuacao.faturamento_telefone = retornarApenasNumeros(registro.faturamento_telefone);
    registroSemPontuacao.faturamento_celular = retornarApenasNumeros(registro.faturamento_celular);
    registroSemPontuacao.faturamento_cep = retornarApenasNumeros(registro.faturamento_cep);
    registroSemPontuacao.telefone_residencial = retornarApenasNumeros(registro.telefone_residencial);
    registroSemPontuacao.telefone_celular = retornarApenasNumeros(registro.telefone_celular);
    return registroSemPontuacao;
}

//usada para validar o acesso do usuario implantação
export async function getUcValidarUsuarioImplantacao() {
    return async (dispatch, getState) => {
        const request = await axios.get(`${consts.API_URL}/unidadeConsumidora/listar`);
        dispatch({
            type: "UNIDADE_CONSUMIDORA_LISTADO",
            payload: request,
        });
    };
}
