import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputDateCalendar from "../common/form/labelAndInputDateCalendar";
import LabelAndTextarea from "../common/form/labelAndTextarea";
import Select from "../common/form/select";
import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";
import DateFormat from "../common/dateFormat/DateFormat";
import { salvarAndamento, setModoTela, initForm } from "./gestaoProcessoActions";
import ButtonTable from "../common/table/buttonTable";
import { ModalForm } from "../common/dialog/modalForm";

class GestaoProcessoForm extends Component {
	state = { exibirModalAndamento: false, gestao_processo: null, andamento: null };

	componentDidMount() {
		if (!this.props.formularioValues.id) {
			this.props.initForm({ id_usuario_criacao: this.props.usuarioLogado.id, status: "false", andamento: [] });
		} else {
			this.props.initForm({
				...this.props.formularioValues,
				id_usuario_alteracao: this.props.usuarioLogado.id,
				status: String(this.props.formularioValues.status),
				data_inicio: this.props.formularioValues.data_inicio ? String(this.props.formularioValues.data_inicio).substring(0, 10) : null,
				prazo_conclusao: this.props.formularioValues.prazo_conclusao ? String(this.props.formularioValues.prazo_conclusao).substring(0, 10) : null,
			});
		}
	}

	fecharModalAndamento(referencia) {
		referencia.setState({ exibirModalAndamento: false, gestao_processo: null, andamento: null });
	}

	render() {
		//se ainda não inicializou o form com o array de andamento vazio retorna
		if (!this.props.formularioValues.andamento) return null;
		const processoConcluido = !!this.props.registro.status; //quando ta cadastrando é undefined, senão pega do registro carregado
		const andamentoFinal = this.props.formularioValues.andamento.find((andamento) => andamento.solucao_final);
		let readOnly = this.props.excluir || this.props.visualizar || andamentoFinal || processoConcluido ? "readOnly" : "";
		return (
			<>
				{this.state.exibirModalAndamento && this.state.andamento ? (
					<ModalForm
						titulo="Alterar registro de andamento"
						item={{ ...this.state.andamento }}
						handleSubmit={(andamento) => {
							andamento.gestao_processo = JSON.parse(JSON.stringify(this.state.gestao_processo));
							this.props.salvarAndamento(andamento, () => this.fecharModalAndamento(this));
						}}
						handleClose={() => this.fecharModalAndamento(this)}
					>
						<LabelAndTextarea name="descricao" label="Descrição" cols="12 12 12 12" placeholder="Informe a descrição do andamento" />
						<Select
							name="solucao_final"
							options={this.props.listaSolucao}
							label="Tipo de ação"
							cols="12 12 12 12"
							placeholder="Selecione o tipo de ação"
							isClearable={false}
							readOnly={processoConcluido}
						/>
					</ModalForm>
				) : null}
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<Row>
							<Grid cols="12 12 6 6">
								<ContentCardBody>
									<Row>
										<Select
											cols="12 12 12 12"
											name="id_cliente"
											//se usuario implantacao estiver logado
											//filtra para poder incluir apenas itens na UC
											//que ele é o implantador
											options={
												this.props.usuarioLogado.usuario_implantacao
													? this.props.listaCliente.filter((cliente) =>
															cliente.itens.find((uc) => uc.id_usuario_implantacao == this.props.usuarioLogado.id)
													  )
													: this.props.listaCliente
											}
											label="Cliente"
											placeholder="Selecione o Cliente"
											value={this.props.formularioValues.id_cliente}
											onChange={(value) => {
												this.props.initForm({
													...this.props.formularioValues,
													id_cliente: value,
												});
											}}
											readOnly={readOnly || this.props.formularioValues.id} //nunca pode editar depois de cadastrado
										/>
									</Row>
									<Row>
										<Select
											cols="12 12 12 12"
											name="id_unidade_consumidora"
											//se usuario implantacao estiver logado
											//filtra para poder incluir apenas itens na UC
											//que ele é o implantador
											options={this.props.listaUnidadeConsumidora.filter((item) => {
												const implantacaoLogado = this.props.usuarioLogado.usuario_implantacao;
												const ucClienteSelecionado = item.id_cliente == this.props.formularioValues.id_cliente;
												if (implantacaoLogado) {
													const usuarioImplantacaoVinculadoUc = item.id_usuario_implantacao == this.props.usuarioLogado.id;
													return usuarioImplantacaoVinculadoUc && ucClienteSelecionado;
												}
												return ucClienteSelecionado;
											})}
											label="Unidade Consumidora"
											placeholder="Selecione a UC"
											value={this.props.formularioValues.id_unidade_consumidora}
											onChange={(value) => {
												this.props.initForm({
													...this.props.formularioValues,
													id_unidade_consumidora: value,
												});
											}}
											readOnly={readOnly}
										/>
									</Row>
									<Row>
										<Field
											name="id_concessionaria"
											cols="12 12 12 12"
											component={Select}
											options={this.props.listaConcessionaria}
											label="Concessionária"
											placeholder="Informe a concessionária"
											readOnly={readOnly}
										/>
									</Row>
									<Row>
										<Field
											cols="12 12 12 12"
											name="numero_protocolo"
											component={LabelAndInput}
											label="Nº do Protocolo"
											placeholder="Informe o Nº do Protocolo"
											readOnly={readOnly}
											maxLength={20}
										/>
									</Row>
									<Row>
										<Field
											cols="12 12 12 12"
											name="data_inicio"
											component={LabelAndInputDateCalendar}
											label="Data de Início"
											placeholder="Informe a data de início do protocolo"
											readOnly={readOnly || this.props.formularioValues.id} //nunca pode editar depois de cadastrado
										/>
									</Row>
									<Row>
										<Field
											cols="12 12 12 12"
											name="prazo_conclusao"
											component={LabelAndInputDateCalendar}
											label="Prazo para Conclusão"
											placeholder="Informe o prazo para a conclusão do protocolo"
											readOnly={readOnly}
											liberarTodasDatas={true}
										/>
									</Row>
									<Row>
										<Field
											name="id_usuario_responsavel"
											cols="12 12 12 12"
											component={Select}
											options={this.props.listaUsuarioAdministradores}
											label="Usuário(a) Responsável"
											placeholder="Informe o usuário(a) responsável"
											readOnly={readOnly}
										/>
									</Row>
									<Row>
										<Field
											cols="12 12 12 12"
											name="descricao"
											component={LabelAndTextarea}
											label="Descrição da Tarefa"
											placeholder="Descrição do protocolo"
											readOnly={readOnly || this.props.formularioValues.id} //nunca pode editar depois de cadastrado
										/>
									</Row>
									<Row>
										<Field
											name="status"
											cols="12 12 12 12"
											component={Select}
											options={this.props.listaStatus}
											label="Status"
											placeholder="Selecione o status do protocolo"
											readOnly={readOnly}
											isClearable={false}
										/>
									</Row>
								</ContentCardBody>
							</Grid>
							<Grid cols="12 12 6 6" style={{ marginTop: 14, maxHeight: "83vh" }}>
								<h6 style={{ marginInline: 20 }}>Atualizações Parciais</h6>
								<div
									style={{
										backgroundColor: "#eee",
										marginInline: 20,
										maxHeight: 575,
										minHeight: window.innerWidth < 768 ? null : 575,
										overflowY: "scroll",
									}}
								>
									{this.props.formularioValues.andamento
										.filter((andamento) => !andamento.solucao_final)
										.map((andamento) => {
											const condicaoExibirBotao =
												!this.props.excluir &&
												this.props.usuarioLogado.acesso_gestao_processo_andamento_editar &&
												this.props.usuarioLogado.id === andamento.id_usuario_inclusao && //só o usuario de inclusão pode editar
												!this.props.formularioValues.andamento.find((item) => item.id > andamento.id); //só pode editar o último incluido
											return (
												<div key={andamento.id}>
													<Grid cols="12 12 12 12" style={{ display: "flex", padding: 10, borderBottom: "1px solid #ccc" }}>
														<Grid cols="11 11 11 11">
															<div>
																<b>Atualização incluída por: </b>
																{andamento.nome_usuario_inclusao}
															</div>
															<div>
																<b>Data/hora de inclusão:</b> {DateFormat.formatarDataHoraSqlParaTela(andamento.datahora_inclusao)}
															</div>
															{andamento.datahora_inclusao !== andamento.datahora_alteracao ? (
																<div>
																	<b>Data/hora de alteração:</b> {DateFormat.formatarDataHoraSqlParaTela(andamento.datahora_alteracao)}
																</div>
															) : null}
															<div>
																<b>Descrição: </b>
																{andamento.descricao}
															</div>
														</Grid>
														{condicaoExibirBotao ? (
															<Grid cols="1 1 1 1">
																<ButtonTable
																	type="warning"
																	icon="fas fa-pencil-alt"
																	visible={true}
																	event={() => {
																		//remove a lista de andamento
																		const gestao_processo = JSON.parse(JSON.stringify(this.props.formularioValues));
																		delete gestao_processo.andamento;
																		const andamentoCopiado = JSON.parse(JSON.stringify(andamento));
																		andamentoCopiado.solucao_final = String(andamentoCopiado.solucao_final);
																		//salva no state o processo atual e o registro de andamento selecionado
																		this.setState({ exibirModalAndamento: true, gestao_processo, andamento: andamentoCopiado });
																	}}
																/>
															</Grid>
														) : null}
													</Grid>
												</div>
											);
										})}
								</div>
								<h6 style={{ marginInline: 20, marginTop: 28 }}>Solução Final</h6>
								<div style={{ backgroundColor: "#eee", marginTop: 16, marginInline: 20, height: "135px", overflowY: "scroll" }}>
									{andamentoFinal ? (
										<>
											<Grid cols="12 12 12 12" key={andamentoFinal.id} style={{ display: "flex", padding: 10 }}>
												<Grid cols="11 11 11 11">
													<div>
														<b>Atualização incluída por:</b> {andamentoFinal.nome_usuario_inclusao}
													</div>
													<div>
														<b>Data/hora de inclusão:</b> {DateFormat.formatarDataHoraSqlParaTela(andamentoFinal.datahora_inclusao)}
													</div>
													{andamentoFinal.datahora_inclusao !== andamentoFinal.datahora_alteracao ? (
														<div>
															<b>Data/hora de alteração:</b> {DateFormat.formatarDataHoraSqlParaTela(andamentoFinal.datahora_alteracao)}
														</div>
													) : null}
													<div>
														<b>Descrição: </b>
														{andamentoFinal.descricao}
													</div>
												</Grid>
												<Grid cols="1 1 1 1">
													<ButtonTable
														type="warning"
														icon="fas fa-pencil-alt"
														disabled={
															this.props.excluir ||
															!this.props.usuarioLogado.acesso_gestao_processo_andamento_editar ||
															this.props.usuarioLogado.id !== andamentoFinal.id_usuario_inclusao //só o usuario de inclusão pode editar
														}
														visible={true}
														event={() => {
															//remove a lista de andamento
															const gestao_processo = JSON.parse(JSON.stringify(this.props.formularioValues));
															delete gestao_processo.andamento;
															const andamentoCopiado = JSON.parse(JSON.stringify(andamentoFinal));
															andamentoCopiado.solucao_final = String(andamentoCopiado.solucao_final);
															//salva no state o processo atual e o registro de andamento selecionado
															this.setState({ exibirModalAndamento: true, gestao_processo, andamento: andamentoCopiado });
														}}
													/>
												</Grid>
											</Grid>
										</>
									) : null}
								</div>
							</Grid>
						</Row>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols="6 6 4 3">
									{this.props.excluir ? (
										<Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} disabled={this.props.visualizar} />
									) : (
										<Button text={"Salvar"} submit type={"success"} icon={"fa fa-check"} disabled={this.props.visualizar} />
									)}
								</Grid>
								<Grid cols="6 6 4 3">
									<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
			</>
		);
	}
}
function usuariosAdminAutorizadosVisualizar(lista) {
	return lista.filter(
		(usuario) =>
			!usuario.acesso_cliente &&
			(usuario.acesso_gestao_processo_visualizar ||
				usuario.acesso_gestao_processo_adicionar ||
				usuario.acesso_gestao_processo_editar ||
				usuario.acesso_gestao_processo_excluir)
	);
}

GestaoProcessoForm = reduxForm({
	form: "gestaoProcessoForm",
	destroyOnUnmount: false,
})(GestaoProcessoForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues("gestaoProcessoForm")(state),
	registro: state.gestaoProcesso.registro,
	filtro: state.gestaoProcesso.filtro,
	lista: state.gestaoProcesso.lista,
	listaUsuarioAdministradores: usuariosAdminAutorizadosVisualizar(state.usuario.listaUsuario),
	listaCliente: state.gestaoProcesso.listaCliente,
	listaUnidadeConsumidora: filtrarUcPorDataDesligamento(state.gestaoProcesso.listaUnidadeConsumidora),
	listaConcessionaria: state.gestaoProcesso.listaConcessionaria,
	listaStatus: state.gestaoProcesso.listaStatus,
	listaSolucao: state.gestaoProcesso.listaSolucao,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			salvarAndamento,
			setModoTela,
			initForm,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(GestaoProcessoForm);
