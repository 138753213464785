import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Form from "./documentoForm";
import Select from "../common/form/select";
import { Loading } from "../common/loading/loading";
import { setModoTela, initForm, setFiltro, salvar, excluir, getLista, getListaCliente, getListaUnidadeConsumidora, getListaDocumentoTipo } from "./documentoActions";

class DocumentoCliente extends Component {
    componentWillMount() {
        this.props.getLista();
        this.props.getListaCliente();
        this.props.getListaUnidadeConsumidora();
        this.props.getListaDocumentoTipo();

        let idInterval = setInterval(() => {
            if (this.props.usuarioLogado && this.props.usuarioLogado.administrador) {
                this.props.getLista();
                clearInterval(idInterval);
            } else if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
                this.props.setFiltro({
                    ...this.props.filtro,
                    id_cliente: this.props.usuarioLogado.id_cliente,
                });
                this.props.getLista();
                clearInterval(idInterval);
            }
        }, 2000);
    }

    render() {
        //Sem acesso
        if (!this.props.usuarioLogado) {
            return <div />;
        }

        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}

                <Content>
                    {this.props.modoTela == "visualizacao" ? <Form onSubmit={() => {}} visualizacao /> : null}
                    {this.props.modoTela == "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
                    {this.props.modoTela == "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
                    {this.props.modoTela == "lista" ? this.lista() : null}
                </Content>
            </div>
        );
    }

    lista() {
        let acessoCliente = this.props.usuarioLogado.acesso_cliente || false;

        return (
            <ContentCard>
                <ContentCardBody>
                    <Row>
                        {!acessoCliente ? (
                            <Select
                                name="id_cliente"
                                options={this.props.listaCliente}
                                label="Cliente"
                                cols="12 6 4"
                                placeholder="Selecione o Cliente"
                                value={this.props.filtro.id_cliente}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        id_cliente: value,
                                    });
                                    this.props.getLista();
                                }}
                            />
                        ) : null}

                        <Select
                            name="id_unidade_consumidora"
                            options={this.props.listaUnidadeConsumidora.filter((item) =>
                                acessoCliente
                                    ? item.id_cliente == this.props.usuarioLogado.id_cliente
                                    : this.props.filtro.id_cliente
                                    ? item.id_cliente == this.props.filtro.id_cliente
                                    : true
                            )}
                            label="Unidade Consumidora"
                            cols="12 6 4"
                            placeholder="Selecione a UC"
                            value={this.props.filtro.id_unidade_consumidora}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_unidade_consumidora: value,
                                });
                                this.props.getLista();
                            }}
                        />
                        <Select
                            name="id_documento_tipo"
                            options={this.props.listaDocumentoTipo}
                            label="Tipo"
                            cols="12 6 4"
                            placeholder="Selecione o Tipo de Documento"
                            value={this.props.filtro.id_documento_tipo}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_documento_tipo: value,
                                });
                                this.props.getLista();
                            }}
                        />
                    </Row>

                    <Table responsive>
                        <THead>
                            <NewTr
                                backgroundColorTr={"#FFFFFF"}
                                backgroundColorTd={"#F5F5F5"}
                                celulas={
                                    <>
                                        <Th sticky minWidth={70}>
                                            Cliente
                                        </Th>
                                        <Th sticky minWidth={150}>
                                            Unidade Consumidora
                                        </Th>
                                        <Th sticky minWidth={70}>
                                            Tipo
                                        </Th>
                                        <Th sticky minWidth={90}>
                                            Documento
                                        </Th>
                                        <Th sticky minWidth={70}>
                                            Data
                                        </Th>
                                    </>
                                }
                            />
                        </THead>
                        <TBody>
                            {this.props.lista.map((item) => (
                                <NewTr
                                    key={item.id}
                                    backgroundColorTr={"#FFFFFF"}
                                    backgroundColorTd={"#F5F5F5"}
                                    celulas={
                                        <>
                                            <Td>{item.nome_cliente}</Td>
                                            <Td>{item.nome_unidade_consumidora}</Td>
                                            <Td>{item.nome_documento_tipo}</Td>
                                            <Td>{item.nome}</Td>
                                            <Td minWidth={75}>
                                                {item.vigencia_inicio ? `${String(item.vigencia_inicio).substring(4, 6)}/${String(item.vigencia_inicio).substring(0, 4)}` : null}
                                            </Td>
                                        </>
                                    }
                                    botoes={[
                                        {
                                            type: "primary",
                                            icon: "fas fa-eye",
                                            disabled: !this.props.usuarioLogado.acesso_cadastro_cliente_visualizar && !acessoCliente,
                                            event: () => {
                                                let registro = {
                                                    ...item,
                                                    vigencia_inicio: `${String(item.vigencia_inicio).substring(4, 6)}/${String(item.vigencia_inicio).substring(0, 4)}`,
                                                    vigencia_fim: `${String(item.vigencia_fim).substring(4, 6)}/${String(item.vigencia_fim).substring(0, 4)}`,
                                                };
                                                this.props.setModoTela("visualizacao", registro);
                                                this.props.initForm(registro);
                                            },
                                        },
                                    ]}
                                />
                            ))}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.documento.modoTela,
	formularioValues: getFormValues("documentoForm")(state),
	aguardando: state.documento.aguardando,
	filtro: state.documento.filtro,
	lista: state.documento.lista,
	listaCliente: state.documento.listaCliente,
	listaUnidadeConsumidora: state.documento.listaUnidadeConsumidora,
	listaDocumentoTipo: state.documento.listaDocumentoTipo,
	listaMeses: state.documento.listaMeses,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			setFiltro,
			salvar,
			excluir,
			getLista,
			getListaCliente,
			getListaUnidadeConsumidora,
			getListaDocumentoTipo,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(DocumentoCliente);
