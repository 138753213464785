import axios from "axios";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";

//id usado para alterar a situação do arquivo como "Download realizado", usado apenas nas fatura até então
export function setArquivoPDF(pdf = null, nomePdf = "", orientation = "portrait", id_pdf = 0) {
	return (dispatch, getState) => {
		dispatch({
			type: "DISPLAY_PDF_CARREGAR",
			payload: { pdf, nomePdf, orientation, id_pdf },
		});
	};
}

export function uploadPDF(pdf, nomePdf) {
	return (dispatch, getState) => {
		axios
			.post(`${consts.API_URL}/pdf`, {
				nomePdf,
				pdf,
			})
			.then((resp) => {
				const { pdf_link } = resp.data;
				const full_pdf_link = `https://api-eficience.infinitum-ti.com/${pdf_link}`;
				dispatch({ type: "DISPLAY_PDF_SET_LINK", payload: full_pdf_link });
			})
			.catch((e) => {
				setErroAPI(e);
			});
	};
}
