import DateFormat from "../common/dateFormat/DateFormat";
import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";
const INITIAL_STATE = {
	registro: {},
	aguardando: false,
	modoTela: "lista",
	lista: [],
	listaCliente: [],
	listaUnidadeConsumidora: [],
	filtro: {
		id_cliente: null,
		id_unidade_consumidora: null,
		id_agrupador: null, //id_geracao_integracao ou id_unidade_consumidora
		agrupar_por_uc: true,
	},
	listaGraficoTodasIntegracoesAgrupadasPorHora: [],
	listaGraficoEnergiaDia: [],
	listaGraficoEnergiaDiasMes: [],
	listaGraficoEnergiaMesesAno: [],
	dataGrafico: DateFormat.getDataAtual().replace(/\//g, "-"),
	AMARELA: "AMARELA",
	VERMELHA: "VERMELHA",
	VERDE: "VERDE",
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "GERACAO_LEITURA_MODO_TELA":
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
			};

		case "GERACAO_LEITURA_SET_STATUS_VISUALIZADO":
			const idOuListaIds = action.payload instanceof Array ? action.payload : [action.payload];
			//Modifica a lista atual para não precisar buscar novamente a cada clique nos botões vermelhos
			const lista = state.lista.map((item) => {
				const itemRetorno = { ...item };
				if (idOuListaIds.includes(item.id)) {
					itemRetorno.status_visualizado = true;
				}
				return itemRetorno;
			});
			return { ...state, lista: ordenarListaLeituras(lista, state) };

		case "GERACAO_LEITURA_SET_AGUARDANDO":
			return { ...state, aguardando: action.payload };

		case "GERACAO_LEITURA_SET_DATA_GRAFICO":
			return { ...state, dataGrafico: action.payload };

		case "GERACAO_LEITURA_FILTRO":
			return { ...state, filtro: action.payload };

		case "GERACAO_LEITURA_LISTA_GRAFICO_TODAS_INTEGRACOES_AGRUPADAS_POR_HORA":
			return {
				...state,
				listaGraficoTodasIntegracoesAgrupadasPorHora: inserirLeiturasZeradasGraficos({
					dataGrafico: state.dataGrafico,
					leituras: action.payload.data || [],
					nomeAtributoValor: "potencia_instantanea_media_hora",
					minutosIntervalo: 60,
				}),
			};
		case "GERACAO_LEITURA_LISTA_GRAFICO_ENERGIA_DIA":
			return {
				...state,
				listaGraficoEnergiaDia: inserirLeiturasZeradasGraficos({
					dataGrafico: state.dataGrafico,
					leituras: action.payload.data,
					nomeAtributoValor: "potencia_instantanea",
					minutosIntervalo: 5,
				}),
			};

		case "GERACAO_LEITURA_LISTA_GRAFICO_ENERGIA_DIAS_MES":
			return {
				...state,
				listaGraficoEnergiaDiasMes: action.payload.data,
			};

		case "GERACAO_LEITURA_LISTA_GRAFICO_ENERGIA_MESES_ANO":
			return {
				...state,
				listaGraficoEnergiaMesesAno: action.payload.data,
			};

		case "GERACAO_LEITURA_LISTADO":
			action.payload.data.forEach((item, index, array) => {
				const modulosPotenciaTotal = parseFloat(item.modulos_potencia_total) >= 0 ? parseFloat(item.modulos_potencia_total) : 0;
				const inversorPotenciaTotal = parseFloat(item.inversor_potencia_total) >= 0 ? parseFloat(item.inversor_potencia_total) : 0;

				//se for zero considera 1
				const potenciaInstalada = Math.min(modulosPotenciaTotal, inversorPotenciaTotal);
				const potenciaInstantanea = parseFloat(item.potencia_instantanea) >= 0 ? parseFloat(item.potencia_instantanea) : 0;

				array[index].percentual_producao =
					potenciaInstantanea && potenciaInstalada ? Number((potenciaInstantanea * 100) / potenciaInstalada).toFixed(1) : 0;
			});
			return {
				...state,
				lista: ordenarListaLeituras(action.payload.data, state),
			};

		case "GERACAO_LEITURA_CLIENTE_SELECT_LISTADO":
			return {
				...state,
				listaCliente: action.payload.data,
			};

		case "GERACAO_LEITURA_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
			return {
				...state,
				listaUnidadeConsumidora: filtrarUcPorDataDesligamento(action.payload.data),
			};

		default:
			return state;
	}
};

function inserirLeiturasZeradasGraficos({ dataGrafico, leituras, nomeAtributoValor, minutosIntervalo }) {
	const tempoMs = 1000 * 60 * minutosIntervalo;
	const ano = dataGrafico.substring(0, 4);
	const mes = parseInt(dataGrafico.substring(5, 7)) - 1;
	const dia = parseInt(dataGrafico.substring(8, 10));
	const primeiroHorarioDoDia = new Date(ano, mes, dia, 0, 0, 0, 0);
	const ultimoHorarioDoDia = new Date(ano, mes, dia, 23, 59, 59, 0);

	//Se a lista vier vazia cria uma nova lista com leituras fake de 5 em 5 min e potencia_instantanea zerada
	if (!leituras.length) {
		const listaTodosVazios = [
			{
				data_hora: primeiroHorarioDoDia.toISOString(),
				[nomeAtributoValor]: "0",
			},
		];

		//enquanto estiver maior que 00:00h e menor que 23:59h vai adicionando
		while (new Date(listaTodosVazios[listaTodosVazios.length - 1].data_hora).getTime() + tempoMs < ultimoHorarioDoDia.getTime()) {
			const msNovaData = new Date(listaTodosVazios[listaTodosVazios.length - 1].data_hora).getTime() + tempoMs;
			const dataNovaLeitura = new Date(msNovaData);

			const novaLeitura = {
				data_hora: dataNovaLeitura.toISOString(),
				[nomeAtributoValor]: "0",
			};
			listaTodosVazios.push(novaLeitura);
		}
		return listaTodosVazios;
	} else {
		//se a lista não vier vazia, adiciona leituras zeradas (fake) antes e depois da lista
		const listaComVazios = [...leituras];

		//enquanto for maior que 00:00h e menor q o primeira leitura válida adiciona leituras zeradas (fake)
		while (new Date(listaComVazios[0].data_hora).getTime() - tempoMs > primeiroHorarioDoDia.getTime()) {
			const novaLeitura = {
				data_hora: new Date(new Date(listaComVazios[0].data_hora).getTime() - tempoMs).toISOString(),
				[nomeAtributoValor]: "0",
			};
			listaComVazios.unshift(novaLeitura);
		}
		//enquanto for maior que a última leitura válida da lista e menor q 23:59h adiciona leituras zeradas (fake)
		while (new Date(listaComVazios[listaComVazios.length - 1].data_hora).getTime() + tempoMs < ultimoHorarioDoDia.getTime()) {
			const novaLeitura = {
				data_hora: new Date(new Date(listaComVazios[listaComVazios.length - 1].data_hora).getTime() + tempoMs).toISOString(),
				[nomeAtributoValor]: "0",
			};
			listaComVazios.push(novaLeitura);
		}
		return listaComVazios;
	}
}

function validarStatusDetalhesSemana(item, indice, lista, telaDetalhesAtiva) {
	//VALIDA STATUS E HORÁRIO DA ÚLTIMA LEITURA

	if (telaDetalhesAtiva) {
		if (indice === 0) {
			//compara com a datahora atual
			return item.status && new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora) && item.potencia_instantanea > 0;
		} else {
			//compara com o anterior
			return (
				item.status && new Date(lista[indice - 1].data_hora).getHours() - new Date(item.data_hora).getHours() < 3 && item.potencia_instantanea > 0
			);
		}
	} else {
		const todosInversoresOk = item.resumo_equipamento.every(
			(inversor) => JSON.parse(inversor).status && parseFloat(JSON.parse(inversor).potencia_instantanea || 0) > 0
		);
		return item.status && validarLeituraUltimasTresHoras(item) && item.potencia_instantanea > 0 && todosInversoresOk;
	}
}

function validarLeituraUltimasTresHoras(item) {
	const horarioTresHorasAtras = new Date();
	horarioTresHorasAtras.setHours(horarioTresHorasAtras.getHours() - 3);
	return new Date(item.data_hora).getTime() > horarioTresHorasAtras.getTime();
}

function ordenarListaLeituras(lista, state) {
	//SÓ LISTA O STATUS AMARELO E VERMELHO NO TOPO SE NÃO ESTIVER NA TELA DETALHES -------------------------
	const telaDetalhesAtiva = !!state.filtro.id_agrupador;

	//ADICIONA PROPRIEDADE statusVerificado, VALIDANDO ALÉM DO STATUS DO BANCO, O PERÍODO SEM GERAÇÃO DE ENERGIA E A POTÊNCIA INSTANTÂNEA
	const listaStatusVerificado = lista.map((item, i) => ({
		...item,
		statusVerificado: validarStatusDetalhesSemana(item, i, lista, telaDetalhesAtiva),
	}));

	//MONTA UMA LISTA COM TODOS CONSIDERADOS STATUS AMARELO
	const listaStatusAmarelo = listaStatusVerificado
		.filter((item) => validarInversoresStatusAmarelo(item))
		.map((item) => ({ ...item, corStatus: state.AMARELA }));

	//ORDENA OS AMARELOS PARA OS QUE ESTÃO EM ALERTA APARECER PRIMEIRO
	listaStatusAmarelo.sort((a, b) => a.status_visualizado - b.status_visualizado);

	//MONTA UMA LISTA COM TODOS CONSIDERADOS STATUS VERMELHO
	const listaStatusVermelho = listaStatusVerificado
		.filter((item) => !item.statusVerificado && !listaStatusAmarelo.find((amarelo) => amarelo.id == item.id))
		.map((item) => ({ ...item, corStatus: state.VERMELHA }));

	//ORDENA OS VERMELHOS PARA OS QUE ESTÃO EM ALERTA APARECER PRIMEIRO
	listaStatusVermelho.sort((a, b) => a.status_visualizado - b.status_visualizado);

	//MONTA UMA LISTA COM TODOS CONSIDERADOS STATUS VERDE
	const listaStatusVerde = listaStatusVerificado.filter((item) => item.statusVerificado).map((item) => ({ ...item, corStatus: state.VERDE }));

	//UNIFICA AS DUAS LISTAS, JUNTAMENTE COM OS STATUS VERDE
	const listaOrdenadaCores = [...listaStatusAmarelo, ...listaStatusVermelho, ...listaStatusVerde];

	//CONDICIONA A ORDENAÇÃO DE ACORDO COM O MODO DE TELA ATIVO
	return telaDetalhesAtiva ? listaOrdenadaCores.sort((a, b) => b.id - a.id) : listaOrdenadaCores;
	//--------------------------------------------------------------------------------------------
}
function validarInversoresStatusAmarelo(leitura) {
	//SE A LEITURA FOR DE MAIS DE 3 HORAS ATRÁS FICA VERMELHO E IGNORA A VALIDAÇÃO DOS AMARELOS
	if (!validarLeituraUltimasTresHoras(leitura)) return;

	//SE PASSAR NA VALIDAÇÃO DE TEMPO DA LEITURA IRÁ VALIDAR O STATUS DOS INVERSORES
	//SE TIVER PELO MENOS UM ONLINE E UM OFFLINE CONSIDERA AMARELO
	if (leitura.resumo_equipamento.length > 1) {
		const temAlgumOnline = leitura.resumo_equipamento.some(
			(item) => JSON.parse(item).status && parseFloat(JSON.parse(item).potencia_instantanea || 0) > 0
		);

		const temAlgumOffline = leitura.resumo_equipamento.some((item) => !JSON.parse(item).status);
		return temAlgumOnline && temAlgumOffline;
	}
	//SE TIVER APENAS UM INVERSOR NUNCA VAI SER AMARELO, ENTÃO IGNORA O REGISTRO
	//POIS ELE SÓ FICARA VERDE OU VERMELHO
	return false;
}
