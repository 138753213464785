import axios from "axios";
import consts from "../consts";
import { setErroAPI } from "../common/toast/toast";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";

export function setFiltro(filtro) {
    return {
        type: "ANALISE_SET_FILTRO",
        payload: filtro,
    };
}

export function setAguardando(aguardando) {
    return {
        type: "ANALISE_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function setTabSelecionada(tab) {
    return {
        type: "ANALISE_TAB_SELECIONADA",
        payload: tab,
    };
}

export function setAbas(abas) {
    return {
        type: "ANALISE_ABAS_CARREGADA",
        payload: abas,
    };
}

export async function getListaEmpenho() {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));

            const filtroIdCliente = getState().analise.filtro.id_cliente;
            const filtroAno = getState().analise.filtro.ano_referencia;

            //se não tiver filtro de cliente não retorna nada
            if (!filtroIdCliente) {
                return {
                    type: "ANALISE_TABELA_GESTAO_EMPENHO_LISTADO",
                    payload: { data: [] },
                };
            }

            const url = `${consts.API_URL}/analiseEmpenho?id_cliente=${filtroIdCliente}&ano_referencia=${filtroAno}`;
            const resposta = await axios.get(url);
            dispatch({
                type: "ANALISE_TABELA_GESTAO_EMPENHO_LISTADO",
                payload: resposta,
            });
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export async function getDemanda() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_DEMANDA_LISTADO",
                payload: null,
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/demandaContrato?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || 0
            }
			&id_tipo_contrato_demanda=${getState().analise.filtro.id_tipo_contrato_demanda || 0}
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );
            dispatch({
                type: "ANALISE_DEMANDA_LISTADO",
                payload: {
                    demandas: resp.data,
                    vigente: resp.data[0],
                },
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getDemandaContagem() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_DEMANDA_CONTAGEM_LISTADO",
                payload: { data: [] },
            });

            dispatch({
                type: "ANALISE_AGENDAMENTO_DEMANDA",
                payload: { data: [] },
            });

            //se não ta logado sai fora
            if (!getState().auth.usuarioLogado) return {};
            const resp = await axios.get(
                `${consts.API_URL}/demandaContrato/listarContagemComAgendamentoDemanda?
			id_cliente=${
                getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || 0
            }&id_tipo_contrato_demanda=${getState().analise.filtro.id_tipo_contrato_demanda || 0}`
            );
            if (getState().analise.filtro.unidades_consumidoras.length == 1) {
                const idUcSelecionada = getState().analise.filtro.unidades_consumidoras[0].value;
                const demanda = resp.data.find((demanda) => demanda.id_unidade_consumidora == idUcSelecionada) || {};
                const idTipoContratoDemanda = getState().analise.filtro.id_tipo_contrato_demanda || 0;
                const listaAgendamentoDemanda = await getListaAgendamento(demanda.id, idTipoContratoDemanda);

                listaAgendamentoDemanda.forEach((agendamento, indice, lista) => {
                    const ano = String(agendamento.competencia_inicio_vigencia).substring(0, 4);
                    const mes = String(agendamento.competencia_inicio_vigencia).substring(4, 6);

                    const agendamentoOrdenado = {};

                    //ordernar conforme mes de inicio, para que inicie em janeiro na tabela
                    for (let i = demanda.mes_inicio, j = 1; j <= 12; j++, i++) {
                        if (i > 12) {
                            i = 1;
                        }
                        agendamentoOrdenado[`valor_${i}`] = agendamento[`valor_${j}`];
                    }
                    agendamentoOrdenado.id_demanda_contrato = demanda.id;
                    agendamentoOrdenado.competencia_inicio_vigencia = agendamento.competencia_inicio_vigencia;
                    agendamentoOrdenado.competencia_formatada = `Agendado ${mes}/${ano}`;
                    agendamentoOrdenado.mensagem_tooltip = `Início previsto para ${mes}/${ano}`;

                    lista[indice] = { ...agendamentoOrdenado };
                });
                listaAgendamentoDemanda.sort((a, b) => a.competencia_inicio_vigencia - b.competencia_inicio_vigencia);

                dispatch({
                    type: "ANALISE_AGENDAMENTO_DEMANDA",
                    payload: { data: listaAgendamentoDemanda },
                });
            }

            dispatch({
                type: "ANALISE_DEMANDA_CONTAGEM_LISTADO",
                payload: resp,
            });
        } catch (erro) {
            setErroAPI(erro);
        }
    };
}

async function getListaAgendamento(idDemanda, idTipoContratoDemanda) {
    try {
        const agendamentos = await axios.get(
            `${consts.API_URL}/demandaAgendamento?id_demanda_contrato=${
                idDemanda || 0
            }&id_tipo_contrato_demanda=${idTipoContratoDemanda}`
        );
        return agendamentos.data || [];
    } catch (erro) {
        setErroAPI(erro);
        return [];
    }
}

function filtrarIdsUnidadesConsumidoras(state) {
    const filtroGrupoFaturamento = state.filtro.grupos_faturamento.map((item) => parseInt(item.value)); //apenas os ids do filtro
    //se selecionou alguma UC manda a lista de ids das UCs selecionadas
    let ids_unidades_consumidoras = state.filtro.unidades_consumidoras.map((item) => item.value);
    if (ids_unidades_consumidoras.length) {
        return ids_unidades_consumidoras;
    }
    //se não tiver nenhuma UC selecionada, filtra a lista de UC original de acordo com os filtros selecionados
    const listaUCs = state.listaUnidadeConsumidora
        .filter(
            (item) =>
                (!state.filtro.id_cliente || item.id_cliente == state.filtro.id_cliente) &&
                (state.tabSelecionada === "demanda"
                    ? item.id_grupo_faturamento === 1 || (item.id_grupo_faturamento === 3 && item.irrigante)
                    : (!filtroGrupoFaturamento.length || filtroGrupoFaturamento.includes(item.id_grupo_faturamento)) &&
                      ((state.filtro.irrigante && item.irrigante) || (state.filtro.naoIrrigante && !item.irrigante)))
        )
        .map((item) => item.id);

    //se após filtrar as UCs a lista acabar vazia, significa que nenhuma UC se enquadra no filtro
    //entao adiciona retorna uma lista contendo o id 0, que é inválido, para que não traga nenhuma informação
    return listaUCs.length ? listaUCs : [0];
}

export async function getListaGraficoValorDevolvidoAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const usuarioLogado = getState().auth.usuarioLogado || {};
            const modoSafra = state.filtro.devolucao_ano_safra;

            //se for tipo safra os meses iniciam em junho no gráfico
            const endpoint = modoSafra ? "listarGraficoValorDevolvidoSafra" : "listarGraficoValorDevolvidoAnual";

            //valida o id do cliente
            const id_cliente = usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : state.filtro.id_cliente || 0;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${
                    consts.API_URL
                }/fatura/${endpoint}?id_cliente=${id_cliente}&ids_unidades_consumidoras=${encodeURIComponent(
                    ids_unidades_consumidoras
                )}`
            );

            //se o ano/safra atual do filtro não existe na lista do novo cliente altera o ano/safra para o último período da lista
            const anosSelect = resp.data.map((item) =>
                modoSafra ? item.ano_inicio_fim_safra.split("/")[0] : item.ano
            );
            if (!anosSelect.find((item) => item == state.filtro.ano_devolucao)) {
                let anoFiltro = anosSelect.length ? anosSelect[anosSelect.length - 1] : new Date().getFullYear();
                dispatch(
                    setFiltro({
                        ...state.filtro,
                        ano_devolucao: anoFiltro,
                        competencia_inicial_comparativo_devolucao_ressarcimento: modoSafra
                            ? `${anoFiltro}06`
                            : `${anoFiltro}01`,
                        competencia_final_comparativo_devolucao_ressarcimento: modoSafra
                            ? `${parseInt(anoFiltro) + 1}05`
                            : `${anoFiltro}12`,
                        competencia_inicial_devolucao_por_uc: modoSafra ? `${anoFiltro}06` : `${anoFiltro}01`,
                        competencia_final_devolucao_por_uc: modoSafra
                            ? `${parseInt(anoFiltro) + 1}05`
                            : `${anoFiltro}12`,
                    })
                );
            }
            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoValorDevolvidoMensalAno() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_MENSAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const usuarioLogado = getState().auth.usuarioLogado || {};
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            //se for tipo safra os meses iniciam em junho no gráfico
            const endpoint = state.filtro.devolucao_ano_safra
                ? "listarGraficoValorDevolvidoMensalSafra"
                : "listarGraficoValorDevolvidoMensalAno";

            //valida o id do cliente
            const id_cliente = usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : state.filtro.id_cliente || 0;

            const resp = await axios.get(
                `${consts.API_URL}/fatura/${endpoint}?
			id_cliente=${id_cliente}&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&ano=${
                    state.filtro.ano_devolucao
                }`
            );

            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_MENSAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoValorDevolvidoRessarcidoPeriodo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_RESSARCIMENTO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const usuarioLogado = getState().auth.usuarioLogado || {};

            //valida o id do cliente
            const id_cliente = usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : state.filtro.id_cliente || 0;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(`${
                consts.API_URL
            }/fatura/listarGraficoValorDevolvidoRessarcidoPeriodo?id_cliente=${id_cliente}&ids_unidades_consumidoras=${encodeURIComponent(
                ids_unidades_consumidoras
            )}&competencia_inicial=${
                state.filtro.competencia_inicial_comparativo_devolucao_ressarcimento
            }&competencia_final=${state.filtro.competencia_final_comparativo_devolucao_ressarcimento}
			`);

            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_RESSARCIMENTO_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoValorDevolvidoPorUC() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_POR_UC_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const usuarioLogado = getState().auth.usuarioLogado || {};

            //valida o id do cliente
            const id_cliente = usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : state.filtro.id_cliente || 0;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(`${
                consts.API_URL
            }/fatura/listarGraficoValorDevolvidoPorUC?id_cliente=${id_cliente}&ids_unidades_consumidoras=${encodeURIComponent(
                ids_unidades_consumidoras
            )}&competencia_inicial=${state.filtro.competencia_inicial_devolucao_por_uc}&competencia_final=${
                state.filtro.competencia_final_devolucao_por_uc
            }
			`);

            dispatch({
                type: "ANALISE_GRAFICO_DEVOLUCAO_POR_UC_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCusto?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoAnualPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCusto?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&periodo_safra=${true}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoConsumoAnual(lista) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_ANUAL_LISTADO",
                payload: { data: lista || [] },
            });

            if (!lista) {
                const state = getState().analise;
                const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
                const resp = await axios.get(
                    `${consts.API_URL}/fatura/listarGraficoConsumo?
				id_cliente=${
                    getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                        ? getState().auth.usuarioLogado.id_cliente
                        : getState().analise.filtro.id_cliente || null
                }
				&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
                );
                dispatch({
                    type: "ANALISE_GRAFICO_CONSUMO_ANUAL_LISTADO",
                    payload: resp,
                });
            }
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoTarifaMedia() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoTarifaMedia?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}
export async function getListaGraficoTarifaMediaGeral() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_GERAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoTarifaMedia?
				id_cliente=${
                    getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                        ? getState().auth.usuarioLogado.id_cliente
                        : getState().analise.filtro.id_cliente || null
                }
				&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
				&geral=true`
            );
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_GERAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoPorHorarioAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_HORARIO_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorHorario?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&ano=${getState().analise.filtro.anoComparativoPizza || getState().analise.filtro.ano}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_horario}
			&competencia_final=${getState().analise.filtro.competencia_final_horario}`
            );
            const unidadeConsumidora = getState().analise.listaUnidadeConsumidora.find((item) =>
                getState().analise.filtro.unidades_consumidoras.find((uc) => uc.value == item.id)
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_HORARIO_ANUAL_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        mes: item.mes,
                        tipos: item.tipos.filter((tipo) => {
                            if (tipo.tipo == "Reservado") {
                                return (
                                    !unidadeConsumidora ||
                                    unidadeConsumidora.id_grupo_faturamento == 2 ||
                                    unidadeConsumidora.irrigante
                                );
                            } else {
                                return true;
                            }
                        }),
                    })),
                },
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}
export async function getListaGraficoConsumoPorHorarioAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_HORARIO_ANUAL_LISTADO",
                payload: { data: [] },
            });
            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoPorHorario?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_horario}
			&competencia_final=${getState().analise.filtro.competencia_final_horario}`
            );
            const unidadeConsumidora = getState().analise.listaUnidadeConsumidora.find((item) =>
                getState().analise.filtro.unidades_consumidoras.find((uc) => uc.value == item.id)
            );

            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_HORARIO_ANUAL_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        mes: item.mes,
                        tipos: item.tipos.filter((tipo) => {
                            if (tipo.tipo == "Reservado") {
                                return (
                                    !unidadeConsumidora ||
                                    unidadeConsumidora.id_grupo_faturamento == 2 ||
                                    unidadeConsumidora.irrigante
                                );
                            } else {
                                return true;
                            }
                        }),
                    })),
                },
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoDemandaEnergiaConsumida() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_DEMANDA_ENERGIA_CONSUMIDA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoDemandaEnergiaConsumida?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&ano=${getState().analise.filtro.anoComparativoPizza || getState().analise.filtro.ano}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_horario}
			&competencia_final=${getState().analise.filtro.competencia_final_horario}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_DEMANDA_ENERGIA_CONSUMIDA_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoConsumoPorHorarioMensal() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_HORARIO_MENSAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoPorHorario?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&ano=${getState().analise.filtro.anoComparativoPizza || getState().analise.filtro.ano}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_horario}
			&competencia_final=${getState().analise.filtro.competencia_final_horario}`
            );
            const unidadeConsumidora = getState().analise.listaUnidadeConsumidora.find((item) =>
                getState().analise.filtro.unidades_consumidoras.find((uc) => uc.value == item.id)
            );

            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_HORARIO_MENSAL_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        mes: item.mes,
                        tipos: item.tipos.filter((tipo) => {
                            if (tipo.tipo == "Reservado") {
                                return (
                                    !unidadeConsumidora ||
                                    unidadeConsumidora.id_grupo_faturamento == 2 ||
                                    unidadeConsumidora.irrigante
                                );
                            } else {
                                return true;
                            }
                        }),
                    })),
                },
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}
export async function getListaGraficoConsumoPonta() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_PONTA_LISTADO",
                payload: { data: [] },
            });
            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoPonta?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&ano=${getState().analise.filtro.ano}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial}
			&competencia_final=${getState().analise.filtro.competencia_final}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_PONTA_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoConsumoForaPonta() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_FORA_PONTA_LISTADO",
                payload: { data: [] },
            });
            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoForaPonta?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&ano=${getState().analise.filtro.ano}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial}
			&competencia_final=${getState().analise.filtro.competencia_final}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_FORA_PONTA_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}
export async function getListaGraficoConsumoReservado() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_RESERVADO_LISTADO",
                payload: { data: [] },
            });
            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoReservado?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&ano=${getState().analise.filtro.ano}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial}
			&competencia_final=${getState().analise.filtro.competencia_final}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_RESERVADO_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoDemanda(lista) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_DEMANDA_LISTADO",
                payload: { data: lista || [] },
            });

            if (!lista) {
                const state = getState().analise;
                const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

                const resp = await axios.get(
                    `${consts.API_URL}/fatura/listarGraficoDemandaNovo?
				id_cliente=${
                    getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                        ? getState().auth.usuarioLogado.id_cliente
                        : getState().analise.filtro.id_cliente || null
                }
				&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
                &id_tipo_contrato_demanda=${getState().analise.filtro.id_tipo_contrato_demanda || 0}
				&ano=${getState().analise.filtro.ano}
				&competencia_inicial=${getState().analise.filtro.competencia_inicial}
				&competencia_final=${getState().analise.filtro.competencia_final}`
                );
                dispatch({
                    type: "ANALISE_GRAFICO_DEMANDA_LISTADO",
                    payload: resp,
                });
            }
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoReativo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativo?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
				&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoConsumoReativo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_REATIVO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoReativo?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_REATIVO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoReativoTipo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_TIPO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoTipos?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_reativo}
			&competencia_final=${getState().analise.filtro.competencia_final_reativo}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_TIPO_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        ...item,
                        tipos: item.anos,
                    })),
                },
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoConsumoAnualPeriodoSafra(lista) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: { data: lista || [] },
            });

            if (!lista) {
                const state = getState().analise;
                const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
                const resp = await axios.get(
                    `${consts.API_URL}/fatura/listarGraficoConsumo?
				id_cliente=${
                    getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                        ? getState().auth.usuarioLogado.id_cliente
                        : getState().analise.filtro.id_cliente || null
                }
				&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
				&periodo_safra=${true}`
                );
                dispatch({
                    type: "ANALISE_GRAFICO_CONSUMO_ANUAL_PERIODO_SAFRA_LISTADO",
                    payload: resp,
                });
            }
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoTarifaMediaPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoTarifaMedia?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&periodo_safra=${true}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoTarifaMediaGeralPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_GERAL_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoTarifaMedia?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&geral=true
			&periodo_safra=${true}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_TARIFA_MEDIA_GERAL_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoReativoPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativo?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&periodo_safra=${true}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoPorHorarioAnualPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_HORARIO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorHorario?id_cliente=${
                    getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                        ? getState().auth.usuarioLogado.id_cliente
                        : getState().analise.filtro.id_cliente || null
                }&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}&ano=${
                    getState().analise.filtro.anoComparativoPizza || getState().analise.filtro.ano
                }&competencia_inicial=${
                    getState().analise.filtro.competencia_inicial_horario_periodo_safra
                }&competencia_final=${
                    getState().analise.filtro.competencia_final_horario_periodo_safra
                }&periodo_safra=${true}`
            );
            const unidadeConsumidora = getState().analise.listaUnidadeConsumidora.find((item) =>
                getState().analise.filtro.unidades_consumidoras.find((uc) => uc.value == item.id)
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_HORARIO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        mes: item.mes,
                        tipos: item.tipos.filter((tipo) => {
                            if (tipo.tipo == "Reservado") {
                                return (
                                    !unidadeConsumidora ||
                                    unidadeConsumidora.id_grupo_faturamento == 2 ||
                                    unidadeConsumidora.irrigante
                                );
                            } else {
                                return true;
                            }
                        }),
                    })),
                },
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoConsumoPorHorarioAnualPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_HORARIO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoConsumoPorHorario?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${(getState().analise.filtro.anoPeriodoSafraAcumulado || 0) - 1}06
			&competencia_final=${getState().analise.filtro.anoPeriodoSafraAcumulado || 0}05
			&periodo_safra=${true}`
            );
            const unidadeConsumidora = getState().analise.listaUnidadeConsumidora.find((item) =>
                getState().analise.filtro.unidades_consumidoras.find((uc) => uc.value == item.id)
            );

            dispatch({
                type: "ANALISE_GRAFICO_CONSUMO_HORARIO_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        mes: item.mes,
                        tipos: item.tipos.filter((tipo) => {
                            if (tipo.tipo == "Reservado") {
                                return (
                                    !unidadeConsumidora ||
                                    unidadeConsumidora.id_grupo_faturamento == 2 ||
                                    unidadeConsumidora.irrigante
                                );
                            } else {
                                return true;
                            }
                        }),
                    })),
                },
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_DEMANDA_ENERGIA_CONSUMIDA_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoDemandaEnergiaConsumida?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&ano=${getState().analise.filtro.anoComparativoPizza || getState().analise.filtro.ano}
			&competencia_inicial=${(getState().analise.filtro.anoPeriodoSafraAcumulado || 0) - 1}06
			&competencia_final=${getState().analise.filtro.anoPeriodoSafraAcumulado || 0}05
			&periodo_safra=${true}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_DEMANDA_ENERGIA_CONSUMIDA_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoMultaAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_MULTA_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoMulta?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_MULTA_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoMultaAgrupado() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_MULTA_AGRUPADO_LISTADO",
                payload: { data: [] },
            });
            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoTiposMulta?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_multa}
			&competencia_final=${getState().analise.filtro.competencia_final_multa}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_MULTA_AGRUPADO_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        ...item,
                        tipos: item.anos,
                    })),
                },
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoReativoTipoMulta() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_TIPO_MULTA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoTipos?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_multa}
			&competencia_final=${getState().analise.filtro.competencia_final_multa}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_TIPO_MULTA_LISTADO",
                payload: {
                    data: resp.data.map((item) => ({
                        ...item,
                        tipos: item.anos,
                    })),
                },
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoAvaliacaoProducao() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_AVALIACAO_PRODUCAO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoAvaliacaoProducao?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial}
			&competencia_final=${getState().analise.filtro.competencia_final}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_AVALIACAO_PRODUCAO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoQuantidadeAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_QUANTIDADE_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoQuantidade?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_QUANTIDADE_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoSaldo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_SALDO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoSaldo?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_SALDO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoComparativoConsumo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_COMPARATIVO_CONSUMO_LISTADO",
                payload: { data: [] },
            });
            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoComparativoConsumo?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_COMPARATIVO_CONSUMO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoConsumoInstantaneo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_CONSUMO_INSTANTANEO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoConsumoInstantaneo?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial}
			&competencia_final=${getState().analise.filtro.competencia_final}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_CONSUMO_INSTANTANEO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoEconomia() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_ECONOMIA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoEconomia?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial}
			&competencia_final=${getState().analise.filtro.competencia_final}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_ECONOMIA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoPorUnidadeAcumuladoAno() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidadeAcumuladoAno?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&competencia_base=${getState().analise.filtro.anoAcumulado}12`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidadeAcumuladoAno?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&periodo_safra=true
			&competencia_inicial=${(getState().analise.filtro.anoPeriodoSafraAcumulado || 0) - 1}06
			&competencia_final=${getState().analise.filtro.anoPeriodoSafraAcumulado || 0}05`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoPorUnidadeAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ANUAL_LISTADO",
                payload: { data: [] },
            });
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidadeGrupo?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_unidade}
			&competencia_final=${getState().analise.filtro.competencia_final_unidade}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaCustoPorUnidadePeriodo() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_CUSTO_POR_UNIDADE_PERIODO_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarCustoPorUnidadePeriodo?
				id_cliente=${
                    getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                        ? getState().auth.usuarioLogado.id_cliente
                        : getState().analise.filtro.id_cliente || null
                }
				&competencia_inicial_antes=${getState().analise.filtro.competencia_inicial_custo_comparativo_antes}
				&competencia_final_antes=${getState().analise.filtro.competencia_final_custo_comparativo_antes}
				&competencia_inicial_depois=${getState().analise.filtro.competencia_inicial_custo_comparativo_depois}
				&competencia_final_depois=${getState().analise.filtro.competencia_final_custo_comparativo_depois}
				`
            );
            dispatch({
                type: "ANALISE_CUSTO_POR_UNIDADE_PERIODO_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoPorUnidadeAnualPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidadeGrupo?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&id_unidade_consumidora=${null}
			&ano=${
                getState().analise.filtro.competencia_final
                    ? parseInt(parseInt(getState().analise.filtro.competencia_final) / 100)
                    : null
            }
			&competencia_inicial=${
                getState().analise.filtro.ano_safra_custo_anual_periodo_safra
                    ? `${getState().analise.filtro.ano_safra_custo_anual_periodo_safra}06`
                    : null
            }
			&competencia_final=${
                getState().analise.filtro.ano_safra_custo_anual_periodo_safra
                    ? `${getState().analise.filtro.ano_safra_custo_anual_periodo_safra + 1}05`
                    : null
            }
			&periodo_safra=${true}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_ANUAL_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoPorUnidadeMensal() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_MENSAL_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidade?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&id_unidade_consumidora=${null}
			&ano=${getState().analise.filtro.ano}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial}
			&competencia_final=${getState().analise.filtro.competencia_final}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_MENSAL_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getListaGraficoCustoPorUnidadeMensalPeriodoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_MENSAL_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidade?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&id_unidade_consumidora=${null}
			&ano=${getState().analise.filtro.ano}
			&competencia_inicial=${
                getState().analise.filtro.ano_safra_custo_anual_periodo_safra
                    ? `${getState().analise.filtro.ano_safra_custo_anual_periodo_safra}06`
                    : null
            }
			&competencia_final=${
                getState().analise.filtro.ano_safra_custo_anual_periodo_safra
                    ? `${getState().analise.filtro.ano_safra_custo_anual_periodo_safra + 1}05`
                    : null
            }
			&periodo_safra=${true}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_UNIDADE_MENSAL_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoReativoPorUnidadeAcumuladoAno() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoPorUnidadeAcumuladoAno?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_reativo}
			&competencia_final=${getState().analise.filtro.competencia_final_reativo}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: resp,
            });
        } catch (erro) {
            setErroAPI(erro);
        }
    };
}

export async function getListaGraficoCustoReativoPorUnidadeAcumuladoAnoPeridoSafra() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ACUMULADO_ANO_PERIODO_SAFRA_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoPorUnidadeAcumuladoAno?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&competencia_inicial=${(getState().analise.filtro.anoPeriodoSafraAcumulado || 0) - 1}06
			&competencia_final=${getState().analise.filtro.anoPeriodoSafraAcumulado || 0}05
			&periodo_safra=${true}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ACUMULADO_ANO_PERIODO_SAFRA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoReativoPorUnidadeAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoReativoPorUnidade?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&id_unidade_consumidora=${null}
			&ano=${
                getState().analise.filtro.competencia_inicial_reativo
                    ? parseInt(parseInt(getState().analise.filtro.competencia_inicial_reativo) / 100)
                    : null
            }
			&competencia_inicial=${getState().analise.filtro.competencia_inicial_reativo}
			&competencia_final=${getState().analise.filtro.competencia_inicial_reativo}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getGeracaoCadastro() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_CADASTRO_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/geracao?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );

            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_CADASTRO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoGeracaoEconomiaPonta() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_ECONOMIA_PONTA_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoEconomiaPonta?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}
			&competencia_inicial=${getState().analise.filtro.competencia_inicial}
			&competencia_final=${getState().analise.filtro.competencia_final}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_GERACAO_ECONOMIA_PONTA_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: "ANALISE_CLIENTE_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaDemandaContratoTipo() {
    const request = axios.get(`${consts.API_URL}/demandaContratoTipo/listarselect`);
    return {
        type: "ANALISE_CONTRATO_DEMANDA_TIPO_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaUnidadeConsumidora() {
    const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect`);
    return {
        type: "ANALISE_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
        payload: request,
    };
}

export function buscaCusd(id, nomePdf) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const resp = await axios.get(`${consts.API_URL}/demandaContrato/importarCusd?id=${id}`);
            if (resp.data && resp.data.arquivo_cusd) {
                dispatch(setArquivoPDF(resp.data.arquivo_cusd, nomePdf));
            }
        } catch (ex) {
            dispatch(setErroAPI(ex));
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function buscaCcer(id, nomePdf) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const resp = await axios.get(`${consts.API_URL}/demandaContrato/importarCcer?id=${id}`);
            if (resp.data && resp.data.arquivo_ccer) {
                dispatch(setArquivoPDF(resp.data.arquivo_ccer, nomePdf));
            }
        } catch (ex) {
            dispatch(setErroAPI(ex));
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export async function getListaGraficoCustoTiposMultaAnual() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_TIPO_MULTA_ANUAL_LISTADO",
                payload: { data: [] },
            });

            const state = getState().analise;
            const ids_unidades_consumidoras = filtrarIdsUnidadesConsumidoras(state);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoTiposMulta?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&ids_unidades_consumidoras=${encodeURIComponent(ids_unidades_consumidoras)}`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_TIPO_MULTA_ANUAL_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export async function getListaGraficoCustoMultaPorUnidadeAcumuladoAno() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_MULTA_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCustoMultaPorUnidade?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().analise.filtro.id_cliente || null
            }
			&competencia_base=${getState().analise.filtro.anoMultaAcumulado}12`
            );
            dispatch({
                type: "ANALISE_GRAFICO_CUSTO_MULTA_UNIDADE_ACUMULADO_ANO_LISTADO",
                payload: resp,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}
