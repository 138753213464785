const INITIAL_STATE = {
    registro: {},
    modoTela: "lista",
    aguardando: false,
    contratoDemanda: null,
    listaDemandas: [],
    listaDemandaContagem: [],
    listaGraficoCustoAnual: [],
    listaGraficoConsumoAnual: [],
    listaGraficoTarifaMedia: [],
    listaGraficoTarifaMediaGeral: [],
    listaGraficoGeracaoQuantidadeAnual: [],
    listaGraficoCustoUnidadeAnual: [],
    listaCustoPorUnidadePeriodo: [],
    listaGraficoCustoUnidadeMensal: [],
    listaGraficoCustoPorUnidadeAcumuladoAno: [],
    listaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra: [],
    listaGraficoCustoHorarioAnual: [],
    listaGraficoConsumoHorarioAnual: [],
    listaGraficoConsumoHorarioMensal: [],
    listaGraficoConsumoPonta: [],
    listaGraficoConsumoForaPonta: [],
    listaGraficoConsumoReservado: [],
    listaGraficoCustoDemandaEnergiaConsumida: [],
    listaGraficoDemanda: [],
    listaGraficoCustoReativo: [],
    listaGraficoConsumoReativo: [],
    geracaoContrato: null,
    listaGraficoGeracaoSaldo: [],
    listaGraficoGeracaoAvaliacaoProducaoAnual: [],
    listaGraficoGeracaoAvaliacaoProducaoMensal: [],
    listaGraficoGeracaoComparativoConsumo: [],
    listaGraficoGeracaoEconomia: [],
    listaGraficoGeracaoEconomiaPonta: [],
    listaGraficoGeracaoConsumoInstantaneo: [],
    listaGraficoCustoAnualPeriodoSafra: [],
    listaGraficoConsumoAnualPeriodoSafra: [],
    listaGraficoTarifaMediaPeriodoSafra: [],
    listaGraficoTarifaMediaGeralPeriodoSafra: [],
    listaGraficoCustoUnidadeAnualPeriodoSafra: [],
    listaGraficoCustoUnidadeMensalPeriodoSafra: [],
    listaGraficoCustoReativoPeriodoSafra: [],
    listaGraficoCustoReativoPorUnidadeAcumuladoAno: [],
    listaGraficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra: [],
    listaGraficoCustoPorHorarioAnualPeriodoSafra: [],
    listaGraficoConsumoPorHorarioAnualPeriodoSafra: [],
    listaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra: [],
    listaGraficoCustoReativoUnidadeAnual: [],
    listaCliente: [],
    listaUnidadeConsumidora: [],
    listaDemandaContratoTipo: [],
    listaGraficoCustoTiposMulta: [],
    listaGraficoCustoMulta: [],
    listaGraficoCustoMultaAgrupado: [],
    listarGraficoCustoReativoTipo: [],
    listarGraficoCustoReativoTipoMulta: [],
    listaGraficoCustoMultaPorUnidade: [],
    listaEmpenho: [],
    listaGraficoValorDevolvidoAnual: [],
    listaGraficoValorDevolvidoMensalAno: [],
    listaGraficoValorDevolvidoRessarcidoPeriodo: [],
    listaGraficoValorDevolvidoPorUC: [],
    filtro: {
        ano_referencia: `${new Date().getFullYear()}`, //usado na analise de empenho
        ano_devolucao: `${new Date().getFullYear()}`, //usado no grafico mensal da aba devoluções
        devolucao_ano_safra: false,
        competencia_inicial_comparativo_devolucao_ressarcimento: `${new Date().getFullYear()}01`, //usado no grafico pizza da aba devoluções
        competencia_final_comparativo_devolucao_ressarcimento: `${new Date().getFullYear()}12`, //usado no grafico pizza da aba devoluções
        competencia_inicial_devolucao_por_uc: `${new Date().getFullYear()}01`, //usado no grafico barras da aba devoluções
        competencia_final_devolucao_por_uc: `${new Date().getFullYear()}12`, //usado no grafico barras da aba devoluções
        ano: null,
        competencia_inicial: null,
        competencia_final: null,
        id_cliente: null,
        id_unidade_consumidora: null,
        unidades_consumidoras: [],
        grupos_faturamento: [],
        irrigante: true,
        naoIrrigante: true,
        competencia_inicial_unidade: `${new Date().getFullYear()}01`,
        competencia_final_unidade: `${new Date().getFullYear()}12`,
        ano_safra_custo_anual_periodo_safra: new Date().getFullYear() - 1,
        competencia_inicial_horario: `${new Date().getFullYear()}01`,
        competencia_final_horario: `${new Date().getFullYear()}12`,
        competencia_inicial_horario_periodo_safra: `${
            new Date().getMonth() > 5 ? new Date().getFullYear() : new Date().getFullYear() - 1
        }06`,
        competencia_final_horario_periodo_safra: `${
            new Date().getMonth() > 5 ? new Date().getFullYear() + 1 : new Date().getFullYear()
        }05`,
        anoAcumulado: new Date().getFullYear(),
        anoPeriodoSafraAcumulado: new Date().getFullYear(),
        ano_custo_comparativo: new Date().getFullYear(),
        competencia_inicial_custo_comparativo_antes: `${new Date().getFullYear() - 1}01`,
        competencia_final_custo_comparativo_antes: `${new Date().getFullYear() - 1}${
            new Date().getMonth() < 9 ? "0" : ""
        }${new Date().getMonth() + 1}`,
        competencia_inicial_custo_comparativo_depois: `${new Date().getFullYear()}01`,
        competencia_final_custo_comparativo_depois: `${new Date().getFullYear()}${
            new Date().getMonth() < 9 ? "0" : ""
        }${new Date().getMonth() + 1}`,
        anoMultaAcumulado: new Date().getFullYear(),
        competencia_inicial_multa: `${new Date().getFullYear()}01`,
        competencia_final_multa: `${new Date().getFullYear()}12`,
        competencia_inicial_reativo: `${new Date().getFullYear()}01`,
        competencia_final_reativo: `${new Date().getFullYear()}12`,
        id_tipo_contrato_demanda: 1, //padrão traz somente fora ponta
    },
    tabSelecionada: "consumo",
    abas: {
        consumo: false,
        horario: false,
        demanda: false,
        reativo: false,
        excedente: false,
        periodoSafra: false,
        empenho: false,
        devolucao: false,
    },
    listaAgendamentoDemanda: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ANALISE_SET_FILTRO":
            return { ...state, filtro: action.payload };

        case "ANALISE_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };

        case "ANALISE_TAB_SELECIONADA":
            return { ...state, tabSelecionada: action.payload };

        case "ANALISE_ABAS_CARREGADA":
            return { ...state, abas: action.payload };

        case "ANALISE_DEMANDA_LISTADO":
            return {
                ...state,
                contratoDemanda: action.payload ? action.payload.vigente : null,
                listaDemandas: action.payload ? action.payload.demandas : [],
            };

        case "ANALISE_DEMANDA_CONTAGEM_LISTADO":
            return {
                ...state,
                listaDemandaContagem: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoCustoAnual: action.payload.data,
            };

        case "ANALISE_GRAFICO_CONSUMO_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoConsumoAnual: action.payload.data,
            };

        case "ANALISE_GRAFICO_TARIFA_MEDIA_LISTADO":
            return {
                ...state,
                listaGraficoTarifaMedia: action.payload.data,
            };

        case "ANALISE_GRAFICO_TARIFA_MEDIA_PERIODO_SAFRA_LISTADO":
            return {
                ...state,
                listaGraficoTarifaMediaPeriodoSafra: action.payload.data,
            };

        case "ANALISE_GRAFICO_TARIFA_MEDIA_GERAL_LISTADO":
            return {
                ...state,
                listaGraficoTarifaMediaGeral: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_LISTADO":
            return {
                ...state,
                listaGraficoCustoPorUnidadeAcumuladoAno: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_PERIODO_SAFRA_LISTADO":
            return {
                ...state,
                listaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra: action.payload.data,
            };

        case "ANALISE_GRAFICO_GERACAO_QUANTIDADE_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoGeracaoQuantidadeAnual: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_UNIDADE_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoCustoUnidadeAnual: action.payload.data,
            };

        case "ANALISE_CUSTO_POR_UNIDADE_PERIODO_LISTADO":
            return {
                ...state,
                listaCustoPorUnidadePeriodo: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_UNIDADE_MENSAL_LISTADO":
            return {
                ...state,
                listaGraficoCustoUnidadeMensal: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_HORARIO_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoCustoHorarioAnual: action.payload.data,
            };

        case "ANALISE_GRAFICO_CONSUMO_HORARIO_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoConsumoHorarioAnual: action.payload.data,
            };

        case "ANALISE_GRAFICO_CONSUMO_HORARIO_MENSAL_LISTADO":
            return {
                ...state,
                listaGraficoConsumoHorarioMensal: action.payload.data,
            };

        case "ANALISE_GRAFICO_CONSUMO_PONTA_LISTADO":
            return {
                ...state,
                listaGraficoConsumoPonta: action.payload.data,
            };

        case "ANALISE_GRAFICO_CONSUMO_FORA_PONTA_LISTADO":
            return {
                ...state,
                listaGraficoConsumoForaPonta: action.payload.data,
            };

        case "ANALISE_GRAFICO_CONSUMO_RESERVADO_LISTADO":
            return {
                ...state,
                listaGraficoConsumoReservado: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_DEMANDA_ENERGIA_CONSUMIDA_LISTADO":
            return {
                ...state,
                listaGraficoCustoDemandaEnergiaConsumida: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_DEMANDA_ENERGIA_CONSUMIDA_PERIODO_SAFRA_LISTADO":
            return {
                ...state,
                listaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra: action.payload.data,
            };
        case "ANALISE_GRAFICO_DEMANDA_LISTADO":
            return {
                ...state,
                listaGraficoDemanda: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_REATIVO_LISTADO":
            return {
                ...state,
                listaGraficoCustoReativo: action.payload.data,
            };

        case "ANALISE_GRAFICO_CONSUMO_REATIVO_LISTADO":
            return {
                ...state,
                listaGraficoConsumoReativo: action.payload.data,
            };

        case "ANALISE_GRAFICO_GERACAO_CADASTRO_LISTADO":
            return {
                ...state,
                geracaoContrato:
                    action.payload.data.length > 0 ? action.payload.data[action.payload.data.length - 1] : null,
            };

        case "ANALISE_GRAFICO_GERACAO_SALDO_LISTADO":
            return {
                ...state,
                listaGraficoGeracaoSaldo: action.payload.data,
            };

        case "ANALISE_GRAFICO_GERACAO_AVALIACAO_PRODUCAO_LISTADO":
            return {
                ...state,
                listaGraficoGeracaoAvaliacaoProducaoAnual: action.payload.data,
            };

        case "ANALISE_GRAFICO_GERACAO_COMPARATIVO_CONSUMO_LISTADO":
            return {
                ...state,
                listaGraficoGeracaoComparativoConsumo: action.payload.data,
            };

        case "ANALISE_GRAFICO_GERACAO_ECONOMIA_LISTADO":
            return {
                ...state,
                listaGraficoGeracaoEconomia: action.payload.data,
            };

        case "ANALISE_GRAFICO_GERACAO_CONSUMO_INSTANTANEO_LISTADO":
            return {
                ...state,
                listaGraficoGeracaoConsumoInstantaneo: action.payload.data,
            };

        case "ANALISE_GRAFICO_GERACAO_ECONOMIA_PONTA_LISTADO":
            return {
                ...state,
                listaGraficoGeracaoEconomiaPonta: action.payload.data,
            };

        case "ANALISE_CLIENTE_SELECT_LISTADO":
            return {
                ...state,
                listaCliente: action.payload.data,
            };

        case "ANALISE_CONTRATO_DEMANDA_TIPO_SELECT_LISTADO":
            return {
                ...state,
                listaDemandaContratoTipo: action.payload.data,
            };

        case "ANALISE_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
            return {
                ...state,
                listaUnidadeConsumidora: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_ANUAL_PERIODO_SAFRA_LISTADO":
            return {
                ...state,
                listaGraficoCustoAnualPeriodoSafra: action.payload.data,
            };

        case "ANALISE_GRAFICO_CONSUMO_ANUAL_PERIODO_SAFRA_LISTADO":
            return {
                ...state,
                listaGraficoConsumoAnualPeriodoSafra: action.payload.data,
            };

        case "ANALISE_GRAFICO_TARIFA_MEDIA_GERAL_PERIODO_SAFRA_LISTADO":
            return {
                ...state,
                listaGraficoTarifaMediaGeralPeriodoSafra: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_UNIDADE_ANUAL_PERIODO_SAFRA_LISTADO":
            return {
                ...state,
                listaGraficoCustoUnidadeAnualPeriodoSafra: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_UNIDADE_MENSAL_PERIODO_SAFRA_LISTADO":
            return {
                ...state,
                listaGraficoCustoUnidadeMensalPeriodoSafra: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_REATIVO_PERIODO_SAFRA_LISTADO":
            return {
                ...state,
                listaGraficoCustoReativoPeriodoSafra: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ACUMULADO_ANO_LISTADO":
            return {
                ...state,
                listaGraficoCustoReativoPorUnidadeAcumuladoAno: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ACUMULADO_ANO_PERIODO_SAFRA_LISTADO":
            return {
                ...state,
                listaGraficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_REATIVO_UNIDADE_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoCustoReativoUnidadeAnual: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_HORARIO_ANUAL_PERIODO_SAFRA_LISTADO":
            return {
                ...state,
                listaGraficoCustoPorHorarioAnualPeriodoSafra: action.payload.data,
            };

        case "ANALISE_GRAFICO_CONSUMO_HORARIO_ANUAL_PERIODO_SAFRA_LISTADO":
            return {
                ...state,
                listaGraficoConsumoPorHorarioAnualPeriodoSafra: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_TIPO_MULTA_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoCustoTiposMulta: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_MULTA_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoCustoMulta: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_REATIVO_TIPO_LISTADO":
            return {
                ...state,
                listarGraficoCustoReativoTipo: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_REATIVO_TIPO_MULTA_LISTADO":
            return {
                ...state,
                listarGraficoCustoReativoTipoMulta: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_MULTA_AGRUPADO_LISTADO":
            return {
                ...state,
                listaGraficoCustoMultaAgrupado: action.payload.data,
            };

        case "ANALISE_GRAFICO_CUSTO_MULTA_UNIDADE_ACUMULADO_ANO_LISTADO":
            return {
                ...state,
                listaGraficoCustoMultaPorUnidade: action.payload.data,
            };
        case "ANALISE_GRAFICO_DEVOLUCAO_ANUAL_LISTADO":
            return {
                ...state,
                listaGraficoValorDevolvidoAnual: action.payload.data,
            };
        case "ANALISE_GRAFICO_DEVOLUCAO_MENSAL_LISTADO":
            return {
                ...state,
                listaGraficoValorDevolvidoMensalAno: action.payload.data,
            };
        case "ANALISE_GRAFICO_DEVOLUCAO_RESSARCIMENTO_LISTADO":
            return {
                ...state,
                listaGraficoValorDevolvidoRessarcidoPeriodo: action.payload.data,
            };
        case "ANALISE_GRAFICO_DEVOLUCAO_POR_UC_LISTADO":
            return {
                ...state,
                listaGraficoValorDevolvidoPorUC: action.payload.data,
            };

        case "ANALISE_TABELA_GESTAO_EMPENHO_LISTADO":
            return {
                ...state,
                listaEmpenho: action.payload.data,
            };
        case "ANALISE_AGENDAMENTO_DEMANDA":
            return {
                ...state,
                listaAgendamentoDemanda: action.payload.data,
            };

        default:
            return state;
    }
};
