export function adicionarPontosMilhar(numeroComVirgula) {
	// Valida o número
	const parsedNumber = String(numeroComVirgula).includes(",") ? parseFloat(String(numeroComVirgula).replace(",", ".")) : parseFloat(numeroComVirgula);

	if (isNaN(parsedNumber)) return null;

	// converte para 2 casas decimais
	const numeroFloat = parsedNumber.toFixed(2);

	// separa a parte inteira da parte decimal
	const [parteInteira, parteDecimal] = numeroFloat.split(".");

	//verifica se existe sinal de negativo no número
	const sinalNegativo = parteInteira.includes("-") ? "-" : "";

	// separa os digitos
	const arrayParteInteira = parteInteira.split("").filter((caracter) => caracter != "-");

	// insere separador de milhar
	for (let i = arrayParteInteira.length - 3; i > 0; i -= 3) {
		arrayParteInteira.splice(i, 0, ".");
	}

	// une a parte inteira com a parte decimal
	return sinalNegativo + arrayParteInteira.join("") + "," + parteDecimal;
}
