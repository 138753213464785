import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import confirmDialog from '../common/dialog/confirmDialog';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'GERACAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro
			}
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'GERACAO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('geracaoForm', {
			...registro
		})
    ];
}

export function setFiltro(filtro) {
    return {
        type: 'GERACAO_FILTRO',
        payload: filtro
    };
}

export function getLista() {

	return (dispatch, getState) => {

		dispatch({
			type: 'GERACAO_LISTADO',
			payload: { data: [] }
		});

		axios.get(`${consts.API_URL}/geracao?
			id_cliente=${getState().geracao.filtro.id_cliente || null}
			&id_unidade_consumidora=${getState().geracao.filtro.id_unidade_consumidora || null}
			&ano=${getState().geracao.filtro.ano}`)
		.then(resp => {
			dispatch({
				type: 'GERACAO_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {

		if (!registro.id) {

			dispatch(setAguardando(true));

	        axios.post(`${consts.API_URL}/geracao`, {
				...registro,
				ano: (registro.competencia_inicio || '').split('/')[1],
				competencia_inicio: (registro.competencia_inicio || '').split('/')[0] && ((registro.competencia_inicio || '').split('/')[0].match(/\d+/g) || []).join('').trim().length == 2
					&& (registro.competencia_inicio || '').split('/')[1] && ((registro.competencia_inicio || '').split('/')[1].match(/\d+/g) || []).join('').trim().length == 4
					? `${(registro.competencia_inicio || '').split('/')[1]}${(registro.competencia_inicio || '').split('/')[0]}` : null,
				anterior_janeiro: FormatUtils.formatarValorBanco(registro.anterior_janeiro, 2),
				anterior_fevereiro: FormatUtils.formatarValorBanco(registro.anterior_fevereiro, 2),
				anterior_marco: FormatUtils.formatarValorBanco(registro.anterior_marco, 2),
				anterior_abril: FormatUtils.formatarValorBanco(registro.anterior_abril, 2),
				anterior_maio: FormatUtils.formatarValorBanco(registro.anterior_maio, 2),
				anterior_junho: FormatUtils.formatarValorBanco(registro.anterior_junho, 2),
				anterior_julho: FormatUtils.formatarValorBanco(registro.anterior_julho, 2),
				anterior_agosto: FormatUtils.formatarValorBanco(registro.anterior_agosto, 2),
				anterior_setembro: FormatUtils.formatarValorBanco(registro.anterior_setembro, 2),
				anterior_outubro: FormatUtils.formatarValorBanco(registro.anterior_outubro, 2),
				anterior_novembro: FormatUtils.formatarValorBanco(registro.anterior_novembro, 2),
				anterior_dezembro: FormatUtils.formatarValorBanco(registro.anterior_dezembro, 2),
				janeiro: FormatUtils.formatarValorBanco(registro.janeiro, 2),
				fevereiro: FormatUtils.formatarValorBanco(registro.fevereiro, 2),
				marco: FormatUtils.formatarValorBanco(registro.marco, 2),
				abril: FormatUtils.formatarValorBanco(registro.abril, 2),
				maio: FormatUtils.formatarValorBanco(registro.maio, 2),
				junho: FormatUtils.formatarValorBanco(registro.junho, 2),
				julho: FormatUtils.formatarValorBanco(registro.julho, 2),
				agosto: FormatUtils.formatarValorBanco(registro.agosto, 2),
				setembro: FormatUtils.formatarValorBanco(registro.setembro, 2),
				outubro: FormatUtils.formatarValorBanco(registro.outubro, 2),
				novembro: FormatUtils.formatarValorBanco(registro.novembro, 2),
				dezembro: FormatUtils.formatarValorBanco(registro.dezembro, 2),
				inversor_quantidade: FormatUtils.formatarValorBanco(registro.inversor_quantidade, 0),
				inversor_potencia_individual: FormatUtils.formatarValorBanco(registro.inversor_potencia_individual, 2),
				inversor_potencia_total: FormatUtils.formatarValorBanco(registro.inversor_potencia_total, 2),
				modulos_quantidade: FormatUtils.formatarValorBanco(registro.modulos_quantidade, 0),
				modulos_potencia_individual: FormatUtils.formatarValorBanco(registro.modulos_potencia_individual, 2),
				modulos_potencia_total: FormatUtils.formatarValorBanco(registro.modulos_potencia_total, 2),
				valor_investido: FormatUtils.formatarValorBanco(registro.valor_investido, 2)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));

				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {

			confirmDialog('Deseja realmente alterar?', () => {

				dispatch(setAguardando(true));

				axios.put(`${consts.API_URL}/geracao`, {
					...registro,
					ano: (registro.competencia_inicio || '').split('/')[1],
					competencia_inicio: (registro.competencia_inicio || '').split('/')[0] && ((registro.competencia_inicio || '').split('/')[0].match(/\d+/g) || []).join('').trim().length == 2
						&& (registro.competencia_inicio || '').split('/')[1] && ((registro.competencia_inicio || '').split('/')[1].match(/\d+/g) || []).join('').trim().length == 4
						? `${(registro.competencia_inicio || '').split('/')[1]}${(registro.competencia_inicio || '').split('/')[0]}` : null,
					anterior_janeiro: FormatUtils.formatarValorBanco(registro.anterior_janeiro, 2),
					anterior_fevereiro: FormatUtils.formatarValorBanco(registro.anterior_fevereiro, 2),
					anterior_marco: FormatUtils.formatarValorBanco(registro.anterior_marco, 2),
					anterior_abril: FormatUtils.formatarValorBanco(registro.anterior_abril, 2),
					anterior_maio: FormatUtils.formatarValorBanco(registro.anterior_maio, 2),
					anterior_junho: FormatUtils.formatarValorBanco(registro.anterior_junho, 2),
					anterior_julho: FormatUtils.formatarValorBanco(registro.anterior_julho, 2),
					anterior_agosto: FormatUtils.formatarValorBanco(registro.anterior_agosto, 2),
					anterior_setembro: FormatUtils.formatarValorBanco(registro.anterior_setembro, 2),
					anterior_outubro: FormatUtils.formatarValorBanco(registro.anterior_outubro, 2),
					anterior_novembro: FormatUtils.formatarValorBanco(registro.anterior_novembro, 2),
					anterior_dezembro: FormatUtils.formatarValorBanco(registro.anterior_dezembro, 2),
					janeiro: FormatUtils.formatarValorBanco(registro.janeiro, 2),
					fevereiro: FormatUtils.formatarValorBanco(registro.fevereiro, 2),
					marco: FormatUtils.formatarValorBanco(registro.marco, 2),
					abril: FormatUtils.formatarValorBanco(registro.abril, 2),
					maio: FormatUtils.formatarValorBanco(registro.maio, 2),
					junho: FormatUtils.formatarValorBanco(registro.junho, 2),
					julho: FormatUtils.formatarValorBanco(registro.julho, 2),
					agosto: FormatUtils.formatarValorBanco(registro.agosto, 2),
					setembro: FormatUtils.formatarValorBanco(registro.setembro, 2),
					outubro: FormatUtils.formatarValorBanco(registro.outubro, 2),
					novembro: FormatUtils.formatarValorBanco(registro.novembro, 2),
					dezembro: FormatUtils.formatarValorBanco(registro.dezembro, 2),
					inversor_quantidade: FormatUtils.formatarValorBanco(registro.inversor_quantidade, 0),
					inversor_potencia_individual: FormatUtils.formatarValorBanco(registro.inversor_potencia_individual, 2),
					inversor_potencia_total: FormatUtils.formatarValorBanco(registro.inversor_potencia_total, 2),
					modulos_quantidade: FormatUtils.formatarValorBanco(registro.modulos_quantidade, 0),
					modulos_potencia_individual: FormatUtils.formatarValorBanco(registro.modulos_potencia_individual, 2),
					modulos_potencia_total: FormatUtils.formatarValorBanco(registro.modulos_potencia_total, 2),
					valor_investido: FormatUtils.formatarValorBanco(registro.valor_investido, 2)
				}).then(resp => {
					setSuccess('Operação Realizada com sucesso.');
					dispatch(getLista());
					dispatch(setModoTela('lista'));
					dispatch(setAguardando(false));
				}).catch(e => {
					setErroAPI(e);
					dispatch(setAguardando(false));
				});
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
		confirmDialog('Deseja realmente excluir?', () => {
	        axios.delete(`${consts.API_URL}/geracao?id=${registro.id}`)
			.then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		});
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: 'GERACAO_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUnidadeConsumidora() {
    const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect`);
    return {
        type: 'GERACAO_UNIDADE_CONSUMIDORA_SELECT_LISTADO',
        payload: request
    };
}

//AMPLIAÇÃO


export function initFormAmpliacao(registro = {}) {
    return [
        initialize('geracaoAmpliacaoForm', {
			...registro
		})
    ];
}

export function getListaAmpliacao(idGeracao) {

	return (dispatch, getState) => {

		dispatch({
			type: 'GERACAO_AMPLIACAO_LISTADO',
			payload: { data: [] }
		});

		axios.get(`${consts.API_URL}/geracaoAmpliacao?id_geracao=${idGeracao}`)
		.then(resp => {
			dispatch({
				type: 'GERACAO_AMPLIACAO_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
    };
}

export function salvarAmpliacao(registro) {
    return (dispatch, getState) => {

		if (!registro.id) {

			dispatch(setAguardando(true));

	        axios.post(`${consts.API_URL}/geracaoAmpliacao`, {
				...registro,
				competencia: (registro.competencia || '').split('/')[0] && ((registro.competencia || '').split('/')[0].match(/\d+/g) || []).join('').trim().length == 2
					&& (registro.competencia || '').split('/')[1] && ((registro.competencia || '').split('/')[1].match(/\d+/g) || []).join('').trim().length == 4
					? `${(registro.competencia || '').split('/')[1]}${(registro.competencia || '').split('/')[0]}` : null,
				janeiro: FormatUtils.formatarValorBanco(registro.janeiro, 2),
				fevereiro: FormatUtils.formatarValorBanco(registro.fevereiro, 2),
				marco: FormatUtils.formatarValorBanco(registro.marco, 2),
				abril: FormatUtils.formatarValorBanco(registro.abril, 2),
				maio: FormatUtils.formatarValorBanco(registro.maio, 2),
				junho: FormatUtils.formatarValorBanco(registro.junho, 2),
				julho: FormatUtils.formatarValorBanco(registro.julho, 2),
				agosto: FormatUtils.formatarValorBanco(registro.agosto, 2),
				setembro: FormatUtils.formatarValorBanco(registro.setembro, 2),
				outubro: FormatUtils.formatarValorBanco(registro.outubro, 2),
				novembro: FormatUtils.formatarValorBanco(registro.novembro, 2),
				dezembro: FormatUtils.formatarValorBanco(registro.dezembro, 2),
				inversor_quantidade: FormatUtils.formatarValorBanco(registro.inversor_quantidade, 0),
				inversor_potencia_individual: FormatUtils.formatarValorBanco(registro.inversor_potencia_individual, 2),
				inversor_potencia_total: FormatUtils.formatarValorBanco(registro.inversor_potencia_total, 2),
				modulos_quantidade: FormatUtils.formatarValorBanco(registro.modulos_quantidade, 0),
				modulos_potencia_individual: FormatUtils.formatarValorBanco(registro.modulos_potencia_individual, 2),
				modulos_potencia_total: FormatUtils.formatarValorBanco(registro.modulos_potencia_total, 2),
				valor_investido: FormatUtils.formatarValorBanco(registro.valor_investido, 2)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaAmpliacao(registro.id_geracao));
				dispatch(setModoTela('cadastro', getState().geracao.registro));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {

			confirmDialog('Deseja realmente alterar?', () => {

				dispatch(setAguardando(true));

				axios.put(`${consts.API_URL}/geracaoAmpliacao`, {
					...registro,
					competencia: (registro.competencia || '').split('/')[0] && ((registro.competencia || '').split('/')[0].match(/\d+/g) || []).join('').trim().length == 2
						&& (registro.competencia || '').split('/')[1] && ((registro.competencia || '').split('/')[1].match(/\d+/g) || []).join('').trim().length == 4
						? `${(registro.competencia || '').split('/')[1]}${(registro.competencia || '').split('/')[0]}` : null,
					janeiro: FormatUtils.formatarValorBanco(registro.janeiro, 2),
					fevereiro: FormatUtils.formatarValorBanco(registro.fevereiro, 2),
					marco: FormatUtils.formatarValorBanco(registro.marco, 2),
					abril: FormatUtils.formatarValorBanco(registro.abril, 2),
					maio: FormatUtils.formatarValorBanco(registro.maio, 2),
					junho: FormatUtils.formatarValorBanco(registro.junho, 2),
					julho: FormatUtils.formatarValorBanco(registro.julho, 2),
					agosto: FormatUtils.formatarValorBanco(registro.agosto, 2),
					setembro: FormatUtils.formatarValorBanco(registro.setembro, 2),
					outubro: FormatUtils.formatarValorBanco(registro.outubro, 2),
					novembro: FormatUtils.formatarValorBanco(registro.novembro, 2),
					dezembro: FormatUtils.formatarValorBanco(registro.dezembro, 2),
					inversor_quantidade: FormatUtils.formatarValorBanco(registro.inversor_quantidade, 0),
					inversor_potencia_individual: FormatUtils.formatarValorBanco(registro.inversor_potencia_individual, 2),
					inversor_potencia_total: FormatUtils.formatarValorBanco(registro.inversor_potencia_total, 2),
					modulos_quantidade: FormatUtils.formatarValorBanco(registro.modulos_quantidade, 0),
					modulos_potencia_individual: FormatUtils.formatarValorBanco(registro.modulos_potencia_individual, 2),
					modulos_potencia_total: FormatUtils.formatarValorBanco(registro.modulos_potencia_total, 2),
					valor_investido: FormatUtils.formatarValorBanco(registro.valor_investido, 2)
				}).then(resp => {
					setSuccess('Operação Realizada com sucesso.');
					dispatch(getListaAmpliacao(registro.id_geracao));
					dispatch(setModoTela('cadastro', getState().geracao.registro));
					dispatch(setAguardando(false));
				}).catch(e => {
					setErroAPI(e);
					dispatch(setAguardando(false));
				});
			});
		}
    };
}

export function excluirAmpliacao(registro) {
    return (dispatch, getState) => {
		confirmDialog('Deseja realmente excluir?', () => {
	        axios.delete(`${consts.API_URL}/geracaoAmpliacao?id=${registro.id}`)
			.then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaAmpliacao(registro.id_geracao));
				dispatch(setModoTela('cadastro', getState().geracao.registro));
			}).catch(e => {
				setErroAPI(e);
			});
		});
    };
}
