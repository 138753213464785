import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Button from "../../common/button/button";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
	getListaGraficoCustoDemandaEnergiaConsumida,
	getListaGraficoConsumoForaPonta,
	getListaGraficoConsumoPonta,
	getListaGraficoConsumoPorHorarioMensal,
	getListaGraficoConsumoPorHorarioAnual,
	getListaGraficoCustoPorHorarioAnual,
	getListaGraficoConsumoReservado,
	setFiltro,
} from "../analiseActions";
import { montarGraficoPieAnual } from "../../common/graficos/montarGraficoPieAnual.js";
import { montarGraficoClusteredColumnMensal } from "../../common/graficos/montarGraficoClusteredColumnMensal.js";
import { montarGraficoClusteredColumnAnual } from "../../common/graficos/montarGraficoClusteredColumnAnual.js";

function analiseAproveitamentoConsumoHorario(props) {
	const {
		state,
		filtro,
		setAguardando,
		listar,
		buscarPropriedadeUcFiltrada,
		listaGraficoCustoDemandaEnergiaConsumida,
		listaGraficoConsumoReservado,
		listaGraficoConsumoForaPonta,
		listaGraficoConsumoPonta,
		listaGraficoConsumoHorarioMensal,
		listaGraficoCustoHorarioAnual,
		listaGraficoConsumoHorarioAnual,
		getListaGraficoCustoDemandaEnergiaConsumida,
		getListaGraficoConsumoReservado,
		getListaGraficoConsumoPonta,
		getListaGraficoConsumoPorHorarioMensal,
		getListaGraficoConsumoPorHorarioAnual,
		getListaGraficoCustoPorHorarioAnual,
		getListaGraficoConsumoForaPonta,
		setFiltro,
	} = props;

	const [graficoCustoHorarioAnual, setGraficoCustoHorarioAnual] = useState(null);
	const [graficoConsumoHorarioAnual, setGraficoConsumoHorarioAnual] = useState(null);
	const [graficoConsumoHorarioBarraAnual, setGraficoConsumoHorarioBarraAnual] = useState(null);
	const [graficoConsumoHorarioMensal, setGraficoConsumoHorarioMensal] = useState(null);
	const [graficoConsumoPontaMensal, setGraficoConsumoPontaMensal] = useState(null);
	const [graficoConsumoForaPontaMensal, setGraficoConsumoForaPontaMensal] = useState(null);
	const [graficoConsumoReservadoMensal, setGraficoConsumoReservadoMensal] = useState(null);
	const [graficoCustoDemandaEnergiaConsumidaAnual, setGraficoCustoDemandaEnergiaConsumidaAnual] = useState(null);

	useEffect(() => {
		atualizarTodosGraficos();
		return desmontarTodosGraficos;
	}, []);

	useEffect(() => {
		const buscarDados = async () => {
			if (!state.aguardando) return;
			atualizarTodosGraficos();
			await Promise.all([
				getListaGraficoCustoPorHorarioAnual(),
				getListaGraficoConsumoPorHorarioAnual(),
				getListaGraficoConsumoPorHorarioMensal(),
				getListaGraficoConsumoPonta(),
				getListaGraficoConsumoForaPonta(),
				getListaGraficoConsumoReservado(),
				getListaGraficoCustoDemandaEnergiaConsumida(),
			]);
			atualizarTodosGraficos();
			setAguardando(false);
		};

		buscarDados();
		atualizarTodosGraficos();
	}, [state.aguardando]);

	function atualizarTodosGraficos() {
		atualizarListaGraficoCustoPorHorarioAnual();
		atualizarListaGraficoConsumoPorHorarioAnual();
		atualizarListaGraficoConsumoPorHorarioMensal();
		atualizarListaGraficoConsumoPonta();
		atualizarListaGraficoConsumoForaPonta();
		atualizarListaGraficoConsumoReservado();
		atualizarListaGraficoCustoDemandaEnergiaConsumida();
	}

	function desmontarTodosGraficos() {
		if (graficoCustoHorarioAnual) graficoCustoHorarioAnual.dispose();
		if (graficoConsumoHorarioAnual) graficoConsumoHorarioAnual.dispose();
		if (graficoConsumoHorarioBarraAnual) graficoConsumoHorarioBarraAnual.dispose();
		if (graficoConsumoHorarioMensal) graficoConsumoHorarioMensal.dispose();
		if (graficoConsumoPontaMensal) graficoConsumoPontaMensal.dispose();
		if (graficoConsumoForaPontaMensal) graficoConsumoForaPontaMensal.dispose();
		if (graficoConsumoReservadoMensal) graficoConsumoReservadoMensal.dispose();
		if (graficoCustoDemandaEnergiaConsumidaAnual) graficoCustoDemandaEnergiaConsumidaAnual.dispose();
	}

	function atualizarListaGraficoCustoPorHorarioAnual() {
		const graficoCustoHorarioAnualAtualizado = montarGraficoPieAnual(
			state,
			graficoCustoHorarioAnual,
			"graficoCustoHorarioAnual",
			listaGraficoCustoHorarioAnual,
			"Custo Total por Horário (R$)",
			"R$",
			""
		);
		setGraficoCustoHorarioAnual(graficoCustoHorarioAnualAtualizado);
	}

	function atualizarListaGraficoConsumoPorHorarioAnual() {
		const graficoConsumoHorarioAnualAtualizado = montarGraficoPieAnual(
			state,
			graficoConsumoHorarioAnual,
			"graficoConsumoHorarioAnual",
			listaGraficoConsumoHorarioAnual,
			"Consumo Total por Horário (kWh)",
			"",
			"kWh"
		);
		setGraficoConsumoHorarioAnual(graficoConsumoHorarioAnualAtualizado);

		const graficoConsumoHorarioBarraAnualAtualizado = montarGraficoClusteredColumnAnual(
			state,
			graficoConsumoHorarioBarraAnual,
			"graficoConsumoHorarioBarraAnual",
			listaGraficoConsumoHorarioAnual.map((item) => ({
				mes: item.mes,
				anos: item.tipos.map((tipo) => ({
					ano: tipo.tipo,
					valor: tipo.valor,
				})),
			})),
			"Consumo Total Anual por Horário (kWh)",
			"",
			"kWh"
		);
		setGraficoConsumoHorarioBarraAnual(graficoConsumoHorarioBarraAnualAtualizado);
	}

	function atualizarListaGraficoConsumoPorHorarioMensal() {
		const graficoConsumoHorarioMensalAtualizado = montarGraficoClusteredColumnMensal(
			state,
			graficoConsumoHorarioMensal,
			"graficoConsumoHorarioMensal",
			listaGraficoConsumoHorarioMensal.map((item) => ({
				mes: item.mes,
				anos: item.tipos.map((tipo) => ({
					ano: tipo.tipo,
					valor: tipo.valor,
				})),
			})),
			"Consumo Mensal por Horário (kWh)",
			"",
			"kWh"
		);
		setGraficoConsumoHorarioMensal(graficoConsumoHorarioMensalAtualizado);
	}

	function atualizarListaGraficoConsumoPonta() {
		const graficoConsumoPontaMensalAtualizado = montarGraficoClusteredColumnMensal(
			state,
			graficoConsumoPontaMensal,
			"graficoConsumoPontaMensal",
			listaGraficoConsumoPonta,
			"Consumo Ponta Mensal (kWh)",
			"",
			"kWh"
		);
		setGraficoConsumoPontaMensal(graficoConsumoPontaMensalAtualizado);
	}

	function atualizarListaGraficoConsumoForaPonta() {
		const graficoConsumoForaPontaMensalAtualizado = montarGraficoClusteredColumnMensal(
			state,
			graficoConsumoForaPontaMensal,
			"graficoConsumoForaPontaMensal",
			listaGraficoConsumoForaPonta,
			"Consumo Fora Ponta Mensal (kWh)",
			"",
			"kWh"
		);
		setGraficoConsumoForaPontaMensal(graficoConsumoForaPontaMensalAtualizado);
	}

	function atualizarListaGraficoConsumoReservado() {
		const graficoConsumoReservadoMensalAtualizado = montarGraficoClusteredColumnMensal(
			state,
			graficoConsumoReservadoMensal,
			"graficoConsumoReservadoMensal",
			listaGraficoConsumoReservado,
			"Consumo Reservado Mensal (kWh)",
			"",
			"kWh"
		);
		setGraficoConsumoReservadoMensal(graficoConsumoReservadoMensalAtualizado);
	}

	function atualizarListaGraficoCustoDemandaEnergiaConsumida() {
		const graficoCustoDemandaEnergiaConsumidaAnualAtualizado = montarGraficoPieAnual(
			state,
			graficoCustoDemandaEnergiaConsumidaAnual,
			"graficoCustoDemandaEnergiaConsumidaAnual",
			listaGraficoCustoDemandaEnergiaConsumida,
			"Demanda X Energia Consumida (R$)",
			"R$",
			""
		);
		setGraficoCustoDemandaEnergiaConsumidaAnual(graficoCustoDemandaEnergiaConsumidaAnualAtualizado);
	}
	return (
        <div>
            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: true /*!buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("irrigante")*/ ? "" : "none",
                }}
            >
                <ContentCardHeader style={{ backgroundColor: "#fff" }}>
                    <Row>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="De"
                                    autoOk
                                    value={
                                        filtro.competencia_inicial_horario
                                            ? `${String(filtro.competencia_inicial_horario).substring(0, 4)}/${String(filtro.competencia_inicial_horario).substring(
                                                  4,
                                                  6
                                              )}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_inicial_horario: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="Até"
                                    autoOk
                                    value={
                                        filtro.competencia_final_horario
                                            ? `${String(filtro.competencia_final_horario).substring(0, 4)}/${String(filtro.competencia_final_horario).substring(4, 6)}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_final_horario: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="12 4 3 2" style={{ paddingTop: 10 }}>
                            <Button
                                text="Limpar Período"
                                type={"primary"}
                                icon={"fa fa-minus"}
                                event={() => {
                                    setFiltro({
                                        ...filtro,
                                        competencia_inicial_horario: null,
                                        competencia_final_horario: null,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid cols="6 4 3 2" style={{ paddingTop: 10 }}>
                            <Button text="Atualizar" type={"primary"} icon={"fa fa-sync"} event={listar} />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            overflowX: "scroll",
                            justifyContent: window.innerWidth < 768 ? "left" : "center",
                        }}
                    >
                        <div id="graficoCustoHorarioAnual" style={{ minWidth: 550, paddingBlock: 20, height: "300px" }}></div>

                        <div id="graficoConsumoHorarioAnual" style={{ minWidth: 550, paddingBlock: 20, height: "300px" }}></div>

                        <div id="graficoCustoDemandaEnergiaConsumidaAnual" style={{ minWidth: 600, paddingBlock: 20, height: "300px" }}></div>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <Row>
                        <Grid cols="12 12 12 12">
                            <div id="graficoConsumoHorarioBarraAnual" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                        </Grid>
                    </Row>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <Row>
                        <Grid cols="12 12 12 12">
                            <div id="graficoConsumoHorarioMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                        </Grid>
                    </Row>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoPontaMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoForaPontaMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("irrigante") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoReservadoMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}
const mapStateToProps = (state) => ({
	filtro: state.analise.filtro,
	listaGraficoCustoDemandaEnergiaConsumida: state.analise.listaGraficoCustoDemandaEnergiaConsumida,
	listaGraficoConsumoReservado: state.analise.listaGraficoConsumoReservado,
	listaGraficoConsumoForaPonta: state.analise.listaGraficoConsumoForaPonta,
	listaGraficoConsumoPonta: state.analise.listaGraficoConsumoPonta,
	listaGraficoConsumoHorarioMensal: state.analise.listaGraficoConsumoHorarioMensal,
	listaGraficoCustoHorarioAnual: state.analise.listaGraficoCustoHorarioAnual,
	listaUnidadeConsumidora: state.analise.listaUnidadeConsumidora,
	listaGraficoConsumoHorarioAnual: state.analise.listaGraficoConsumoHorarioAnual,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getListaGraficoCustoDemandaEnergiaConsumida,
			getListaGraficoConsumoReservado,
			getListaGraficoConsumoForaPonta,
			getListaGraficoConsumoPonta,
			getListaGraficoConsumoPorHorarioMensal,
			getListaGraficoConsumoPorHorarioAnual,
			getListaGraficoCustoPorHorarioAnual,
			setFiltro,
		},
		dispatch
	);
const AnaliseAproveitamentoConsumoHorario = connect(mapStateToProps, mapDispatchToProps)(analiseAproveitamentoConsumoHorario);
export { AnaliseAproveitamentoConsumoHorario };
