import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Select from "../common/form/select";
import { DisplayPDF } from "../displayPDF/displayPDF";
import {
    setModoTela,
    setFiltro,
    setAguardando,
    getLista,
    getListaCliente,
    getListaUnidadeConsumidora,
    gerarRelatorioMensal,
    gerarRelatorioSolarMensal,
    enviarRelatorio,
    getDadosRelatorioSafra,
} from "./faturaRelatorioActions";

import RelatorioSafraPDF from "../htmlToPdf/RelatorioSafraPDF";
import { htmlToPdf } from "../htmlToPdf/htmlToPdf";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";
import { Loading } from "../common/loading/loading";
import { setError } from "../common/toast/toast";

import { WhatsappShareButton } from "react-share";
import "../displayPDF/displayPDF.css";

class FaturaRelatorio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linhaSelecionada: null,
            linhaSelecionadaUC: null,
            idClienteRelatorio: null,
            nomeClienteRelatorio: null,
            competenciaRelatorio: null,
            isSafariIOS: false,
            zoom: 80,
        };
        this.relatorioSafra = React.createRef();
        this.renderizarRelatorio = this.renderizarRelatorio.bind(this);
        this.closePdf = this.closePdf.bind(this);
    }

    componentWillMount() {
        this.props.getListaCliente();
        this.props.getListaUnidadeConsumidora();
        if (this.props.usuarioLogado && this.props.usuarioLogado.administrador) {
            this.props.getLista();
        } else if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
            this.props.setFiltro({
                ...this.props.filtro,
                id_cliente: this.props.usuarioLogado.id_cliente,
            });
            this.props.getLista();
        }
    }

    async renderizarRelatorio() {
        this.props.setAguardando(true);
        const pdfBase64 = await htmlToPdf(this.relatorioSafra, this.props.setAguardando, "portrait");
        this.props.setArquivoPDF(
            pdfBase64,
            `Relatório Safra ${this.props.filtro.ano_safra.split("/")[0]}_${this.props.filtro.ano_safra.split("/")[1]} - ${this.state.idClienteRelatorio}_${
                this.props.filtro.id_tipo_relatorio
            }.pdf`,
            "portrait"
        );
    }

    isSafari() {
        const ua = navigator.userAgent;
        return ua.includes("Safari") && !ua.includes("Chrome");
    }

    isIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }
    componentDidMount() {
        const safari = this.isSafari();
        const ios = this.isIOS();
        if (safari && ios) {
            this.setState({ ...this.state, isSafariIOS: true });
        }
    }

    closePdf() {
        this.props.setModoTela("lista");
        this.relatorioSafra.current = null;
        this.setState({ ...this.state, zoom: 80 });
        this.props.setArquivoPDF();
    }

    errorIOS() {
        setError("Funcionalidade indisponível para iOS");
    }
    render() {
        if (this.props.pdf) {
            return <DisplayPDF closePdf={this.closePdf} pdfBase64={this.props.pdf} nomePdf={this.props.nomePdf} />;
        }
        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}

                <Content>{this.props.modoTela == "lista" ? this.lista() : null}</Content>
                {/* SE FOR IPHONE RENDERIZA APENAS HTML SEM PODER BAIXAR */}
                {this.props.modoTela == "relatorioSafra" ? (
                    this.state.isSafariIOS ? (
                        <div className="displayPDF" style={{ cursor: "default" }}>
                            <header>
                                <div className="closePDF">
                                    <i title="Fechar" style={{ cursor: "pointer" }} className="fas fa-times" onClick={this.closePdf} />
                                </div>
                                <div className="buttonsPDF">
                                    <div
                                        title="Aumentar Zoom"
                                        style={{ cursor: "pointer" }}
                                        className="fas fa-search-plus"
                                        onClick={() => {
                                            this.setState({ ...this.state, zoom: this.state.zoom + 20 });
                                        }}
                                    />
                                    <div
                                        title="Diminuir Zoom"
                                        style={{ cursor: "pointer" }}
                                        className="fas fa-search-minus"
                                        onClick={() => {
                                            this.setState({ ...this.state, zoom: this.state.zoom > 80 ? this.state.zoom - 20 : this.state.zoom });
                                        }}
                                    />

                                    <WhatsappShareButton onFocus={(e) => (e.target.style.outline = "none")}>
                                        <div
                                            title="Compartilhar pelo WhatsApp"
                                            style={{ cursor: "pointer", color: "#636363" }}
                                            className="fab fa-whatsapp"
                                            onClick={this.errorIOS}
                                        />
                                    </WhatsappShareButton>

                                    <div title="Baixar" style={{ cursor: "pointer", color: "#636363" }} className="fas fa-download" onClick={this.errorIOS} />
                                    <div onClick={this.errorIOS}>
                                        <i title="Imprimir" style={{ cursor: "pointer", color: "#636363" }} className="fas fa-print" />
                                    </div>
                                </div>
                            </header>
                            <div
                                style={{
                                    width: "100vw",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    overflow: "scroll",
                                }}
                            >
                                <div
                                    style={{
                                        transform: `scale(calc(${(window.innerWidth / 100) * this.state.zoom}/2560))`,
                                        transformOrigin: "top",
                                        paddingTop: 150,
                                        paddingLeft: `calc(2560px/100 * ${this.state.zoom - 75})`,
                                    }}
                                >
                                    <RelatorioSafraPDF
                                        ref={this.relatorioSafra}
                                        dadosImpressao={{
                                            ...this.props.dadosRelatorioSafra,
                                            nomeClienteRelatorio: this.state.nomeClienteRelatorio,
                                            competenciaRelatorio: this.state.competenciaRelatorio,
                                        }}
                                        renderizarRelatorio={() => this.props.setAguardando(false)}
                                        setAguardando={this.props.setAguardando}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                overflow: "hidden",
                            }}
                        >
                            <div style={{ marginTop: "200vh" }}>
                                <RelatorioSafraPDF
                                    ref={this.relatorioSafra}
                                    dadosImpressao={{
                                        ...this.props.dadosRelatorioSafra,
                                        nomeClienteRelatorio: this.state.nomeClienteRelatorio,
                                        competenciaRelatorio: this.state.competenciaRelatorio,
                                    }}
                                    renderizarRelatorio={this.renderizarRelatorio}
                                    setAguardando={this.props.setAguardando}
                                />
                            </div>
                        </div>
                    )
                ) : null}
            </div>
        );
    }

    lista() {
        const mesAtual = new Date().getMonth() + 1;
        const anoMax = mesAtual < 6 ? new Date().getFullYear() - 1 : new Date().getFullYear();
        const listaAnoSafra = [];
        for (let ano = anoMax; ano > anoMax - 10; ano--) {
            listaAnoSafra.push({
                id: `${ano - 1}/${ano}`,
                valor: `${ano - 1}/${ano}`,
            });
        }

        return (
            <ContentCard>
                <ContentCardBody>
                    <Row>
                        {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                            <Select
                                name="id_cliente"
                                options={this.props.listaCliente}
                                label="Cliente"
                                cols="12 6 4"
                                placeholder="Selecione o Cliente"
                                value={this.props.filtro.id_cliente}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        id_cliente: value,
                                    });
                                    this.props.getLista();
                                }}
                            />
                        ) : null}

                        {this.props.filtro.id_tipo_relatorio == 2 ? null : ( //no modo safra some
                            <Select
                                name="ano"
                                options={Array.from({ length: 20 }, (v, i) => ({
                                    id: new Date().getFullYear() - 5 + i,
                                    valor: new Date().getFullYear() - 5 + i,
                                }))}
                                label="Ano"
                                cols="12 4 2"
                                placeholder="Selecione o ano"
                                isClearable={false}
                                value={this.props.filtro.ano}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        ano: value,
                                    });
                                    this.props.getLista();
                                }}
                            />
                        )}
                        {this.props.filtro.id_tipo_relatorio != 1 ? null : ( //só aparece no modo mensal
                            <Select
                                name="mes"
                                options={this.props.listaMeses}
                                label="Mês"
                                cols="12 4 2"
                                placeholder="Selecione o mês"
                                isClearable={false}
                                value={this.props.filtro.mes}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        mes: value,
                                    });
                                    this.props.getLista();
                                }}
                            />
                        )}

                        {this.props.filtro.id_tipo_relatorio != 2 ? null : ( //só aparece no modo safra
                            <Select
                                name="ano_safra"
                                options={listaAnoSafra}
                                label="Ano Safra"
                                cols="12 8 4"
                                placeholder="Selecione o ano safra"
                                isClearable={false}
                                value={this.props.filtro.ano_safra}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        ano_safra: value,
                                    });
                                    this.props.getLista();
                                }}
                            />
                        )}

                        <Select
                            name="id_status"
                            options={this.props.listaStatus.filter((item) => this.props.filtro.id_tipo_relatorio == 1 || item.id != 3)} //remove o status importação solar quando no modo safra
                            label="Status"
                            cols="12 4 4"
                            placeholder="Selecione o status"
                            value={this.props.filtro.id_status}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_status: value,
                                });
                            }}
                        />
                        <Select
                            name="tipo_relatorio"
                            options={this.props.listaTiposRelatorios}
                            label="Tipo de Relatório"
                            cols="12 4 4"
                            placeholder="Selecione o Tipo de Relatório"
                            value={this.props.filtro.id_tipo_relatorio}
                            isClearable={false}
                            isSearchable={false}
                            onChange={(value) => {
                                if (value == 3) {
                                    setError("Tipo de relatório indisponível");
                                    return;
                                }
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_tipo_relatorio: value,
                                    id_status: 2,
                                });
                                this.props.getLista();
                            }}
                        />
                    </Row>

                    <Table responsive>
                        <THead>
                            <NewTr
                                backgroundColorTr={"#FFFFFF"}
                                backgroundColorTd={"#F5F5F5"}
                                celulas={
                                    <>
                                        <Th sticky minWidth={90}>
                                            Competência
                                        </Th>
                                        <Th sticky minWidth={100}>
                                            Cliente
                                        </Th>
                                        <Th sticky alignCenter minWidth={100}>
                                            Status
                                        </Th>
                                        <Th sticky alignCenter width={180}>
                                            {this.props.filtro.id_tipo_relatorio == 1 ? "Relatório de Consumo Mensal" : null}
                                            {this.props.filtro.id_tipo_relatorio == 2 ? "Relatório Safra" : null}
                                            {this.props.filtro.id_tipo_relatorio == 3 ? "Relatório Anual" : null}
                                        </Th>
                                        {this.props.filtro.id_tipo_relatorio != 1 ? null : (
                                            <>
                                                <Th sticky minWidth={100}>
                                                    Unidade Geradora
                                                </Th>
                                                <Th sticky width={100} backgroundColor={"#FFFFFF"} alignCenter>
                                                    Relatório Fotovoltaico
                                                </Th>
                                            </>
                                        )}
                                        <Th sticky backgroundColor={this.props.filtro.id_tipo_relatorio == 1 ? "#F5F5F5" : "#FFFFFF"} alignCenter minWidth={50}>
                                            Enviar
                                        </Th>
                                    </>
                                }
                            />
                        </THead>
                        <TBody>
                            {this.props.lista
                                .filter((item) => {
                                    const condicaoFiltroCliente = !this.props.filtro.id_cliente || this.props.filtro.id_cliente == item.id_cliente;
                                    let condicaoFiltroStatus = false;

                                    //VALIDAÇÃO STATUS MODO MENSAL
                                    if (this.props.filtro.id_tipo_relatorio == 1) {
                                        //se não tem produção solar, considera solar pronto, se tem, valida a lista
                                        const relatorioSolarPronto = !(item.itens || []).length || (item.itens || []).every((uc) => uc.relatorio_solar_pronto);

                                        switch (parseInt(this.props.filtro.id_status)) {
                                            case 1: //"Pendente de Importação"
                                                condicaoFiltroStatus = !item.relatorio_pronto && !item.relatorio_enviado;
                                                break;
                                            case 2: //"Todas Importadas - Não Enviados"
                                                condicaoFiltroStatus = item.relatorio_pronto && relatorioSolarPronto && !item.relatorio_enviado;
                                                break;
                                            case 3: //"Todas Importadas - faltando produção solar"
                                                condicaoFiltroStatus = item.relatorio_pronto && !relatorioSolarPronto && !item.relatorio_enviado;
                                                break;
                                            case 4: //"Enviado"
                                                condicaoFiltroStatus = item.relatorio_enviado;
                                                break;
                                            default: //Todos
                                                condicaoFiltroStatus = true;
                                                break;
                                        }
                                    }
                                    if (this.props.filtro.id_tipo_relatorio == 2) {
                                        switch (parseInt(this.props.filtro.id_status)) {
                                            case 1: //"Pendente de Importação"
                                                condicaoFiltroStatus = !item.relatorio_pronto && !item.relatorio_enviado;
                                                break;
                                            case 2: //"Todas Importadas - Não Enviados"
                                                condicaoFiltroStatus = item.relatorio_pronto && !item.relatorio_enviado;
                                                break;

                                            case 4: //"Enviado"
                                                condicaoFiltroStatus = item.relatorio_enviado;
                                                break;
                                            default: //Todos
                                                condicaoFiltroStatus = true;
                                                break;
                                        }
                                    }

                                    return condicaoFiltroCliente && condicaoFiltroStatus;
                                })
                                .map((item, i) => {
                                    let descricaoStatus = "Indefinido";

                                    if (this.props.filtro.id_tipo_relatorio == 1) {
                                        const relatorioSolarPronto = !(item.itens || []).length || (item.itens || []).every((uc) => uc.relatorio_solar_pronto);
                                        if (item.relatorio_enviado) {
                                            descricaoStatus = "Enviado";
                                        }
                                        if (!item.relatorio_pronto && !item.relatorio_enviado) {
                                            descricaoStatus = "Pendente de Importação";
                                        }
                                        if (item.relatorio_pronto && relatorioSolarPronto && !item.relatorio_enviado) {
                                            descricaoStatus = "Todas Importadas - Não Enviados";
                                        }
                                        if (item.relatorio_pronto && !relatorioSolarPronto && !item.relatorio_enviado) {
                                            descricaoStatus = "Todas Importadas - faltando produção solar";
                                        }
                                    }

                                    if (this.props.filtro.id_tipo_relatorio == 2) {
                                        if (item.relatorio_enviado) {
                                            descricaoStatus = "Enviado";
                                        }
                                        if (!item.relatorio_pronto && !item.relatorio_enviado) {
                                            descricaoStatus = "Pendente de Importação";
                                        }
                                        if (item.relatorio_pronto && !item.relatorio_enviado) {
                                            descricaoStatus = "Todas Importadas - Não Enviados";
                                        }
                                    }

                                    return (
                                        <NewTr
                                            key={item.id_cliente}
                                            backgroundColorTr={"#FFFFFF"}
                                            backgroundColorTd={"#F5F5F5"}
                                            onClick={() =>
                                                this.setState({
                                                    ...this.state,
                                                    linhaSelecionada: item.id_cliente,
                                                    linhaSelecionadaUC: null,
                                                })
                                            }
                                            onMouseEnter={() =>
                                                this.setState({
                                                    ...this.state,
                                                    linhaSelecionada: item.id_cliente,
                                                    linhaSelecionadaUC: null,
                                                })
                                            }
                                            celulas={
                                                <>
                                                    <Td>
                                                        {this.props.filtro.id_tipo_relatorio == 1 ? `${this.props.filtro.mes}/${this.props.filtro.ano}` : null}
                                                        {this.props.filtro.id_tipo_relatorio == 2 ? `${this.props.filtro.ano_safra}` : null}
                                                        {this.props.filtro.id_tipo_relatorio == 3 ? this.props.filtro.ano : null}
                                                    </Td>
                                                    <Td>{item.nome_cliente}</Td>
                                                    <Td alignCenter>{descricaoStatus}</Td>
                                                    <Td alignCenter minWidth={100}>
                                                        <ButtonTable
                                                            type={"primary"}
                                                            icon={"fas fa-file-pdf"}
                                                            visible={item.relatorio_pronto}
                                                            disabled={!this.props.usuarioLogado || !this.props.usuarioLogado.acesso_relatorio}
                                                            event={() => {
                                                                if (this.props.filtro.id_tipo_relatorio == 1) {
                                                                    this.props.gerarRelatorioMensal(item);
                                                                }
                                                                if (this.props.filtro.id_tipo_relatorio == 2) {
                                                                    this.gerarRelatorioSafra(item);
                                                                }
                                                                this.props.setArquivoPDF();
                                                            }}
                                                        />
                                                    </Td>
                                                    {this.props.filtro.id_tipo_relatorio != 1 ? null : (
                                                        <Td colspan={2} alignCenter minWidth={100}>
                                                            {(item.itens || []).map((uc) => {
                                                                return (
                                                                    <div
                                                                        key={item.id_cliente + "-" + uc.id}
                                                                        style={{ display: "flex" }}
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                ...this.state,
                                                                                linhaSelecionadaUC: uc.id_unidade_consumidora,
                                                                            })
                                                                        }
                                                                        onMouseEnter={() =>
                                                                            this.setState({
                                                                                ...this.state,
                                                                                linhaSelecionadaUC: uc.id_unidade_consumidora,
                                                                            })
                                                                        }
                                                                        onMouseLeave={() =>
                                                                            this.setState({
                                                                                ...this.state,
                                                                                linhaSelecionadaUC: null,
                                                                            })
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexGrow: 1,
                                                                                alignItems: "center",
                                                                                minHeight: 40,
                                                                                padding: 5,
                                                                                border:
                                                                                    this.state.linhaSelecionadaUC == uc.id_unidade_consumidora
                                                                                        ? "1px solid #555"
                                                                                        : "1px solid transparent",
                                                                            }}
                                                                        >
                                                                            <div style={{ width: "calc(100% - 100px)", textAlign: "left", paddingRight: 15 }}>
                                                                                {uc.nome_unidade_consumidora}
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    width: 100,
                                                                                }}
                                                                            >
                                                                                <ButtonTable
                                                                                    type={"primary"}
                                                                                    icon={"fas fa-file-pdf"}
                                                                                    visible={uc.relatorio_solar_pronto}
                                                                                    disabled={!this.props.usuarioLogado || !this.props.usuarioLogado.acesso_relatorio}
                                                                                    event={() => {
                                                                                        this.props.gerarRelatorioSolarMensal({
                                                                                            ...item,
                                                                                            ...uc,
                                                                                        });
                                                                                        this.props.setArquivoPDF();
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Td>
                                                    )}
                                                    <Td alignCenter minWidth={100}>
                                                        <ButtonTable
                                                            title={item.relatorio_enviado ? "O relatório já foi enviado" : "Enviar relatório"}
                                                            type={item.relatorio_enviado ? "warning" : "success"}
                                                            icon={"fas fa-envelope"}
                                                            visible={item.relatorio_pronto || item.relatorio_enviado}
                                                            disabled={!this.props.usuarioLogado || !this.props.usuarioLogado.acesso_relatorio_email || item.relatorio_enviado}
                                                            event={() => {
                                                                this.props.enviarRelatorio(item.id_cliente);
                                                            }}
                                                        />
                                                    </Td>
                                                </>
                                            }
                                            botoes={[]}
                                        />
                                    );
                                })}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }

    async gerarRelatorioSafra(item) {
        this.props.setAguardando(true);
        await this.props.getDadosRelatorioSafra(item.id_cliente);
        this.props.setModoTela("relatorioSafra");
        this.setState({
            ...this.state,
            idClienteRelatorio: item.id_cliente,
            nomeClienteRelatorio: item.nome_cliente,
            competenciaRelatorio: this.props.filtro.ano_safra,
        });
    }
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.faturaRelatorio.modoTela,
	aguardando: state.faturaRelatorio.aguardando,
	filtro: state.faturaRelatorio.filtro,
	lista: state.faturaRelatorio.lista,
	listaCliente: state.faturaRelatorio.listaCliente,
	listaUnidadeConsumidora: state.faturaRelatorio.listaUnidadeConsumidora,
	listaMeses: state.faturaRelatorio.listaMeses,
	listaTiposRelatorios: state.faturaRelatorio.listaTiposRelatorios,
	listaStatus: state.faturaRelatorio.listaStatus,
	dadosRelatorioSafra: state.faturaRelatorio.dadosRelatorioSafra,
	pdf: state.displayPDF.pdf,
	nomePdf: state.displayPDF.nomePdf,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			setFiltro,
			setAguardando,
			getLista,
			getListaCliente,
			getListaUnidadeConsumidora,
			gerarRelatorioMensal,
			gerarRelatorioSolarMensal,
			enviarRelatorio,
			setArquivoPDF,
			getDadosRelatorioSafra,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(FaturaRelatorio);
